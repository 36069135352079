import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '@/hooks/index';

interface SELECT_WINNER_FOR_EXACTAS_TYPE {
  sport: string;
  eventId: string;
  exactasType: number;
  payload: { oddId: string };
}

const selectWinnerForExactasType = async ({
  sport,
  eventId,
  exactasType,
  payload,
}: SELECT_WINNER_FOR_EXACTAS_TYPE) => {
  const { data } = await authenticatedAxios({
    method: 'PUT',
    url: `admin/${sport}/events/${eventId}/exactas/${exactasType}/winnerOrder`,
    data: payload,
  });

  return data;
};

export default function useSelectWinnerForExactasType() {
  return useMutation(({ sport, eventId, exactasType, payload }: SELECT_WINNER_FOR_EXACTAS_TYPE) =>
    selectWinnerForExactasType({ sport, eventId, exactasType, payload }),
  );
}
