export const ODDS_PROJECTION_TYPE = {
  fastestLapProjections: 'fastestLapProjections',
  eventWinnerProjections: 'eventWinnerProjections',
  eventSecondPlaceProjections: 'eventSecondPlaceProjections',
  heatProjections: 'heatProjections',
  propBetProjections: 'propBetProjections',
  headToHeadProjections: 'headToHeadProjections',
  podiumProjections: 'podiumProjections',
  showsProjections: 'showsProjections',
  dreamTeamProjections: 'dreamTeamProjections',
  exactasEventProjections: 'exactasEventProjections',
  exactasHeatProjections: 'exactasHeatProjections',
  trifectasEventProjections: 'trifectasEventProjections',
};
