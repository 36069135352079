import React, { useState, useEffect } from 'react';
import round from 'lodash.round';
import { useRecoilState } from 'recoil';
import { useSnackbar } from 'notistack';
import MaterialTable, { Column } from '@material-table/core';
import { useQueryClient } from '@tanstack/react-query';
import { parseISO } from 'date-fns';

import { Box, Typography, TextField, MenuItem, Grid } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { oddsType } from '@/atoms/oddsType';

import DecimalField from '@/components/DecimalField';
import { EventListingSkeleton } from '@/components/PageSkeletons';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import ResetOddsBtn from '@/components/ResetOddsBtn';
import SaveOddsBtn from '@/components/SaveOddsBtn';
import ExportBtn from '@/components/ExportBtn';
import PublishOdds from '@/components/PublishOdds';

import useFetchShowOdds from '@/hooks/odds/showOdds/useFetchShowOdds';
import useFetchPodiumOdds from '@/hooks/odds/podiumOdds/useFetchPodiumOdds';
import useUpdateShowOdds from '@/hooks/odds/showOdds/useUpdateShowOdds';
import useUpdatePodiumOdds from '@/hooks/odds/podiumOdds/useUpdatePodiumOdds';

import * as oddsHelpers from '@/helpers/odds';
import { excelDownloader } from '@/helpers/fileDownloader';
import { oddsToCSVFormatModifier } from '@/helpers/oddsToCSVFormatModifier';
import { invalidatePodiumOdds, invalidateShowOdds } from '@/helpers/cachedQueries';
import { displayInPT as format } from '@/helpers/timeConverters';

import ODDS_TYPES from '@/constants/oddsTypes';
import * as oddRanges from '@/constants/oddRanges';
import * as validationMessages from '@/constants/validationMessages';
import { ODD_DATA_TYPES } from '@/constants/oddDataTypes';
import { fractionPattern } from '@/constants/misc';
import { SHOW_OR_PODIUM } from '@/constants/showOrPodium';
import { ODDS_PROJECTION_TYPE } from '@/constants/oddsProjectionType';
import * as httpStatusCodes from '@/constants/httpStatus';
import { SPORT_NAMES } from '@/constants/sportTypes';
import keys from '@/constants/queryKeys';

import { ShowAndPodiumOdd, ModifiedShowAndPodiumOdds } from '@/types/helpers/odds';
import { UpdateOddsPayloadDTO } from '@/types/odds/updateOdds';

import {
  tableIcons,
  tableCellStyle,
  editCellTextFieldStyle,
  defaultTableOptions,
  createTablePageOptions,
} from '@/utils/TableMisc';

interface ShowsAndPodiumsOddsTabProps {
  sport: string;
  eventId: string;
  showOrPodium: string;
  handlePublishOdds: (oddsProjectionType: string) => void;
}

const ShowsAndPodiumsOddsTab = (props: ShowsAndPodiumsOddsTabProps) => {
  const { sport, eventId, showOrPodium, handlePublishOdds } = props;
  const { data: showOddsData } = useFetchShowOdds({ sport, eventId, showOrPodium });
  const { data: podiumOddsData } = useFetchPodiumOdds({ sport, eventId, showOrPodium });
  const oddsData = React.useMemo(() => {
    return showOrPodium === SHOW_OR_PODIUM.SHOW ? showOddsData : podiumOddsData;
  }, [showOddsData, podiumOddsData]);
  const [oddType] = useRecoilState(oddsType);
  const [modShowAndPodiumOdds, setModShowAndPodiumOdds] = useState<ModifiedShowAndPodiumOdds[]>([]);
  const [holdPercentage, setHoldPercentage] = useState(
    oddsHelpers.getTotalProbability(oddsData?.odds || []),
  );
  const [hasOddsBeenEdited, setHasOddsBeenEdited] = useState(false);
  const [showOddsUpdateDialog, setShowOddsUpdateDialog] = useState(false);
  const [showResetOddsDialog, setShowResetOddsDialog] = useState(false);
  const { mutateAsync: updateShowOdds } = useUpdateShowOdds();
  const { mutateAsync: updatePodiumOdds } = useUpdatePodiumOdds();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const event: any = queryClient.getQueryData([keys.events.fetchEvent, sport, eventId, 'Event']);

  const setInitialOddsData = (odds: ShowAndPodiumOdd[]) => {
    setModShowAndPodiumOdds(oddsHelpers.showsAndPodiumsOddsDataLoader(odds));
    // total probability value increases cause of odds modification
    const calculatedProbability = oddsHelpers.getTotalProbability(odds);
    setHoldPercentage(calculatedProbability);
    if (hasOddsBeenEdited) {
      handleToggleHasOddsBeenEdited();
    }
  };

  useEffect(() => {
    if (oddsData?.odds) {
      // Handles initial data setting when api data changes
      setInitialOddsData(oddsData?.odds);
    }
  }, [JSON.stringify(oddsData)]);

  const handleToggleConfirmDialog = () => setShowOddsUpdateDialog(!showOddsUpdateDialog);

  const handleToggleResetDialog = () => setShowResetOddsDialog(!showResetOddsDialog);

  const handleToggleHasOddsBeenEdited = () => setHasOddsBeenEdited(!hasOddsBeenEdited);

  const saveOddsHandler = (odds: ModifiedShowAndPodiumOdds[]) => {
    const totalProbability = oddsHelpers.getTotalProbability(odds);
    setHoldPercentage(totalProbability);
    setModShowAndPodiumOdds(odds);

    if (!hasOddsBeenEdited) {
      handleToggleHasOddsBeenEdited();
    }
  };

  const invalidateOdds =
    showOrPodium === SHOW_OR_PODIUM.SHOW ? invalidateShowOdds : invalidatePodiumOdds;

  const saveOddsData = async (
    payload: UpdateOddsPayloadDTO,
    message = 'Successfully updated odds',
  ) => {
    const updateOdds = showOrPodium === SHOW_OR_PODIUM.SHOW ? updateShowOdds : updatePodiumOdds;
    const response = await updateOdds(
      { sport, eventId, payload },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          invalidateOdds(queryClient, sport, eventId);
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  React.useEffect(() => {
    invalidateOdds(queryClient, sport, eventId);
  }, [eventId]);

  const updateOddsData = async () => {
    const payload = oddsHelpers.showsAndPodiumsOddsPayloadFormatter(modShowAndPodiumOdds);
    const responseStatus = await saveOddsData({ items: payload });

    if (responseStatus === httpStatusCodes.OK && hasOddsBeenEdited) {
      handleToggleHasOddsBeenEdited();
      const calculatedProbability = oddsHelpers.getTotalProbability(oddsData?.odds || []);
      setHoldPercentage(calculatedProbability);
    }
  };

  const showAndPodiumHeaders: Column<any>[] = [
    // {
    //   title: 'Event Seed',
    //   field: 'athlete.seedNo',
    //   // render: (rowData: ModifiedShowAndPodiumOdds) => (
    //   //   <Typography>{`${rowData?.athlete?.seedNo || ''}`}</Typography>
    //   // ),
    //   editable: 'never',
    //   cellStyle: tableCellStyle,
    // },
    {
      title: 'Athlete',
      field: 'name',
      render: (rowData: ModifiedShowAndPodiumOdds) => (
        <Typography sx={tableCellStyle} data-testid="athleteName">
          {[rowData?.athlete?.firstName, rowData?.athlete?.middleName, rowData?.athlete?.lastName]
            .filter(Boolean)
            .join(' ')}
        </Typography>
      ),
      editable: 'never',
      cellStyle: tableCellStyle,
    },
    // {
    //   title: 'Nationality',
    //   field: 'nationality',
    //   render: (rowData: ModifiedShowAndPodiumOdds) => (
    //     <Typography sx={tableCellStyle}>{`${rowData?.athlete?.nationality || ''}`}</Typography>
    //   ),
    //   editable: 'never',
    //   cellStyle: tableCellStyle,
    // },
    // {
    //   title: 'Stance',
    //   field: 'stance',
    //   render: (rowData: ModifiedShowAndPodiumOdds) => (
    //     <Typography sx={tableCellStyle}>{`${rowData?.athlete?.stance || ''}`}</Typography>
    //   ),
    //   editable: 'never',
    //   cellStyle: tableCellStyle,
    // },
    {
      title: 'Probability',
      field: 'probability',
      defaultSort: 'desc',
      customSort: (a: any, b: any) => +a?.probability - +b?.probability,
      align: 'left',
      validate: (row: ModifiedShowAndPodiumOdds) => {
        const totalPercentageModified = oddsHelpers.findTotalPercentage(
          modShowAndPodiumOdds,
          row?.id,
          {
            modifiedRowsOnly: true,
          },
        );
        const newRemainingTotalProbability = holdPercentage - totalPercentageModified;
        return +row?.probability < 0
          ? 'Enter Probability'
          : +row?.probability > newRemainingTotalProbability ||
            +row?.probability > oddRanges.MAX_PROBABILITY
          ? `Probability can't be more than ${
              newRemainingTotalProbability < oddRanges.MAX_PROBABILITY
                ? round(newRemainingTotalProbability, 2)
                : oddRanges.MAX_PROBABILITY
            }`
          : true;
      },
      editComponent: (props: any) => (
        <TextField
          data-testid="probability"
          variant="outlined"
          value={round(+props?.value, 2)}
          type="number"
          inputProps={{
            step: '0.01',
          }}
          onBlur={(e) => {
            const probability = +e.target.value;
            const odds = oddsHelpers.getDecimalOddsFromProbability(probability);
            const decimalOdds = odds.toString();
            const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();
            const americanOdds =
              oddsHelpers.getAmericanOddsFromProbability(probability) > 0
                ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
                : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;
            props.onRowDataChange({
              ...props.rowData,
              probability,
              odds,
              decimalOdds,
              fractionalOdds,
              americanOdds,
            });
          }}
          onChange={(e: any) => {
            props.onRowDataChange({
              ...props.rowData,
              probability: +e.target.value,
            });
          }}
          error={props?.error}
          helperText={props?.helperText || ''}
          sx={editCellTextFieldStyle}
        />
      ),
      cellStyle: tableCellStyle,
    },
    {
      title: 'Decimal Odds',
      field: 'decimalOdds',
      hidden: ODDS_TYPES.DECIMAL === oddType ? false : true,
      customSort: (a: any, b: any) => +a?.probability - +b?.probability,
      validate: (row: any) => {
        const newValue = +row?.decimalOdds;
        return newValue < oddRanges.MIN_DECIMAL_ODDS && newValue !== 0
          ? {
              isValid: false,
              helperText: validationMessages.ODD_WINNERS_DECIMAL_ODDS_VALIDATION_MSG,
            }
          : newValue > oddRanges.MAX_DECIMAL_ODDS
          ? {
              isValid: false,
              helperText: validationMessages.DECIMAL_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (props: any) => (
        <>
          <DecimalField
            data-testid="decimalOdds"
            value={props.value}
            onBlur={(e: any) => {
              const odds = +e.target.value;
              const probability = oddsHelpers.getProbabilityFromDecimalOdds(odds);
              const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();
              const americanOdds =
                oddsHelpers.getAmericanOddsFromProbability(probability) > 0
                  ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
                  : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;

              props.onRowDataChange({
                ...props.rowData,
                probability,
                odds,
                decimalOdds: odds,
                fractionalOdds,
                americanOdds,
              });
            }}
            onChange={(e: any) => {
              props.onRowDataChange({
                ...props.rowData,
                odds: e.target.value,
                decimalOdds: e.target.value,
              });
            }}
            error={props?.error}
            helperText={props?.helperText || ''}
            thousandSeparator={false}
            decimalScale={2}
            sx={editCellTextFieldStyle}
          />
        </>
      ),
      cellStyle: tableCellStyle,
    },
    {
      title: 'Fractional Odds',
      field: 'fractionalOdds',
      hidden: ODDS_TYPES.FRACTIONAL === oddType ? false : true,
      customSort: (a: any, b: any) => +a?.probability - +b?.probability,
      validate: (row: any) => {
        const newValue = row?.fractionalOdds;
        const convertedOdd = oddsHelpers.decimalFromFraction(newValue);
        return !newValue.match(fractionPattern) && newValue !== '0'
          ? {
              isValid: false,
              helperText: validationMessages.FRACTION_ODDS_VALIDATION_MSG,
            }
          : convertedOdd > oddRanges.MAX_FRACTION_ODDS
          ? {
              isValid: false,
              helperText: validationMessages.FRACTION_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (props: any) => (
        <>
          <TextField
            data-testid="fractionalOdds"
            value={props.value}
            onBlur={(e: any) => {
              const odds = oddsHelpers.getDecimalOddsFromOtherTypes(oddType, e.target.value);
              const probability = oddsHelpers.getProbabilityFromDecimalOdds(odds);
              const fractionalOdds = e.target.value;
              const americanOdds =
                oddsHelpers.getAmericanOddsFromProbability(probability) > 0
                  ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
                  : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;

              props.onRowDataChange({
                ...props.rowData,
                probability,
                odds,
                decimalOdds: odds,
                fractionalOdds,
                americanOdds,
              });
            }}
            onChange={(e: any) => {
              props.onRowDataChange({
                ...props.rowData,
                fractionalOdds: e.target.value,
              });
            }}
            error={props?.error}
            helperText={props?.helperText || ''}
            sx={editCellTextFieldStyle}
          />
        </>
      ),
      cellStyle: tableCellStyle,
    },
    {
      title: 'American Odds',
      field: 'americanOdds',
      hidden: ODDS_TYPES.AMERICAN === oddType ? false : true,
      customSort: (a: any, b: any) => +a?.probability - +b?.probability,
      validate: (row: any) => {
        const newValue = +row?.americanOdds;
        return newValue > oddRanges.MAX_NEGATIVE_AMERICAN_ODDS &&
          newValue < oddRanges.MIN_AMERICAN_ODDS &&
          newValue !== 0
          ? {
              isValid: false,
              helperText: validationMessages.ODD_WINNERS_AMERICAN_ODDS_VALIDATION_MSG,
            }
          : newValue < oddRanges.MIN_NEGATIVE_AMERICAN_ODDS ||
            newValue > oddRanges.MAX_AMERICAN_ODDS
          ? {
              isValid: false,
              helperText: validationMessages.AMERICAN_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (props: any) => (
        <>
          <DecimalField
            data-testid="americanOdds"
            value={props.value}
            onBlur={(e: any) => {
              const odds = oddsHelpers.getDecimalOddsFromOtherTypes(oddType, +e.target.value);
              const probability = round(oddsHelpers.getProbabilityFromDecimalOdds(odds), 1);
              const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();

              props.onRowDataChange({
                ...props.rowData,
                probability,
                odds,
                decimalOdds: odds,
                fractionalOdds,
                americanOdds: +e.target.value > 0 ? `+${e.target.value}` : e.target.value,
              });
            }}
            onChange={(e: any) => {
              props.onRowDataChange({
                ...props.rowData,
                americanOdds: +e.target.value > 0 ? `+${e.target.value}` : e.target.value,
              });
            }}
            error={props?.error}
            helperText={props?.helperText || ''}
            thousandSeparator={false}
            decimalScale={0}
            allowNegative={true}
            sx={editCellTextFieldStyle}
          />
        </>
      ),
      cellStyle: tableCellStyle,
    },
  ];

  const MIN_HOLD_PERCENTAGE = React.useMemo(() => {
    if (modShowAndPodiumOdds && modShowAndPodiumOdds.length > 0) {
      let total = 0;
      modShowAndPodiumOdds.map((data: any) => {
        total += +data?.trueProbability;
        return data;
      });
      return round(total);
    }
    return 100;
  }, [modShowAndPodiumOdds]);

  // Max limit value from the default hold percentage
  const MAX_PERCENTAGE_LIMIT_VALUE = 10;
  const MAX_ALLOWED_HOLD_PERCENTAGE_VALUE = MIN_HOLD_PERCENTAGE + MAX_PERCENTAGE_LIMIT_VALUE;

  const MAX_HOLD_PERCENTAGE = MIN_HOLD_PERCENTAGE * 2;

  const holdPercentagesOptions = React.useMemo(
    () =>
      Array(MAX_HOLD_PERCENTAGE - MIN_HOLD_PERCENTAGE + 1)
        .fill(0)
        .map((_, idx) => MIN_HOLD_PERCENTAGE + idx),
    [MIN_HOLD_PERCENTAGE, MAX_HOLD_PERCENTAGE],
  );

  const exportHandler = (oddDataType: string) => {
    const modData = JSON.parse(JSON.stringify(modShowAndPodiumOdds || []));
    const { title, headers, csvData } = oddsToCSVFormatModifier({
      data: modData,
      oddDataType,
      oddType,
    });

    if (csvData.length > 0) {
      const eventInfo = {
        eventName: event?.name,
        year: event?.year,
        sport: SPORT_NAMES[sport],
        ...(event?.tour && { tour: event?.tour.name }),
        ...(event?.league && { league: event?.league.name }),
      };
      const lastItemUpdatedAt = oddsData?.traderUpdatedAtDate;
      const updatedAt = lastItemUpdatedAt
        ? format(parseISO(lastItemUpdatedAt), 'MM_dd_yyyy_HH_mm_aaa')
        : '';
      excelDownloader({ title, headers, csvData, updatedAt, ...eventInfo });
    }

    if (csvData.length === 0) enqueueSnackbar(`No Data Found for ${title}`);
  };

  if (!oddsData?.odds) return <EventListingSkeleton />;

  return (
    <Box>
      <Grid
        container
        alignItems={'center'}
        item
        xs={12}
        sx={{
          // mt: 4,
          mb: 2,
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { xs: 'flex-start', sm: 'space-between' },
          gap: { xs: '1rem', sm: 0 },
        }}
      >
        <Grid container alignItems={'center'} item xs={12} sm={8}>
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 600, color: 'info.main' }}>
            HOLD PERCENTAGE:
          </Typography>
          &nbsp;
          <Select
            id="selectHoldPercentage"
            value={holdPercentage}
            onChange={(event: SelectChangeEvent<typeof holdPercentage>) => {
              const {
                target: { value },
              } = event;
              const newHoldPercentage = Number(value);
              if (newHoldPercentage !== holdPercentage) {
                setModShowAndPodiumOdds(
                  oddsHelpers.showAndPodiumModifier({
                    eventOddsPosition: modShowAndPodiumOdds as ShowAndPodiumOdd[],
                    newHoldPercentage,
                    oldHoldPercentage: holdPercentage,
                    defaultHoldPercentage: MIN_HOLD_PERCENTAGE,
                  }),
                );
                setHoldPercentage(newHoldPercentage);
                if (!hasOddsBeenEdited) {
                  handleToggleHasOddsBeenEdited();
                }
              }
            }}
            sx={{ height: 30 }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
          >
            {React.Children.toArray(
              holdPercentagesOptions.map((percent) => (
                <MenuItem
                  value={percent}
                  disabled={
                    percent > MAX_ALLOWED_HOLD_PERCENTAGE_VALUE
                      ? // || !canSelectHoldPercentage[percent]
                        true
                      : false
                  }
                >
                  {percent}
                </MenuItem>
              )),
            )}
          </Select>
          &nbsp;&nbsp;
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontWeight: 600,
              color: 'neutral.main',
              marginLeft: '0.75rem',
            }}
          >
            MARGIN:
          </Typography>
          &nbsp;
          <Typography
            id="margin"
            sx={{ fontSize: '0.875rem', fontWeight: 600, color: 'neutral.main' }}
          >
            {holdPercentage - MIN_HOLD_PERCENTAGE}%
          </Typography>
          &nbsp;
        </Grid>
        <Grid
          container
          alignItems={'center'}
          item
          xs={12}
          sm={4}
          sx={{ justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}
        >
          <ResetOddsBtn
            disabled={!hasOddsBeenEdited}
            resetOddsHandler={() => {
              handleToggleResetDialog();
            }}
          />
          <SaveOddsBtn
            disabled={!hasOddsBeenEdited}
            saveOddsHandler={() => {
              handleToggleConfirmDialog();
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        // justifyContent={'flex-end'}
        alignItems={'flex-start'}
        item
        xs={12}
        sx={{ marginY: '1rem' }}
      >
        <ExportBtn
          handleOnClick={() =>
            exportHandler(
              showOrPodium === SHOW_OR_PODIUM.SHOW ? ODD_DATA_TYPES.SHOWS : ODD_DATA_TYPES.PODIUMS,
            )
          }
        />
        <PublishOdds
          clientUpdatedAtDate={oddsData?.clientUpdatedAtDate || ''}
          traderUpdatedAtDate={oddsData?.traderUpdatedAtDate || ''}
          traderUpdatedAtDates={oddsData?.traderUpdatedAtDate || null}
          handlePublishOdds={() => {
            handlePublishOdds(
              showOrPodium === SHOW_OR_PODIUM.SHOW
                ? ODDS_PROJECTION_TYPE.showsProjections
                : ODDS_PROJECTION_TYPE.podiumProjections,
            );
          }}
        />
      </Grid>
      <Box>
        <MaterialTable
          data={modShowAndPodiumOdds || []}
          icons={tableIcons}
          columns={showAndPodiumHeaders}
          options={{
            toolbar: false,
            actionsColumnIndex: -1,
            // sorting: true,
            thirdSortClick: false,
            ...defaultTableOptions,
            pageSize: createTablePageOptions(modShowAndPodiumOdds?.length || 0).pageSize,
            pageSizeOptions: createTablePageOptions(modShowAndPodiumOdds?.length || 0)
              .pageSizeOptions,
          }}
          editable={{
            isDeleteHidden: () => true,
            onRowUpdate: (oddRow: ModifiedShowAndPodiumOdds) => {
              return new Promise((resolve) => {
                setTimeout(() => {
                  const finalRows = oddsHelpers?.editShowAndPodiumRowFormatter(
                    { ...oddRow, hasModifiedProbability: true },
                    modShowAndPodiumOdds,
                  );
                  saveOddsHandler(finalRows);
                  resolve('success');
                }, 1000);
              });
            },
            onRowDelete: () =>
              new Promise(() => {
                //
              }),
          }}
          localization={{
            header: {
              actions: '',
            },
          }}
        />
      </Box>
      {showOddsUpdateDialog && (
        <ConfirmDialog
          open={showOddsUpdateDialog}
          handleClose={handleToggleConfirmDialog}
          handleConfirm={() => {
            handleToggleConfirmDialog();
            updateOddsData();
          }}
          title={`ARE YOU SURE YOU WANT TO UPDATE THE ODDS?`}
          body={'This will update the odds.'}
        />
      )}
      {showResetOddsDialog && (
        <ConfirmDialog
          open={showResetOddsDialog}
          handleClose={handleToggleResetDialog}
          handleConfirm={() => {
            handleToggleResetDialog();
            setInitialOddsData(oddsData?.odds as ShowAndPodiumOdd[]);
          }}
          title={`ARE YOU SURE YOU WANT TO RESET THE ODDS?`}
          body={'This will reset the odds.'}
        />
      )}
    </Box>
  );
};

export default ShowsAndPodiumsOddsTab;
