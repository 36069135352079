import * as React from 'react';
import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import v from 'voca';
import { marketOddsPayload } from '../marketHelper';
import { JAMarketTypesValue, JASubMarketTypesValues } from '@/constants/jaialai';
import { MarketTypeToggleLabel, MarketToggleIcon } from '../components';
import { JAIALAIGameContext } from '@/contexts/jaialaiGame.context';
import { BTN_BG_COLOR, HEADER_BACKGROUND_COLOR, TEXT_COLOR } from '../constants';

interface MarketToggleOptionProps {
  value: any;
  oddId: number;
  betId?: number;
  subMarket?: number;
  toggleIsMarketActive: (params: {
    value: any;
    oddId: number;
    betId?: number;
    subMarket?: number;
    isMarketActive: boolean;
  }) => void;
  label?: string;
  currentBetLabel?: string;
  disabledMarkets: { [key: string]: boolean };
}

const MarketToggleOption: React.FC<MarketToggleOptionProps> = ({
  value,
  oddId,
  betId,
  subMarket,
  toggleIsMarketActive,
  label = '',
  currentBetLabel = '',
  disabledMarkets,
}) => {
  const { isDisabledMatch } = React.useContext(JAIALAIGameContext);

  const isMarketActive = React.useMemo(
    () => marketOddsPayload({ odds: [value] })?.some((odd) => odd?.isMarketActive),
    [value],
  );

  const isMarketDisabled = disabledMarkets[value.marketType] || false;

  const idKey = React.useMemo(
    () =>
      v.kebabCase(
        [
          JAMarketTypesValue[value.marketType as keyof {}],
          currentBetLabel,
          JASubMarketTypesValues[subMarket as keyof {}],
        ]
          .filter(Boolean)
          .join(' '),
      ),
    [value.marketType, currentBetLabel, subMarket],
  );

  const commonIconStyles = React.useMemo(() => ({ fontSize: '1.2rem' }), []);

  const handleToggle = React.useCallback(
    (isActive: boolean) => (e: React.MouseEvent) => {
      e.stopPropagation();
      toggleIsMarketActive({ value, oddId, betId, subMarket, isMarketActive: isActive });
    },
    [value, oddId, betId, subMarket, toggleIsMarketActive],
  );

  const buttonStyles = (active: boolean, mainColor: string) => ({
    backgroundColor: active ? mainColor : TEXT_COLOR.WHITE,
    '&:hover, &:disabled': { backgroundColor: active ? mainColor : TEXT_COLOR.WHITE },
  });

  const iconColorStyles = (active: boolean) => ({
    ...commonIconStyles,
    color: isMarketDisabled ? TEXT_COLOR.GRAY : active ? TEXT_COLOR.WHITE : TEXT_COLOR.GRAY,
    '&:hover, &:disabled': {
      color: isMarketDisabled ? TEXT_COLOR.GRAY : active ? TEXT_COLOR.WHITE : TEXT_COLOR.GRAY,
    },
  });

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: 0.3,
        px: 0.8,
        borderRadius: '0.25rem',
        backgroundColor: isMarketActive
          ? HEADER_BACKGROUND_COLOR.ACTIVE
          : HEADER_BACKGROUND_COLOR.SUSPENDED,
        '&:hover': {
          backgroundColor: HEADER_BACKGROUND_COLOR.HOVER_GRAY,
        },
      }}
    >
      <MarketTypeToggleLabel
        component="span"
        sx={isMarketActive ? {} : { fontStyle: 'italic', color: TEXT_COLOR.RED }}
      >
        {label}
      </MarketTypeToggleLabel>
      <Box>
        <MarketToggleIcon
          sx={buttonStyles(isMarketActive, BTN_BG_COLOR.ACTIVE)}
          onClick={handleToggle(true)}
          disabled={isDisabledMatch || isMarketDisabled}
          id={`activate-${idKey}-btn`}
          {...{
            [`data-activate-${idKey}-btn`]: isMarketActive,
          }}
        >
          <CheckIcon sx={iconColorStyles(isMarketActive)} />
        </MarketToggleIcon>
        <MarketToggleIcon
          sx={buttonStyles(!isMarketActive, BTN_BG_COLOR.SUSPENDED)}
          onClick={handleToggle(false)}
          disabled={isDisabledMatch || isMarketDisabled}
          id={`suspend-${idKey}-btn`}
          {...{
            [`data-suspend-${idKey}-btn`]: !isMarketActive,
          }}
        >
          <CloseIcon sx={iconColorStyles(!isMarketActive)} />
        </MarketToggleIcon>
      </Box>
    </Box>
  );
};

export default MarketToggleOption;
