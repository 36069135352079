import { IconButton } from '@mui/material';
import v from 'voca';
import * as React from 'react';

import { JASubMarketTypes, JASubMarketTypesValues, JAMarketTypes } from '@/constants/jaialai';

import { GridCell, UnLockedIcon, LockedIcon, subMarketTypeStyles } from '../components';
import { teamTypeToKey } from '../constants';
import { JAIALAIGameContext } from '@/contexts/jaialaiGame.context';

//  DEPRECATED: Market, Bet and Sub Market imports
/* import DebugLabelDisplay from '../DebugLabelDisplay';
import { isImpossibleOutcome } from '../marketHelper';
import { IconButton, Typography } from '@mui/material';
import {
  JASubMarketTypes,
  JABetTypes,
  JASubMarketTypesValues,
  JAMarketTypes,
} from '@/constants/jaialai';
import {
  GridCell,
  UnLockedIcon,
  LockedIcon,
  subMarketTypeStyles,
  CalculatedDecimalField,
  CommonOddField,
} from '../components';
import {
  GridCell,
  UnLockedIcon,
  LockedIcon,
  subMarketTypeStyles,
  CalculatedDecimalField,
  CommonOddField,
} from '../components';
import {
  BACKGROUND_COLOR,
  impossibleOutcomeBoxShadowStyles,
  TEXT_COLOR,
  teamTypeToKey,
} from '../constants'; */

const Outcome4Column = ({
  bet,
  toggleSubMarketLock,
  commonBoxShadowStyles,
  id,
}: {
  bet: any;
  oddType: number;
  toggleSubMarketLock: Function;
  updateSubMarketTeam: Function;
  updateSubMarketTeamKey: Function;
  commonBoxShadowStyles: any;
  id: string;
}) => {
  const {
    isDisabledMatch /* 
    //  DEPRECATED: Market, Bet and Sub Market code
    isMatchOutcomeImpossible */,
  } = React.useContext(JAIALAIGameContext);
  const subMarketTypeNameKebabCase = v.kebabCase(
    JASubMarketTypesValues[JASubMarketTypes.OUTCOME_4 as keyof {}] || '',
  );
  const isSubMarketLocked =
    bet[JASubMarketTypes.OUTCOME_4]?.[teamTypeToKey.homeTeam]?.isSubMarketLocked &&
    bet[JASubMarketTypes.OUTCOME_4]?.[teamTypeToKey.awayTeam]?.isSubMarketLocked;

  //  DEPRECATED: Market, Bet and Sub Market code
  /* const isCorrectSetsBetType =
    +bet[JASubMarketTypes.OUTCOME_4]?.[teamTypeToKey.homeTeam]?.betType === JABetTypes.CORRECT_SETS;
  const isCurrentOutcomeImpossible =
    isCorrectSetsBetType &&
    isMatchOutcomeImpossible &&
    isImpossibleOutcome({ currentBet: bet, currentSubMkt: JASubMarketTypes.OUTCOME_4 }); */
  return (
    <GridCell
      sx={{
        gridArea: 'outcome4',
        justifyContent: 'start',
        display: 'grid',
        gridTemplateRows: 'repeat(3, minmax(0, 50px))',
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        gap: '2px',
        //  DEPRECATED: Market, Bet and Sub Market code
        /*  backgroundColor: isCurrentOutcomeImpossible ? BACKGROUND_COLOR.SUSPENDED : '',
        ...(isCurrentOutcomeImpossible ? impossibleOutcomeBoxShadowStyles : commonBoxShadowStyles), */
        ...commonBoxShadowStyles,
        ...subMarketTypeStyles({
          betType: +bet.marketType === JAMarketTypes.SETS ? bet.betType : +bet.betType,
          subMarketType: JASubMarketTypes.OUTCOME_4,
        }),
      }}
    >
      <GridCell
        sx={{
          gridArea: 'lock',
          boxShadow: 'none',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/*  //  DEPRECATED: Market, Bet and Sub Market code
        <Typography
          sx={{ color: TEXT_COLOR.GRAY, fontSize: '0.875rem', lineHeight: '125%' }}
          component={'span'}
        >
          {[JABetTypes.CORRECT_SETS].includes(+bet.betType)
            ? `${v.titleCase(JASubMarketTypesValues[JASubMarketTypes.OUTCOME_4 as keyof {}])}`
            : ``}
        </Typography> 
        &nbsp;
        */}
        {/* {![JABetTypes.CORRECT_SETS].includes(+bet.betType) && ( */}
        <IconButton
          disableRipple
          onClick={(e) => {
            e.stopPropagation();
            const subMarketType = JASubMarketTypes.OUTCOME_4;
            toggleSubMarketLock(subMarketType);
          }}
          id={`toggle-${id}-${subMarketTypeNameKebabCase}-market-lock`}
          disabled={isDisabledMatch}
          {...{
            [`data-${id}-${subMarketTypeNameKebabCase}-market-lock`]: isSubMarketLocked,
          }}
        >
          {isSubMarketLocked ? <LockedIcon /> : <UnLockedIcon />}
        </IconButton>
        {/* )} */}
      </GridCell>

      {/* 
       //  DEPRECATED: Market, Bet and Sub Market code
      {+bet.betType === JABetTypes.CORRECT_SETS && (
        <>
          <GridCell
            sx={{
              gridArea: 'homeTeamCalculatedValue',
              boxShadow: 'none',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <DebugLabelDisplay row={bet?.[JASubMarketTypes.OUTCOME_4]?.[teamTypeToKey.homeTeam]} />
            <CalculatedDecimalField
              id={`${id}-${subMarketTypeNameKebabCase}-homeTeamCalculatedValue`}
              allowNegative
              value={bet?.[JASubMarketTypes.OUTCOME_4]?.[teamTypeToKey.homeTeam]?.calculatedValue}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateSubMarketTeamKey({
                  subMarketType: JASubMarketTypes.OUTCOME_4,
                  teamType: teamTypeToKey.homeTeam,
                  key: 'calculatedValue',
                  val: +e.target.value || 0,
                });
                updateSubMarketTeamKey({
                  subMarketType: JASubMarketTypes.OUTCOME_4,
                  teamType: teamTypeToKey.homeTeam,
                  key: 'hasModifiedProbability',
                  val: true,
                });
              }}
              InputProps={{
                readOnly: true,
              }}
              disabled={isDisabledMatch || isSubMarketLocked}
            />
          </GridCell>

          <GridCell
            sx={{
              gridArea: 'awayTeamCalculatedValue',
              boxShadow: 'none',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <DebugLabelDisplay row={bet?.[JASubMarketTypes.OUTCOME_4]?.[teamTypeToKey.awayTeam]} />
            <CalculatedDecimalField
              id={`${id}-${subMarketTypeNameKebabCase}-awayTeamCalculatedValue`}
              allowNegative
              value={bet?.[JASubMarketTypes.OUTCOME_4]?.[teamTypeToKey.awayTeam]?.calculatedValue}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateSubMarketTeamKey({
                  subMarketType: JASubMarketTypes.OUTCOME_4,
                  teamType: teamTypeToKey.awayTeam,
                  key: 'calculatedValue',
                  val: +e.target.value || 0,
                });
                updateSubMarketTeamKey({
                  subMarketType: JASubMarketTypes.OUTCOME_4,
                  teamType: teamTypeToKey.awayTeam,
                  key: 'hasModifiedProbability',
                  val: true,
                });
              }}
              InputProps={{
                readOnly: true,
              }}
              disabled={isDisabledMatch || isSubMarketLocked}
            />
          </GridCell>

          <GridCell
            sx={{
              gridArea: 'odds',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: 'none',
            }}
          >
            <DebugLabelDisplay row={bet?.[JASubMarketTypes.OUTCOME_4]?.[teamTypeToKey.homeTeam]} />
            <CommonOddField
              id={`${id}-${subMarketTypeNameKebabCase}-homeTeam-odds`}
              oddType={oddType}
              bet={bet}
              subMarketType={JASubMarketTypes.OUTCOME_4}
              teamType={teamTypeToKey.homeTeam}
              updateSubMarketTeam={updateSubMarketTeam}
              disabled={isDisabledMatch || isSubMarketLocked}
            />
          </GridCell>
        </>
      )} */}
    </GridCell>
  );
};
export default Outcome4Column;
