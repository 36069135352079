import {
  Select,
  Typography,
  TextField,
  MenuItem,
  Box,
  Switch,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import round from 'lodash.round';
import { Column } from '@material-table/core';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

import DecimalField from '@/components/DecimalField';
import * as playerMatchUpHelpers from '@/helpers/playerMatchUp';

import ODDS_TYPES from '@/constants/oddsTypes';
import * as oddRanges from '@/constants/oddRanges';
import * as validationMessages from '@/constants/validationMessages';
import { TOGGLE_OPTIONS, TOGGLE_OPTIONS_VALUES } from '.';
import { HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS } from '@/constants/misc';

import { PlayerHeadToHeadOddRow } from '@/types/playerHeadToHeadTab';

import { headToHeadTableStyles, editCellTextFieldStyle } from '@/utils/TableMisc';
import { fractionPattern } from '@/constants/misc';

import Winnerenabledicon from '@/images/icons/winner-enabled-icon.svg';
import Winnerdisabledicon from '@/images/icons/winner-disabled-icon.svg';

import { MODEL_GENERATED_TEXT_AND_COLOR, MODEL_GENERATED_TYPES } from './constants';

const { color, tooltipText } = MODEL_GENERATED_TEXT_AND_COLOR[MODEL_GENERATED_TYPES.TRADER_UPDATED];

export const generatePlayerHeadToHeadColumns = ({
  updateTableData,
  holdPercentagesOptions,
  handleToggleChange,
  oddType,
  setTempVoidedRowId,
  handleToggleConfirmVoidDialog,
  sport,
}: {
  updateTableData: (row: PlayerHeadToHeadOddRow) => void;
  holdPercentagesOptions: number[];
  handleToggleChange: ({
    checked,
    type,
    rowMatchUpId,
    winnerId,
  }: {
    checked: boolean;
    type: TOGGLE_OPTIONS_VALUES;
    rowMatchUpId: string;
    winnerId?: string;
  }) => void;
  oddType: string;
  setTempVoidedRowId: (id: string) => void;
  handleToggleConfirmVoidDialog: () => void;
  sport: string;
}) => {
  const isSortAllowed = !HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport) ? true : false;
  const headers: Column<PlayerHeadToHeadOddRow>[] = [
    {
      title: '',
      editable: 'never',
      // headerStyle: { minWidth: '0.4rem', maxWidth: '0.4rem' },
      sorting: false,
      render: (row: PlayerHeadToHeadOddRow) => {
        return !row?.isModelGenerated ? (
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
            data-testid="customMatch"
          >
            <Tooltip title={tooltipText}>
              <SupervisorAccountIcon sx={{ color: color, fontSize: 24 }} />
            </Tooltip>
          </Box>
        ) : (
          <></>
        );
      },
    },
    {
      title: 'Hold Percentage',
      field: 'holdingPercentage',
      editable: 'never',
      headerStyle: { minWidth: '6rem', maxWidth: '6rem' },
      sorting: false,
      render: (row: PlayerHeadToHeadOddRow) => (
        <Select
          data-testid="holdPercentage"
          value={row.holdingPercentage}
          onChange={(event: SelectChangeEvent<typeof row.holdingPercentage>) => {
            const {
              target: { value },
            } = event;
            const newHoldPercentage = Number(value);
            if (row.holdingPercentage !== newHoldPercentage) {
              const modPlayerMatchUp = playerMatchUpHelpers.playerMatchUpOddsModifier({
                playerMatchUp: row,
                newHoldPercentage,
                oldHoldPercentage: row.holdingPercentage,
              });
              updateTableData(modPlayerMatchUp as PlayerHeadToHeadOddRow);
            }
          }}
          sx={{ height: 30, ...headToHeadTableStyles.athleteNameStyle }}
          MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
          disabled={row.winnerParticipantId !== null || row.voided || row.draw}
        >
          {holdPercentagesOptions.map((percent) => (
            <MenuItem key={percent} value={percent}>
              {percent}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      title: 'Margin',
      editable: 'never',
      headerStyle: { minWidth: '4.375rem', maxWidth: '4.375rem' },
      sorting: false,
      render: (row: PlayerHeadToHeadOddRow) => (
        <Typography
          component="span"
          data-testid="athleteName"
          sx={{ ...headToHeadTableStyles.athleteNameStyle }}
        >
          {round(+row?.holdingPercentage - 100)}%
        </Typography>
      ),
    },
    {
      title: 'Probability',
      field: 'eventParticipant1.probability',
      headerStyle: { minWidth: '5.375rem', maxWidth: '5.375rem' },
      validate: (row: any) => {
        const player1Probability = +row.eventParticipant1.probability;
        return player1Probability < oddRanges.MIN_PROBABILITY ||
          player1Probability > oddRanges.MAX_PROBABILITY
          ? {
              isValid: false,
              helperText: validationMessages.PLAYER_MATCH_UP_PROBABILITY_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (prop: any) => (
        <TextField
          data-testid="eventParticipant1Probability"
          variant="outlined"
          value={prop?.value}
          type="number"
          inputProps={{
            step: '0.01',
          }}
          onBlur={(e) => {
            if (!prop.error) {
              const modPlayerMatchUp = playerMatchUpHelpers.editMatchUpRowProbabilityFormatter(
                +e.target.value,
                prop.rowData,
                { isPlayer1Modified: true },
              );
              prop.onRowDataChange(modPlayerMatchUp);
            }
          }}
          onChange={(e: any) => {
            prop.onRowDataChange({
              ...prop.rowData,
              eventParticipant1: {
                ...prop.rowData.eventParticipant1,
                probability: e.target.value,
              },
            });
          }}
          error={prop?.error}
          helperText={prop?.helperText || ''}
          sx={editCellTextFieldStyle}
        />
      ),
    },
    {
      title: 'Winner',
      field: 'winnerParticipantId',
      editable: 'never',
      headerStyle: { minWidth: '4.375rem', maxWidth: '4.375rem' },
      sorting: isSortAllowed,
      render: (row: PlayerHeadToHeadOddRow) => (
        <>
          <Switch
            data-testid="eventParticipant1WinnerToggle"
            disabled={
              row.winnerParticipantId !== null || row.editedVoid || row.draw || row.editedDraw
            }
            checked={
              row.winnerParticipantId === row.eventParticipant1.id ||
              row.newWinnerId === row.eventParticipant1.id
            }
            onChange={(e) =>
              handleToggleChange({
                checked: e.target.checked,
                type: TOGGLE_OPTIONS.WINNER,
                rowMatchUpId: row.id,
                winnerId: row.eventParticipant1.id,
              })
            }
          />
        </>
      ),
    },
    {
      title: 'Place',
      field: 'eventParticipant1.position',
      headerStyle: { minWidth: '4.375rem', maxWidth: '4.375rem' },
      sorting: isSortAllowed,
      /* validate: (row: PlayerHeadToHeadOddRow) => {
        return Number(row?.eventParticipant1?.position) <= 0 || !row?.eventParticipant1?.position
          ? 'Enter Player place greater than 0'
          : Number(row?.eventParticipant1?.position || 0) ===
            Number(row?.eventParticipant2?.position || 0)
          ? "Match up players can't have same place"
          : true;
      }, */
      editComponent: (props: any) => (
        <>
          <TextField
            data-testid="eventParticipant1Position"
            sx={{ ...headToHeadTableStyles.editCellTextFieldStyle }}
            value={props.value === '--' ? '' : props.value}
            onChange={(e) => {
              props.onChange(Number(e.target.value));
            }}
            error={props?.error}
            helperText={props?.helperText || ''}
          />
        </>
      ),
    },
    {
      title: 'Decimal Odds',
      field: 'eventParticipant1.decimalOdds',
      hidden: ODDS_TYPES.DECIMAL === oddType ? false : true,
      headerStyle: { minWidth: '5.375rem', maxWidth: '5.375rem' },
      customSort: (a, b) => a.eventParticipant1.odds - b.eventParticipant1.odds,
      validate: (row: any) => {
        const newValue = round(+row?.eventParticipant1.decimalOdds || 0, 2);
        return newValue < oddRanges.MIN_DECIMAL_ODDS && newValue !== 0
          ? {
              isValid: false,
              helperText: validationMessages.ODD_WINNERS_DECIMAL_ODDS_VALIDATION_MSG,
            }
          : newValue > oddRanges.MAX_DECIMAL_ODDS
          ? {
              isValid: false,
              helperText: validationMessages.DECIMAL_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (prop: any) => (
        <>
          <DecimalField
            data-testid="eventParticipant1DecimalOdds"
            value={prop.value}
            onBlur={(e: any) => {
              if (!prop.error) {
                const modPlayerMatchUp = playerMatchUpHelpers.editMatchUpRowOddsFormatter(
                  e.target.value,
                  prop.rowData,
                  { isPlayer1Modified: true, oddsType: oddType },
                );
                prop.onRowDataChange(modPlayerMatchUp);
              }
            }}
            onChange={(e: any) => {
              prop.onRowDataChange({
                ...prop.rowData,
                eventParticipant1: {
                  ...prop.rowData.eventParticipant1,
                  decimalOdds: e.target.value,
                  odds: e.target.value,
                },
              });
            }}
            error={prop?.error}
            helperText={prop?.helperText || ''}
            thousandSeparator={false}
            decimalScale={2}
            sx={editCellTextFieldStyle}
          />
        </>
      ),
    },
    {
      title: 'Fractional Odds',
      field: 'eventParticipant1.fractionalOdds',
      hidden: ODDS_TYPES.FRACTIONAL === oddType ? false : true,
      headerStyle: { minWidth: '5.375rem', maxWidth: '5.375rem' },
      customSort: (a, b) => a.eventParticipant1.odds - b.eventParticipant1.odds,
      validate: (row: any) => {
        const newValue = row?.eventParticipant1.fractionalOdds;
        return !newValue.match(fractionPattern) && newValue !== '0'
          ? {
              isValid: false,
              helperText: validationMessages.FRACTION_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (prop: any) => (
        <>
          <TextField
            data-testid="eventParticipant1FractionalOdds"
            value={prop.value}
            onBlur={(e: any) => {
              if (!prop.error) {
                const modPlayerMatchUp = playerMatchUpHelpers.editMatchUpRowOddsFormatter(
                  e.target.value,
                  prop.rowData,
                  { isPlayer1Modified: true, oddsType: oddType },
                );
                prop.onRowDataChange(modPlayerMatchUp);
              }
            }}
            onChange={(e: any) => {
              prop.onRowDataChange({
                ...prop.rowData,
                eventParticipant1: {
                  ...prop.rowData.eventParticipant1,
                  fractionalOdds: e.target.value,
                },
              });
            }}
            error={prop?.error}
            helperText={prop?.helperText || ''}
            sx={editCellTextFieldStyle}
          />
        </>
      ),
    },
    {
      title: 'American Odds',
      field: 'eventParticipant1.americanOdds',
      hidden: ODDS_TYPES.AMERICAN === oddType ? false : true,
      headerStyle: { minWidth: '5.375rem', maxWidth: '5.375rem' },
      customSort: (a, b) => a.eventParticipant1.odds - b.eventParticipant1.odds,
      validate: (row: any) => {
        const newValue = +row?.eventParticipant1.americanOdds;
        return newValue > oddRanges.MAX_NEGATIVE_AMERICAN_ODDS &&
          newValue < oddRanges.MIN_AMERICAN_ODDS
          ? {
              isValid: false,
              helperText: validationMessages.AMERICAN_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (prop: any) => (
        <>
          <DecimalField
            data-testid="eventParticipant1AmericanOdds"
            value={prop.value}
            onBlur={(e: any) => {
              if (!prop.error) {
                const modPlayerMatchUp = playerMatchUpHelpers.editMatchUpRowOddsFormatter(
                  e.target.value,
                  prop.rowData,
                  { isPlayer1Modified: true, oddsType: oddType },
                );

                prop.onRowDataChange(modPlayerMatchUp);
              }
            }}
            onChange={(e: any) => {
              prop.onRowDataChange({
                ...prop.rowData,
                eventParticipant1: {
                  ...prop.rowData.eventParticipant1,
                  americanOdds: e.target.value > 0 ? `+${e.target.value}` : e.target.value,
                },
              });
            }}
            error={prop?.error}
            helperText={prop?.helperText || ''}
            thousandSeparator={false}
            decimalScale={0}
            allowNegative={true}
            sx={editCellTextFieldStyle}
          />
        </>
      ),
    },
    {
      title: 'Athlete 1',
      field: 'eventParticipant1.label',
      editable: 'never',
      // headerStyle: { minWidth: '16rem', maxWidth: '16rem' },
      sorting: isSortAllowed,
      render: (row: PlayerHeadToHeadOddRow) => (
        <Box
          data-testid="eventParticipant1Label"
          sx={{ display: 'inline-flex', alignItems: 'center', gap: '0.2rem', whiteSpace: 'nowrap' }}
        >
          <Typography component="span" sx={{ ...headToHeadTableStyles.athleteNameStyle }}>
            {row?.eventParticipant1?.label}
          </Typography>

          {row.winnerParticipantId === row.eventParticipant1.id ? (
            <Box
              component="img"
              alt="winner icon"
              src={Winnerenabledicon}
              sx={{ ...headToHeadTableStyles.winnerIconsStyle }}
            ></Box>
          ) : (
            <Box
              component="img"
              alt="winner icon"
              src={Winnerdisabledicon}
              sx={{ ...headToHeadTableStyles.winnerIconsStyle }}
            ></Box>
          )}
        </Box>
      ),
      align: 'right',
    },
    {
      title: '',
      headerStyle: { ...headToHeadTableStyles.vsColHeaderStyle },
      align: 'center',
      sorting: false,
      render: () => (
        <Typography component="span" sx={{ ...headToHeadTableStyles.vsStyle }}>
          <strong>VS</strong>
        </Typography>
      ),
    },
    {
      title: 'Athlete 2',
      field: 'eventParticipant2.label',
      editable: 'never',
      // headerStyle: { minWidth: '12rem', maxWidth: '12rem' },
      sorting: isSortAllowed,
      render: (row: PlayerHeadToHeadOddRow) => (
        <Box
          data-testid="eventParticipant2Label"
          sx={{ display: 'inline-flex', alignItems: 'center', gap: '0.2rem', whiteSpace: 'nowrap' }}
        >
          {row.winnerParticipantId === row.eventParticipant2.id ? (
            <Box
              component="img"
              alt="winner icon"
              src={Winnerenabledicon}
              sx={{ ...headToHeadTableStyles.winnerIconsStyle }}
            ></Box>
          ) : (
            <Box
              component="img"
              alt="winner icon"
              src={Winnerdisabledicon}
              sx={{ ...headToHeadTableStyles.winnerIconsStyle }}
            ></Box>
          )}

          <Typography component="span" sx={{ ...headToHeadTableStyles.athleteNameStyle }}>
            {row?.eventParticipant2?.label}
          </Typography>
        </Box>
      ),
      align: 'left',
    },
    {
      title: 'Decimal Odds',
      field: 'eventParticipant2.decimalOdds',
      hidden: ODDS_TYPES.DECIMAL === oddType ? false : true,
      headerStyle: { minWidth: '5.375rem', maxWidth: '5.375rem' },
      customSort: (a, b) => a.eventParticipant2.odds - b.eventParticipant2.odds,
      validate: (row: any) => {
        const newValue = round(+row?.eventParticipant2.decimalOdds || 0, 2);
        return newValue < oddRanges.MIN_DECIMAL_ODDS && newValue !== 0
          ? {
              isValid: false,
              helperText: validationMessages.ODD_WINNERS_DECIMAL_ODDS_VALIDATION_MSG,
            }
          : newValue > oddRanges.MAX_DECIMAL_ODDS
          ? {
              isValid: false,
              helperText: validationMessages.DECIMAL_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (prop: any) => (
        <>
          <DecimalField
            data-testid="eventParticipant2DecimalOdds"
            value={prop.value}
            onBlur={(e: any) => {
              if (!prop.error) {
                const modPlayerMatchUp = playerMatchUpHelpers.editMatchUpRowOddsFormatter(
                  e.target.value,
                  prop.rowData,
                  { isPlayer2Modified: true, oddsType: oddType },
                );
                prop.onRowDataChange(modPlayerMatchUp);
              }
            }}
            onChange={(e: any) => {
              prop.onRowDataChange({
                ...prop.rowData,
                eventParticipant2: {
                  ...prop.rowData.eventParticipant2,
                  decimalOdds: e.target.value,
                },
              });
            }}
            error={prop?.error}
            helperText={prop?.helperText || ''}
            thousandSeparator={false}
            decimalScale={4}
            sx={editCellTextFieldStyle}
          />
        </>
      ),
      align: 'left',
    },
    {
      title: 'Fractional Odds',
      field: 'eventParticipant2.fractionalOdds',
      align: 'left',
      hidden: ODDS_TYPES.FRACTIONAL === oddType ? false : true,
      headerStyle: { minWidth: '5.375rem', maxWidth: '5.375rem' },
      customSort: (a, b) => a.eventParticipant2.odds - b.eventParticipant2.odds,
      validate: (row: any) => {
        const newValue = row?.eventParticipant2.fractionalOdds;
        return !newValue.match(fractionPattern) && newValue !== '0'
          ? {
              isValid: false,
              helperText: validationMessages.FRACTION_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (prop: any) => (
        <>
          <TextField
            data-testid="eventParticipant2FractionalOdds"
            value={prop.value}
            onBlur={(e: any) => {
              if (!prop.error) {
                const modPlayerMatchUp = playerMatchUpHelpers.editMatchUpRowOddsFormatter(
                  e.target.value,
                  prop.rowData,
                  { isPlayer2Modified: true, oddsType: oddType },
                );
                prop.onRowDataChange(modPlayerMatchUp);
              }
            }}
            onChange={(e: any) => {
              prop.onRowDataChange({
                ...prop.rowData,
                eventParticipant2: {
                  ...prop.rowData.eventParticipant2,
                  fractionalOdds: e.target.value,
                },
              });
            }}
            error={prop?.error}
            helperText={prop?.helperText || ''}
            sx={editCellTextFieldStyle}
          />
        </>
      ),
    },
    {
      title: 'American Odds',
      field: 'eventParticipant2.americanOdds',
      align: 'left',
      hidden: ODDS_TYPES.AMERICAN === oddType ? false : true,
      headerStyle: { minWidth: '5.375rem', maxWidth: '5.375rem' },
      customSort: (a, b) => a.eventParticipant2.odds - b.eventParticipant2.odds,
      validate: (row: any) => {
        const newValue = +row?.eventParticipant2.americanOdds;
        return newValue > oddRanges.MAX_NEGATIVE_AMERICAN_ODDS &&
          newValue < oddRanges.MIN_AMERICAN_ODDS
          ? {
              isValid: false,
              helperText: validationMessages.AMERICAN_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (prop: any) => (
        <>
          <DecimalField
            data-testid="eventParticipant2AmericanOdds"
            value={prop.value}
            onBlur={(e: any) => {
              if (!prop.error) {
                const modPlayerMatchUp = playerMatchUpHelpers.editMatchUpRowOddsFormatter(
                  e.target.value,
                  prop.rowData,
                  { isPlayer2Modified: true, oddsType: oddType },
                );
                prop.onRowDataChange(modPlayerMatchUp);
              }
            }}
            onChange={(e: any) => {
              prop.onRowDataChange({
                ...prop.rowData,
                eventParticipant2: {
                  ...prop.rowData.eventParticipant2,
                  americanOdds: e.target.value > 0 ? `+${e.target.value}` : e.target.value,
                },
              });
            }}
            error={prop?.error}
            helperText={prop?.helperText || ''}
            thousandSeparator={false}
            decimalScale={0}
            allowNegative={true}
            sx={editCellTextFieldStyle}
          />
        </>
      ),
    },
    {
      title: 'Place',
      field: 'eventParticipant2.position',
      align: 'right',
      headerStyle: { minWidth: '4.375rem', maxWidth: '4.375rem' },
      /* validate: (row: PlayerHeadToHeadOddRow) => {
        return Number(row?.eventParticipant2?.position) <= 0 || !row?.eventParticipant2?.position
          ? 'Enter Player place greater than 0'
          : Number(row?.eventParticipant1?.position || 0) ===
            Number(row?.eventParticipant2?.position || 0)
          ? "Match up players can't have same place"
          : true;
      }, */
      sorting: isSortAllowed,
      editComponent: (props: any) => (
        <>
          <TextField
            data-testid="eventParticipant2Position"
            sx={{
              ...headToHeadTableStyles.editCellTextFieldStyle,
              input: { textAlign: 'right' },
            }}
            value={props.value === '--' ? '' : props.value}
            onChange={(e) => {
              props.onChange(Number(e.target.value));
            }}
            error={props?.error}
            helperText={props?.helperText || ''}
          />
        </>
      ),
    },
    {
      title: 'Winner',
      field: 'winnerParticipantId',
      align: 'left',
      editable: 'never',
      headerStyle: { minWidth: '4.375rem', maxWidth: '4.375rem' },
      sorting: isSortAllowed,
      render: (row: PlayerHeadToHeadOddRow) => (
        <>
          <Switch
            data-testid="eventParticipant2WinnerToggle"
            disabled={
              row.winnerParticipantId !== null || row.editedVoid || row.draw || row.editedDraw
            }
            checked={
              row.winnerParticipantId === row.eventParticipant2.id ||
              row.newWinnerId === row.eventParticipant2.id
            }
            onChange={(e) =>
              handleToggleChange({
                checked: e.target.checked,
                type: TOGGLE_OPTIONS.WINNER,
                rowMatchUpId: row.id,
                winnerId: row.eventParticipant2.id,
              })
            }
          />
        </>
      ),
    },
    {
      title: 'Probability',
      field: 'eventParticipant2.probability',
      align: 'right',
      headerStyle: { minWidth: '5.375rem', maxWidth: '5.375rem' },
      validate: (row: any) => {
        const player2Probability = +row.eventParticipant2.probability;
        return player2Probability < oddRanges.MIN_PROBABILITY ||
          player2Probability > oddRanges.MAX_PROBABILITY
          ? {
              isValid: false,
              helperText: validationMessages.PLAYER_MATCH_UP_PROBABILITY_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (prop: any) => (
        <TextField
          data-testid="eventParticipant2Probability"
          variant="outlined"
          value={round(+prop?.value, 2)}
          type="number"
          inputProps={{
            step: '0.01',
          }}
          onBlur={(e) => {
            if (!prop.error) {
              const modPlayerMatchUp = playerMatchUpHelpers.editMatchUpRowProbabilityFormatter(
                +e.target.value,
                prop.rowData,
                { isPlayer2Modified: true },
              );
              prop.onRowDataChange(modPlayerMatchUp);
            }
          }}
          onChange={(e: any) => {
            prop.onRowDataChange({
              ...prop.rowData,
              eventParticipant2: {
                ...prop.rowData.eventParticipant2,
                probability: e.target.value,
              },
            });
          }}
          error={prop?.error}
          helperText={prop?.helperText || ''}
          sx={editCellTextFieldStyle}
        />
      ),
    },
    {
      title: 'Draw',
      field: 'draw',
      align: 'right',
      editable: 'never',
      headerStyle: { minWidth: '4.375rem', maxWidth: '4.375rem' },
      sorting: isSortAllowed,
      render: (row: PlayerHeadToHeadOddRow) => (
        <>
          <Switch
            data-testid="drawToggle"
            disabled={
              row.winnerParticipantId !== null ||
              row.newWinnerId !== '' ||
              row.editedVoid ||
              row.draw
            }
            checked={row.editedDraw}
            onChange={(e) =>
              handleToggleChange({
                checked: e.target.checked,
                type: TOGGLE_OPTIONS.DRAW,
                rowMatchUpId: row.id,
              })
            }
          />
        </>
      ),
    },
    {
      title: 'Voided',
      field: 'voided',
      align: 'right',
      editable: 'never',
      headerStyle: { minWidth: '4.375rem', maxWidth: '4.375rem' },
      sorting: isSortAllowed,
      render: (row: PlayerHeadToHeadOddRow) => (
        <>
          <Switch
            data-testid="voidToggle"
            disabled={
              row.winnerParticipantId !== null ||
              row.newWinnerId !== '' ||
              row.draw ||
              row.editedDraw
            }
            checked={row.editedVoid}
            onChange={(e) => {
              handleToggleChange({
                checked: e.target.checked,
                type: TOGGLE_OPTIONS.VOIDED,
                rowMatchUpId: row.id,
              });
              setTempVoidedRowId(row?.id);
              handleToggleConfirmVoidDialog();
            }}
          />
        </>
      ),
    },
  ];

  return headers;
};
