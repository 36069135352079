import * as React from 'react';
import { useRecoilState } from 'recoil';
import round from 'lodash.round';
import { enqueueSnackbar } from 'notistack';

import {
  marketOddsPayload,
  editSubMarketOddsFormatter,
  externalMarketOddsPayload,
  externalMarketOddModifiers,
  getFirstUnlockedOrLockedSubMkt,
  processBets,
  getBetCommonValue,
  isFieldConsistentAcrossTeams,
  processBet,
  calcHoldPercentageOfSubMkt,
  modSubMarket,
  // DEPRECATED: Market, Bet and Sub Market code
  // editSubMarketCommonOddsFormatter,
} from '../marketHelper';
import { BACKGROUND_COLOR, BORDER_COLOR, EXTERNAL_UPDATE_TRIGGER_FIELDS } from '../constants';
import { oddsType } from '@/atoms/oddsType';

import { MarketTypes, JABetTypes, JAMarketTypes } from '@/constants/jaialai';
// JAMarketTypes,
import { getKeysWithObjectValues } from '@/helpers/misc';

import {
  // Styled Components
  Accordion,
} from '../components';
import { teamTypeToKey, fieldsThatTriggerExternalUpdates } from '../constants';
import AccordionSummaryWithMarketHeader from './AccordionSummaryWithMarketHeader';
import BetTypeHeaders from './BetTypeHeaders';
import MarketAccordionDetails from './MarketAccordionDetails';
// import PublishOdds from '@/components/PublishOdds';
// import { ODDS_PROJECTION_TYPE } from '@/constants/oddsProjectionType';

import cloneDeep from 'lodash.clonedeep';
import { sendEvent } from '@/utils/socketService';
import { JAI_ALAI_NAMESPACE } from '../../..';
import { WEB_SOCKET_EMIT_EVENT_TYPES } from '@/constants/socket';
import { JAIALAIGameContext } from '@/contexts/jaialaiGame.context';

export const MIN_HOLD_PERCENTAGE = 100;
export const MAX_HOLD_PERCENTAGE = 200;

const BetRow = ({
  value,
  bet,
  betId,
  oddId,
  oddType,
  setFieldValue,
  externalUpdateOdds,
  externalUpdateOddsOnUnlock,
  holdPercentage,
  holdPercentagesOptions,
  disabledMarkets,
  values,
}: any) => {
  const BET_COMMON_VALUE = getBetCommonValue(bet, 'probability');

  // DEPRECATED: Market, Bet and Sub Market code
  /*  const holdPercentageCustom = React.useMemo(() => {
    let totalProbability = 0;
    Object.values(bet).map((bt: any) => {
      if (typeof bt !== 'string') {
        totalProbability += +bt?.[teamTypeToKey.homeTeam]?.probability || 0;
      }
    });

    return round(totalProbability);
  }, [bet]); */

  const updateSubMarketTeam = (row: any, subMarketType: number, teamType: string) => {
    if (!row?.id) {
      return; // Exit early if row.id is not available
    }
    // DEPRECATED: Market, Bet and Sub Market code
    // const isCommonOdds = [JABetTypes.CORRECT_SETS].includes(+bet.betType);
    const isHomeTeam = teamType === teamTypeToKey.homeTeam;

    // Get current team data
    const currentTeamDataHome = bet?.[subMarketType]?.[teamTypeToKey.homeTeam];
    const currentTeamDataAway = bet?.[subMarketType]?.[teamTypeToKey.awayTeam];

    const commonTeamData = {
      homeTeam: isHomeTeam ? row : currentTeamDataHome,
      awayTeam: !isHomeTeam ? row : currentTeamDataAway,
    };

    /*  if (isCommonOdds) {
      const clonedBet = cloneDeep(bet);

      Object.keys(clonedBet).forEach((key) => {
        if (typeof clonedBet[key] !== 'object') {
          delete clonedBet[key];
        }
      });

      const modBet = editSubMarketCommonOddsFormatter({
        isHomeTeamModified: isHomeTeam,
        homeTeam: commonTeamData.homeTeam,
        awayTeam: commonTeamData.awayTeam,
        holdPercentage: holdPercentageCustom,
        subMarkets: clonedBet,
        subMarketType,
      });

      setFieldValue(`odds[${oddId}].bets[${betId}]`, { ...modBet, betType: +bet.betType });
      return;
    } */

    // Apply edits to the team data using editSubMarketOddsFormatter
    const { homeTeam, awayTeam } = editSubMarketOddsFormatter({
      isHomeTeamModified: isHomeTeam,
      homeTeam: commonTeamData.homeTeam,
      awayTeam: commonTeamData.awayTeam,
      holdPercentage,
      commonOdds: false,
    });

    if (homeTeam) {
      setFieldValue(`odds[${oddId}].bets[${betId}].${subMarketType}.${teamTypeToKey.homeTeam}`, {
        ...homeTeam,
      });
    }
    if (awayTeam) {
      setFieldValue(`odds[${oddId}].bets[${betId}].${subMarketType}.${teamTypeToKey.awayTeam}`, {
        ...awayTeam,
      });
    }
  };

  const updateSubMarketTeamKey = ({
    subMarketType,
    teamType,
    key,
    val,
    allowExternalUpdate = true,
  }: any) => {
    if (fieldsThatTriggerExternalUpdates.includes(key)) {
      setFieldValue(
        `odds[${oddId}].bets[${betId}].${subMarketType}.${teamType}.${key}`,
        val,
        false,
      );

      if (allowExternalUpdate) {
        const currentVal = { ...value };

        const currentBets = currentVal.bets || [];
        currentBets[betId][subMarketType][teamType][key] = val;
        currentVal.bets = currentBets;
        const currentBetType = currentBets[betId][subMarketType][teamType]?.betType;
        const currentMarketType = currentBets[betId][subMarketType][teamType]?.marketType;
        externalUpdateOdds({
          odds: [currentVal],
          oddId,
          autoSave: key === EXTERNAL_UPDATE_TRIGGER_FIELDS.bias,
          isHomeTeam: teamType === teamTypeToKey.homeTeam,
          betType:
            +currentMarketType === JAMarketTypes.SETS ? currentBetType : Number(currentBetType),
          marketType: Number(currentMarketType),
          field: key,
          val: val,
          betId,
        });
      }
    } else {
      setFieldValue(
        `odds[${oddId}].bets[${betId}].${subMarketType}.${teamType}.${key}`,
        val,
        false,
      );
    }
  };

  const toggleSubMarketLock = (subMarketType: number) => {
    const betCpy = cloneDeep(bet);

    const currentSubMarket = betCpy[subMarketType];
    if (!currentSubMarket) return;

    const oddsCopy = cloneDeep(values?.odds || []);
    const unlockedBet = processBet(betCpy, false);
    const lockedBet = processBet(betCpy, true);
    const currentBet = Object.keys(unlockedBet).length > 1 ? unlockedBet : lockedBet;
    const BET_COMMON_VALUE_CPY: any = getBetCommonValue(currentBet);
    const valueCpy = cloneDeep(value);
    const unlockedLean = getFirstUnlockedOrLockedSubMkt(oddsCopy);

    const currentPayload = externalMarketOddsPayload({ odds: [{ bets: [betCpy] }] });
    const canBypassExternalOddsUpdate = ['playerLean', 'bias'].every((field) => {
      const { isHomeTeamFieldConsistent, isAwayTeamFieldConsistent } = isFieldConsistentAcrossTeams(
        currentPayload,
        field as keyof {},
        field === 'playerLean' ? unlockedLean : undefined,
      );
      return isHomeTeamFieldConsistent && isAwayTeamFieldConsistent;
    });

    const currentSubMktHoldPercentage = calcHoldPercentageOfSubMkt(currentSubMarket);
    const isHoldPercentageDifferent = +holdPercentage !== currentSubMktHoldPercentage;

    const { homeTeam, awayTeam } = currentSubMarket;
    const isLocked = homeTeam?.isSubMarketLocked && awayTeam?.isSubMarketLocked;

    if (!isLocked || canBypassExternalOddsUpdate) {
      [teamTypeToKey.homeTeam, teamTypeToKey.awayTeam].forEach((team) => {
        if (isHoldPercentageDifferent) {
          const teamData = currentSubMarket[team];
          const modifiedSubMkt = modSubMarket({
            subMarketKey: team,
            currentValSubMarket: { ...teamData, isSubMarketLocked: !isLocked },
            newHoldPercentage: holdPercentage,
            oldHoldPercentage: currentSubMktHoldPercentage,
          });

          setFieldValue(
            `odds[${oddId}].bets[${betId}].${subMarketType}.${team}`,
            modifiedSubMkt[team],
            false,
          );
        } else {
          updateSubMarketTeamKey({
            subMarketType,
            teamType: team,
            key: 'isSubMarketLocked',
            val: !isLocked,
          });
        }
      });
      return;
    }

    // External odds update is required
    enqueueSnackbar('Unlocking Sub Market!');

    const unlockedHomeTeam = unlockedLean?.[teamTypeToKey.homeTeam];
    const unlockedAwayTeam = unlockedLean?.[teamTypeToKey.awayTeam];
    const currentMarketType = Number(homeTeam?.marketType);
    const currentBetType =
      currentMarketType === JAMarketTypes.SETS ? homeTeam?.betType : Number(homeTeam?.betType);
    const unlockedBias = +BET_COMMON_VALUE_CPY?.[teamTypeToKey.homeTeam]?.bias || 0;

    if (isHoldPercentageDifferent) {
      [teamTypeToKey.homeTeam, teamTypeToKey.awayTeam].forEach((team) => {
        const teamData = valueCpy.bets[betId][subMarketType][team];
        const modifiedSubMkt = modSubMarket({
          subMarketKey: team,
          currentValSubMarket: { ...teamData, isSubMarketLocked: !isLocked },
          newHoldPercentage: holdPercentage,
          oldHoldPercentage: currentSubMktHoldPercentage,
        });
        valueCpy.bets[betId][subMarketType][team] = modifiedSubMkt[team];
      });
    }

    externalUpdateOddsOnUnlock({
      odds: [valueCpy],
      oddId,
      betId,
      isHomeTeam: true,
      marketType: currentMarketType,
      subMarketType,
      betType: currentBetType,
      bias: unlockedBias,
      homePlayerLean: +unlockedHomeTeam?.playerLean,
      awayPlayerLean: +unlockedAwayTeam?.playerLean,
      isSubMarketUnlocked: true,
    });
  };

  const updateBias = (team: string, val: number) => {
    getKeysWithObjectValues(bet)?.forEach((subMarketVal, idx, arr) => {
      const currentSubMarket = bet[subMarketVal];
      if (currentSubMarket) {
        const currentTeam = currentSubMarket[team];
        if (currentTeam) {
          // Get the current teamPath.bias value
          const newBias = +val; // Assuming 'val' is already defined

          // Update the fields using a single setFieldValue call
          updateSubMarketTeamKey({
            subMarketType: subMarketVal,
            teamType: team,
            key: 'bias',
            val: newBias,
            allowExternalUpdate: arr.length - 1 === idx ? true : false,
          });

          if (!currentTeam?.hasModifiedProbability) {
            updateSubMarketTeamKey({
              subMarketType: subMarketVal,
              teamType: team,
              key: 'hasModifiedProbability',
              val: true,
              allowExternalUpdate: arr.length - 1 === idx ? true : false,
            });
          }
        }
      }
    });
  };

  const commonCellHeaderStyles = {
    backgroundColor: marketOddsPayload({ odds: [{ ...value, bets: [value?.bets[betId]] }] })?.some(
      (odd) => odd?.isMarketActive,
    )
      ? BACKGROUND_COLOR.ACTIVE
      : BACKGROUND_COLOR.SUSPENDED,
    boxShadow: `0 0 0 1px ${
      marketOddsPayload({ odds: [{ ...value, bets: [value?.bets[betId]] }] })?.some(
        (odd) => odd?.isMarketActive,
      )
        ? BORDER_COLOR.ACTIVE
        : BORDER_COLOR.SUSPENDED
    }`,
  };

  return (
    <>
      <BetTypeHeaders
        value={value}
        bet={bet}
        betType={+BET_COMMON_VALUE?.homeTeam?.betType}
        commonCellHeaderStyles={commonCellHeaderStyles}
        holdPercentagesOptions={holdPercentagesOptions}
        setFieldValue={setFieldValue}
        oddId={oddId}
      />
      <MarketAccordionDetails
        key={betId}
        value={value}
        bet={bet}
        oddType={oddType}
        toggleSubMarketLock={toggleSubMarketLock}
        updateSubMarketTeam={updateSubMarketTeam}
        updateSubMarketTeamKey={updateSubMarketTeamKey}
        updateBias={updateBias}
        disabledMarkets={disabledMarkets}
        oddId={oddId}
        betId={betId}
        setFieldValue={setFieldValue}
        values={values}
        externalUpdateOddsOnUnlock={externalUpdateOddsOnUnlock}
        holdPercentage={holdPercentage}
      />
    </>
  );
};

const MarketTypesDetails = ({
  formik,
  disabledMarkets,
  updateOdds, // setDisabledMarkets,  // handlePublishOdds
}: {
  formik: any;
  disabledMarkets: { [key: string]: boolean };
  setDisabledMarkets: any;
  updateOdds: any;
  handlePublishOdds: (val: string) => void;
}) => {
  const { handleDisableMatch } = React.useContext(JAIALAIGameContext);
  const { values, setFieldValue } = formik;
  const [accordion, setAccordion] = React.useState<{ [key: string]: boolean }>(
    MarketTypes.reduce((o, val) => ({ ...o, [val]: true }), {}),
  );

  const [oddType] = useRecoilState(oddsType);
  const holdPercentagesOptions = React.useMemo(
    () =>
      Array(MAX_HOLD_PERCENTAGE - MIN_HOLD_PERCENTAGE + 1)
        .fill(0)
        .map((_, idx) => MIN_HOLD_PERCENTAGE + idx),
    [MIN_HOLD_PERCENTAGE, MAX_HOLD_PERCENTAGE],
  );

  const externalUpdateOdds = ({
    odds,
    oddId,
    autoSave = false,
    isHomeTeam = false,
    betType,
    marketType,
    field,
    val, // betId,
  }: {
    odds: any;
    oddId: number;
    autoSave?: boolean;
    betType: number;
    marketType: number;
    isHomeTeam: boolean;
    field: string;
    val: number;
    betId?: number;
  }) => {
    const [firstOdd] = odds;

    const finalPayload = externalMarketOddsPayload({ odds });

    if (field === EXTERNAL_UPDATE_TRIGGER_FIELDS.bias) {
      enqueueSnackbar('Updating Market Odds Calc!');
      handleDisableMatch();

      // DEPRECATED: Market, Bet and Sub Market code
      /* const intermediateFinalPayload = externalMarketOddsPayload({
        odds: [{ ...odds[0], bets: [odds[0]?.bets[betId ?? 0]] }],
      });
      const valChangedForBias = intermediateFinalPayload.find((cVal) => cVal.bias === val); */

      // Apply bias change only for unlocked odds

      finalPayload.forEach((data: any) => {
        if (data?.isSubMarketLocked) return;
        if (Number(data?.marketType) === JAMarketTypes.SETS) {
          data.bias = data.isHomeTeam === isHomeTeam ? val : val * -1;
          return;
        }
        if (Number(data?.marketType) === JAMarketTypes.SETS) {
          data.bias = data.isHomeTeam === isHomeTeam ? val : val * -1;
          return;
        }
        // DEPRECATED: Market, Bet and Sub Market code
        /*  if (
          Number(data?.marketType) === JAMarketTypes.SERVE &&
          data?.eventNumber === valChangedForBias?.eventNumber &&
          data?.setName === valChangedForBias?.setName &&
          data?.points === valChangedForBias?.points
        ) {
          data.bias = data.isHomeTeam === isHomeTeam ? val : val * -1;
          return;
        } */
        if (
          // DEPRECATED: Market, Bet and Sub Market code
          // ![JAMarketTypes.SERVE, JAMarketTypes.SETS].includes(+data?.marketType) &&
          ![JAMarketTypes.SETS].includes(+data?.marketType) &&
          Number(data?.betType) === Number(betType) &&
          Number(data?.marketType) === Number(marketType)
        ) {
          data.bias = data.isHomeTeam === isHomeTeam ? val : val * -1;
          return;
        }
      });
    }

    sendEvent(
      JAI_ALAI_NAMESPACE,
      WEB_SOCKET_EMIT_EVENT_TYPES.FETCH_GAME_MARKET_ODDS,
      finalPayload,
      ({ data }) => {
        if (typeof data === 'object') {
          const oddsCopy = [...values.odds];
          const currentModOdd = externalMarketOddModifiers({
            marketOdds: firstOdd,
            externalOdds: data?.data || [],
          });
          oddsCopy[oddId] = currentModOdd;

          setFieldValue(`odds[${oddId}]`, currentModOdd);

          if (autoSave) {
            updateOdds({ odds: oddsCopy });
          }
        }
      },
    );
  };

  const externalUpdateOddsOnUnlock = ({
    odds,
    oddId,
    betId,
    isHomeTeam = false,
    betType,
    marketType,
    subMarketType,
    bias,
    homePlayerLean,
    awayPlayerLean,
    isMarketUnlocked = false,
    isBetUnlocked = false,
    isSubMarketUnlocked = false,
  }: {
    odds: any;
    oddId: number;
    betId: number;
    betType: number;
    marketType: number;
    subMarketType: number;
    isHomeTeam: boolean;
    bias: number;
    homePlayerLean: number;
    awayPlayerLean: number;
    isMarketUnlocked?: boolean;
    isBetUnlocked?: boolean;
    isSubMarketUnlocked?: boolean;
  }) => {
    const [firstOdd] = odds;
    const finalPayload = externalMarketOddsPayload({ odds });

    const marketTypeBias: any = {};

    if (isMarketUnlocked) {
      firstOdd.bets.forEach((currentBet: any) => {
        const BET_COMMON_VALUE: any = getBetCommonValue(currentBet);
        const currentHomeTeam = BET_COMMON_VALUE[teamTypeToKey.homeTeam];
        marketTypeBias[currentHomeTeam?.betType] = +currentHomeTeam?.bias;
      });
    }

    // Apply bias and player lean change only for unlocked odds
    const updateTeamData = (data: any, biasValue: number) => {
      const leanValue = data.isHomeTeam ? homePlayerLean : awayPlayerLean;
      data.bias = data.isHomeTeam === isHomeTeam ? biasValue : biasValue * -1;
      data.playerLean = leanValue;
      data.isSubMarketLocked = false;
    };

    finalPayload.forEach((data: any) => {
      const dataMarketType = Number(data?.marketType);
      const dataSubMarketType = Number(data?.subMarketType);
      const dataBetType = Number(data?.betType);

      if (isSubMarketUnlocked) {
        const isMatchingSubMarketType =
          (dataMarketType === JAMarketTypes.SETS && dataSubMarketType === subMarketType) ||
          (dataMarketType === marketType &&
            dataBetType === betType &&
            dataSubMarketType === subMarketType);

        if (isMatchingSubMarketType) {
          updateTeamData(data, bias);
        }
      }

      if (isBetUnlocked) {
        const isMatchingBetType =
          dataMarketType === JAMarketTypes.SETS ||
          (dataMarketType === marketType && dataBetType === betType);

        if (isMatchingBetType) {
          updateTeamData(data, bias);
        }
      }

      if (isMarketUnlocked) {
        const isMatchingMarketType = dataMarketType === marketType;

        if (isMatchingMarketType) {
          const currentBias = marketTypeBias[data?.betType];
          updateTeamData(data, currentBias);
        }
      }
    });

    const unlockCurrentSubMarket = (currentBetData: any, currentSubMarketType: number) => {
      currentBetData[currentSubMarketType][teamTypeToKey.homeTeam].isSubMarketLocked = false;
      currentBetData[currentSubMarketType][teamTypeToKey.awayTeam].isSubMarketLocked = false;
    };

    const unlockAllCurrentSubMarkets = (currentBetData: any) => {
      Object.keys(currentBetData).forEach((currentSubMarketType) => {
        if (typeof currentBetData[currentSubMarketType] === 'object') {
          unlockCurrentSubMarket(currentBetData, +currentSubMarketType as number);
        }
      });
    };

    const unlockAllCurrentBetsAndSubMarkets = (currentBetsCopy: any) => {
      currentBetsCopy.forEach((currentBetData: any) => {
        unlockAllCurrentSubMarkets(currentBetData);
      });
    };

    sendEvent(
      JAI_ALAI_NAMESPACE,
      WEB_SOCKET_EMIT_EVENT_TYPES.FETCH_GAME_MARKET_ODDS,
      finalPayload,
      ({ data }) => {
        if (typeof data === 'object') {
          const currentOddsCopy = [...values.odds];

          if (isSubMarketUnlocked) {
            unlockCurrentSubMarket(firstOdd.bets[betId], subMarketType);
          }

          if (isBetUnlocked) {
            unlockAllCurrentSubMarkets(firstOdd.bets[betId]);
          }

          if (isMarketUnlocked) {
            unlockAllCurrentBetsAndSubMarkets(firstOdd.bets);
          }

          const modifiedOdds = externalMarketOddModifiers({
            marketOdds: firstOdd,
            externalOdds: data?.data || [],
          });

          currentOddsCopy[oddId] = modifiedOdds;
          setFieldValue(`odds[${oddId}]`, modifiedOdds);

          updateOdds({ odds: currentOddsCopy });
        }
      },
    );
  };

  return React.Children.toArray(
    values.odds.map((value: any, oddId: number) => {
      const extractBets = (currentBts: any[], betTypes: number[] = []) => {
        return currentBts.reduce((acc, currentBt) => {
          const betType = +currentBt.betType;

          // Skip if the betType is not included in the list and betTypes isn't empty
          if (betTypes.length > 0 && !betTypes.includes(betType)) return acc;

          const currentSubMkt = getBetCommonValue(currentBt, 'probability');
          const subMarketType = currentSubMkt.subMarketType;

          const newAcc = {
            [subMarketType]: currentSubMkt,
          };

          // If acc is empty, initialize with the current value
          if (Object.keys(acc).length === 0) return newAcc;

          const firstSubMktKey = Object.keys(acc)[0];
          const existingSubMarket = acc[firstSubMktKey];

          // Helper to extract probabilities with safeguards
          const getCurrentProbability = (
            subMkt: any,
            teamKey: string /* isCorrectSets: boolean */,
          ) =>
            // DEPRECATED: Market, Bet and Sub Market code
            // isCorrectSets ? 0 : +subMkt[teamKey]?.probability || 0;
            +subMkt[teamKey]?.probability || 0;

          // DEPRECATED: Market, Bet and Sub Market code
          // const isExistingBetCorrectSets =
          //   existingSubMarket[teamTypeToKey.awayTeam]?.betType === JABetTypes.CORRECT_SETS;

          const existingHomeProbability = getCurrentProbability(
            existingSubMarket,
            teamTypeToKey.homeTeam,
            // DEPRECATED: Market, Bet and Sub Market code
            // false,
          );
          const existingAwayProbability = getCurrentProbability(
            existingSubMarket,
            teamTypeToKey.awayTeam,
            // DEPRECATED: Market, Bet and Sub Market code
            // isExistingBetCorrectSets,
          );

          // DEPRECATED: Market, Bet and Sub Market code
          // const isNewBetCorrectSets =
          //   currentSubMkt[teamTypeToKey.awayTeam]?.betType === JABetTypes.CORRECT_SETS;

          const newHomeProbability = getCurrentProbability(
            currentSubMkt,
            teamTypeToKey.homeTeam,
            // DEPRECATED: Market, Bet and Sub Market code
            // false,
          );
          const newAwayProbability = getCurrentProbability(
            currentSubMkt,
            teamTypeToKey.awayTeam,
            // DEPRECATED: Market, Bet and Sub Market code
            // isNewBetCorrectSets,
          );

          const existingTotalProbability = existingHomeProbability + existingAwayProbability;
          const newTotalProbability = newHomeProbability + newAwayProbability;

          // Replace acc if new probabilities are higher
          return newTotalProbability > existingTotalProbability ? newAcc : acc;
        }, {});
      };

      // Main function to process current bets
      const processCurrentBets = (valueCpy: any) => {
        const { bets, marketType } = valueCpy;

        // Process unlocked and locked bets
        const unlockedBets = processBets(bets, false);
        const lockedBets = processBets(bets, true);

        // Determine current bets (unlocked takes precedence over locked)
        const currentBets = unlockedBets.length > 0 ? unlockedBets : lockedBets;

        // Determine the relevant bets and first bet based on market type
        const isMatchMarket = +marketType === JAMarketTypes.MATCH;
        let firstBet = {};

        if (isMatchMarket) {
          // Filter non-correct sets bets
          const nonCorrectSetsBets = currentBets.filter(
            (bT: any) => +bT.betType !== JABetTypes.CORRECT_SETS,
          );
          // DEPRECATED: Market, Bet and Sub Market code
          /*  const nonCorrectSetsBets = currentBets.filter(
            (bT: any) => +bT.betType !== JABetTypes.CORRECT_SETS,
          ); 
          const lockedNonCorrectSetsBets = lockedBets.filter(
            (bT: any) => +bT.betType !== JABetTypes.CORRECT_SETS,
          );
          */
          // Use non-correct sets bets if available, fallback to currentBets
          firstBet =
            extractBets(
              // DEPRECATED: Market, Bet and Sub Market code
              // nonCorrectSetsBets.length > 0 ? nonCorrectSetsBets : lockedNonCorrectSetsBets,
              nonCorrectSetsBets,
            ) || {};
        } else {
          // For non-MATCH market types, use extractBets directly
          firstBet = extractBets(currentBets) || {};
        }

        // Calculate common value
        const COMMON_VALUE = getBetCommonValue(firstBet, 'probability');

        // Safely calculate home and away probabilities
        const homeProb = Number(COMMON_VALUE?.[teamTypeToKey.homeTeam]?.probability || 0);
        const awayProb = Number(COMMON_VALUE?.[teamTypeToKey.awayTeam]?.probability || 0);
        const calcProbability = homeProb + awayProb;

        return { currentBets, firstBet, COMMON_VALUE, calcProbability };
      };

      const { COMMON_VALUE, calcProbability } = processCurrentBets(value);

      const holdPercentage = round(calcProbability as number) || MIN_HOLD_PERCENTAGE;

      const toggleIsMarketActive = (isMarketActive: boolean) => {
        value.bets.forEach((_: any, id: number) => {
          getKeysWithObjectValues(value.bets[id])?.forEach((subMarketVal) => {
            const currentSubMarket = value.bets[id]?.[subMarketVal];
            if (currentSubMarket) {
              [teamTypeToKey.homeTeam, teamTypeToKey.awayTeam].forEach((team) => {
                const currentTeam = currentSubMarket[team];
                if (currentTeam) {
                  setFieldValue(
                    `odds[${oddId}].bets[${id}].${subMarketVal}.${team}.isMarketActive`,
                    isMarketActive,
                  );
                }
              });
            }
          });
        });
      };

      const toggleMarketLock = (isSubMarketLocked: boolean) => {
        const oddsCopy = cloneDeep(values?.odds || []);
        const valueCpy = cloneDeep(value);
        const betsCpy = cloneDeep(value?.bets || []);

        const unlockedLean = getFirstUnlockedOrLockedSubMkt(oddsCopy);

        const canBypassExternalOddsUpdate = betsCpy.every((currentBet: any) => {
          const currentPayload = externalMarketOddsPayload({ odds: [{ bets: [currentBet] }] });
          const currentCanBypassExternalOddsUpdate = ['playerLean', 'bias'].every((field) => {
            const { isHomeTeamFieldConsistent, isAwayTeamFieldConsistent } =
              isFieldConsistentAcrossTeams(
                currentPayload,
                field as keyof {},
                field === 'playerLean' ? unlockedLean : undefined,
              );
            return isHomeTeamFieldConsistent && isAwayTeamFieldConsistent;
          });
          return currentCanBypassExternalOddsUpdate;
        });

        const updateBets = (bets: any[], callback: Function) => {
          return bets.map((bet) => {
            const newBet = { ...bet };
            Object.keys(newBet).forEach((subMarketVal) => {
              if (typeof newBet[subMarketVal] === 'object') {
                const currentSubMarket = newBet[subMarketVal];
                if (currentSubMarket) {
                  const currentSubMktHoldPercentage = calcHoldPercentageOfSubMkt(currentSubMarket);
                  const isHoldPercentageDifferent = +holdPercentage !== currentSubMktHoldPercentage;
                  [teamTypeToKey.homeTeam, teamTypeToKey.awayTeam].forEach((team) => {
                    if (isHoldPercentageDifferent) {
                      const teamData = currentSubMarket[team];
                      const modifiedSubMkt = modSubMarket({
                        subMarketKey: team,
                        currentValSubMarket: {
                          ...teamData,
                          isSubMarketLocked: !isSubMarketLocked,
                        },
                        newHoldPercentage: holdPercentage,
                        oldHoldPercentage: currentSubMktHoldPercentage,
                      });
                      currentSubMarket[team] = modifiedSubMkt[team];
                    } else callback(team, currentSubMarket);
                  });
                }
              }
            });
            return newBet;
          });
        };

        if (!isSubMarketLocked || canBypassExternalOddsUpdate) {
          // Update bets for isSubMarketLocked and playerLean
          const modifiedBets = updateBets(betsCpy, (team: string, currentSubMarket: any) => {
            const currentTeam = currentSubMarket[team];
            if (!currentTeam) return;

            currentTeam.isSubMarketLocked = !isSubMarketLocked;
          });

          setFieldValue(`odds[${oddId}].bets`, modifiedBets, false);

          return;
        }

        // External odds update is required
        enqueueSnackbar('Unlocking Market!');
        const unlockedHomeTeam = unlockedLean?.[teamTypeToKey.homeTeam];
        const unlockedAwayTeam = unlockedLean?.[teamTypeToKey.awayTeam];
        const currentMarketType = Number(valueCpy?.marketType);

        valueCpy.bets.forEach((currentBet: any, currentBetId: number) => {
          getKeysWithObjectValues(currentBet)?.forEach((subMarketVal) => {
            const currentSubMarket = currentBet[subMarketVal];
            if (!currentSubMarket) return;

            const currentSubMktHoldPercentage = calcHoldPercentageOfSubMkt(currentSubMarket);
            const isHoldPercentageDifferent = +holdPercentage !== currentSubMktHoldPercentage;
            [teamTypeToKey.homeTeam, teamTypeToKey.awayTeam].forEach((team) => {
              if (isHoldPercentageDifferent) {
                const teamData = valueCpy.bets[currentBetId][subMarketVal][team];
                const modifiedSubMkt = modSubMarket({
                  subMarketKey: team,
                  currentValSubMarket: {
                    ...teamData,
                    isSubMarketLocked: !isSubMarketLocked,
                  },
                  newHoldPercentage: holdPercentage,
                  oldHoldPercentage: currentSubMktHoldPercentage,
                });
                valueCpy.bets[currentBetId][subMarketVal][team] = modifiedSubMkt[team];
              }
            });
          });
        });

        externalUpdateOddsOnUnlock({
          odds: [valueCpy],
          oddId,
          homePlayerLean: +unlockedHomeTeam?.playerLean,
          awayPlayerLean: +unlockedAwayTeam?.playerLean,
          isMarketUnlocked: true,
          // Default values ignored
          betId: 0,
          isHomeTeam: true,
          marketType: currentMarketType,
          subMarketType: 0,
          betType: 0,
          bias: 0,
        });
      };

      return (
        <Accordion
          expanded={accordion[value?.marketType]}
          onChange={() => {
            setAccordion((prevValue) => ({
              ...prevValue,
              [value?.marketType]: !accordion[value?.marketType],
            }));
          }}
        >
          <AccordionSummaryWithMarketHeader
            value={value}
            holdPercentage={holdPercentage}
            holdPercentagesOptions={holdPercentagesOptions}
            oddId={oddId}
            setFieldValue={setFieldValue}
            toggleIsMarketActive={toggleIsMarketActive}
            toggleMarketLock={toggleMarketLock}
            disabledMarkets={disabledMarkets}
            bet={COMMON_VALUE?.[teamTypeToKey.homeTeam]}
          />
          {React.Children.toArray(
            value.bets.map((bet: any, betId: number) => (
              <BetRow
                value={value}
                bet={bet}
                betId={betId}
                oddId={oddId}
                oddType={oddType}
                setFieldValue={setFieldValue}
                externalUpdateOdds={externalUpdateOdds}
                externalUpdateOddsOnUnlock={externalUpdateOddsOnUnlock}
                holdPercentage={holdPercentage}
                holdPercentagesOptions={holdPercentagesOptions}
                disabledMarkets={disabledMarkets}
                formik={formik}
                values={values}
              />
            )),
          )}
        </Accordion>
      );
    }),
  );
};

export default MarketTypesDetails;
