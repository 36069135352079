import React, { useState } from 'react';
// import round from 'lodash.round';
import { useRecoilState } from 'recoil';
import MaterialTable, { Column } from '@material-table/core';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import AddIcon from '@mui/icons-material/Add';

import { Box, Grid, styled, Button } from '@mui/material';

import { oddsType } from '@/atoms/oddsType';

import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import SaveOddsBtn from '@/components/SaveOddsBtn';
import ResetOddsBtn from '@/components/ResetOddsBtn';
import ExportBtn from '@/components/ExportBtn';
import PublishOdds from '@/components/PublishOdds';
import AddEventExactasEventDialog from '../components/AddExactasEventOddDialog';

import useUpdateExactasEventOddsPayout from '@/hooks/odds/exactas/exactasEventOdds/useUpdateExactasEventOddsPayout';
import useAddExactasEventOdds from '@/hooks/odds/exactas/exactasEventOdds/useAddExactasEventOdds';

// import * as oddsHelpers from '@/helpers/odds';
import {
  // initialExactasEventOddsDataLoader,
  exactasEventOddsFreshWinnerDrawCount,
  // exactaEventOddsPayloadFormatter,
} from '@/helpers/exactas';

import { ODDS_PROJECTION_TYPE } from '@/constants/oddsProjectionType';
import { HeatStatus } from '@/constants/heats';
import * as httpStatusCodes from '@/constants/httpStatus';
import { invalidateExactasHeatOdds } from '@/helpers/cachedQueries';
import { EXACTAS_TYPE } from '@/constants/oddTabs';

import { tableIcons, defaultTableOptions, createTablePageOptions } from '@/utils/TableMisc';

import { generateExactasEventOddsColumns } from './columns';

import { ExactaOddRow } from '@/types/odds/getOdds';
import { UpdateOddsPayoutPayloadDTO } from '@/types/odds/updateOddsPayout';
import { AddExactasOddsDTO } from '@/types/odds/addOdds';

export type TOGGLE_OPTIONS_KEYS = 'WINNER' | 'VOIDED' | 'DRAW';
export type TOGGLE_OPTIONS_VALUES = 'winner' | 'voided' | 'draw';
export const TOGGLE_OPTIONS: Record<TOGGLE_OPTIONS_KEYS, TOGGLE_OPTIONS_VALUES> = {
  WINNER: 'winner',
  VOIDED: 'voided',
  DRAW: 'draw',
};

const ConfirmSettleButton = styled(Button)({
  fontSize: '0.875rem',
  padding: '0.485rem 0.768rem',
  fontWeight: '400',
});

const MIN_HOLD_PERCENTAGE = 100;
const MAX_HOLD_PERCENTAGE = 200;

const ExactasHeatOddsDefaultView = (props: any) => {
  const {
    selectedRoundId,
    selectedHeat,
    changeExactasHeat,
    handlePublishOdds,
    exportHandler,
    setSelectedToBeResetHeatId,
    setCurrentHeatSelection,
    handleToggleEditConfirmDialog,
    handleToggleResetDialog,
    sport,
    eventId,
    playerDropdownOptions,
  } = props;
  const [listOfToggles, setListOfToggles] = useState<any>([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  // const [showOddsUpdateDialog, setShowOddsUpdateDialog] = useState(false);
  const [isConfirmVoidDialogOpen, setIsConfirmVoidDialogOpen] = useState(false);
  const [tempVoidedRowId, setTempVoidedRowId] = useState('');
  const [oddType] = useRecoilState(oddsType);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  // const handleToggleSaveDialog = () => setShowOddsUpdateDialog(!showOddsUpdateDialog);
  const handleToggleConfirmVoidDialog = () => setIsConfirmVoidDialogOpen(!isConfirmVoidDialogOpen);
  const handleResetTempVoidRowId = () => setTempVoidedRowId('');
  const handleToggleConfirmDialog = () => setIsConfirmDialogOpen(!isConfirmDialogOpen);

  const [showAddMatchUpPopup, setAddMatchUpPopUp] = useState(false);

  const { mutateAsync: updateExactasEventOddsPayout } = useUpdateExactasEventOddsPayout();
  const { mutateAsync: addExactasEventOdds } = useAddExactasEventOdds();

  const refreshExactasHeatOdds = () => {
    invalidateExactasHeatOdds(
      queryClient,
      sport,
      eventId,
      EXACTAS_TYPE[2],
      undefined,
      // HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)
      //   ? filterObj({ page, query, ...sort })
      //   : undefined,
    );

    invalidateExactasHeatOdds(
      queryClient,
      sport,
      eventId,
      EXACTAS_TYPE[3],
      undefined,
      // HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)
      //   ? filterObj({ page, query, ...sort })
      //   : undefined,
    );
  };

  const handleExactasEventConfirmVoid = async (
    payload: UpdateOddsPayoutPayloadDTO,
    message = 'Voided Successfully!',
  ) => {
    const response = await updateExactasEventOddsPayout(
      { sport, eventId, payload },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          refreshExactasHeatOdds();
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  const handleExactasEventConfirmAndSettle = async (
    payload: UpdateOddsPayoutPayloadDTO,
    message = 'Payouts updated Successfully!',
  ) => {
    const response = await updateExactasEventOddsPayout(
      { sport, eventId, payload },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          refreshExactasHeatOdds();
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  const handleConfirmVoid = async () => {
    handleToggleConfirmVoidDialog();
    const payload = {
      items: [{ id: tempVoidedRowId, voided: true }],
    };
    const responseStatus = await handleExactasEventConfirmVoid(payload);

    if (responseStatus === httpStatusCodes.OK) {
      handleResetTempVoidRowId();
      refreshExactasHeatOdds();
    }
  };

  const handleConfirmAndSettle = async () => {
    handleToggleConfirmDialog(); // Close confirm dialog/modal.
    const responseStatus = await handleExactasEventConfirmAndSettle({ items: listOfToggles });
    if (responseStatus === httpStatusCodes.OK) {
      setListOfToggles([]);
      refreshExactasHeatOdds();
    }
  };

  const holdPercentagesOptions = Array(MAX_HOLD_PERCENTAGE - MIN_HOLD_PERCENTAGE + 1)
    .fill(0)
    .map((_, idx) => MIN_HOLD_PERCENTAGE + idx);

  const handleToggleChange = ({
    checked,
    type,
    rowMatchUpId,
    winnerId,
  }: {
    checked: boolean;
    type: TOGGLE_OPTIONS_VALUES;
    rowMatchUpId: string;
    winnerId?: string;
  }) => {
    findAndUpdateTableEntry({
      type,
      rowMatchUpId,
      checked,
      winnerId,
      hasBeenEdited: type === TOGGLE_OPTIONS.VOIDED ? false : true,
    });

    if (type !== TOGGLE_OPTIONS.VOIDED) {
      const updatedListOfToggles = [...listOfToggles];
      const index = updatedListOfToggles.findIndex((row) => row.id === rowMatchUpId);
      if (checked) {
        const key = type === TOGGLE_OPTIONS.WINNER ? 'eventParticipantWinnerId' : type;
        const value = type === TOGGLE_OPTIONS.WINNER ? winnerId : checked;
        if (index !== -1) {
          updatedListOfToggles[index][key] = value;
        } else {
          updatedListOfToggles.push({
            id: rowMatchUpId,
            [key]: value,
          });
        }
      } else {
        if (index !== -1) updatedListOfToggles.splice(index, 1);
      }
      setListOfToggles(updatedListOfToggles);
    }
  };

  const findAndUpdateTableEntry = ({
    type,
    rowMatchUpId = '',
    checked = false,
    hasBeenEdited = true,
  }: // winnerId = null,
  {
    type: TOGGLE_OPTIONS_VALUES;
    rowMatchUpId: string;
    checked: boolean;
    winnerId?: string | null;
    hasBeenEdited?: boolean;
  }) => {
    const updatedTableData = [...(selectedHeat?.athletes || [])]; // Find index of table entry.
    const tableEntryIndex = updatedTableData.findIndex(
      (tableEntry: any) => tableEntry.id === rowMatchUpId,
    );
    // Update table entry to toggle value
    // if (tableEntryIndex !== -1 && type === TOGGLE_OPTIONS.WINNER) {
    //   updatedTableData[tableEntryIndex].newWinnerId = checked ? winnerId : '';
    // } else

    if (tableEntryIndex !== -1 && type === TOGGLE_OPTIONS.VOIDED) {
      updatedTableData[tableEntryIndex].editedVoid = checked;
    } else if (tableEntryIndex !== -1 && type === TOGGLE_OPTIONS.DRAW) {
      updatedTableData[tableEntryIndex].editedDraw = checked;
    }
    changeExactasHeat(selectedRoundId, selectedHeat?.id, updatedTableData, hasBeenEdited);
  };

  const rowUpdateHandler = (newData: ExactaOddRow) =>
    new Promise((resolve, reject) => {
      const modTableData = JSON.parse(JSON.stringify(selectedHeat?.athletes));
      modTableData.some((exactasEventOdd: ExactaOddRow, index: number) => {
        if (exactasEventOdd.id === newData.id) {
          modTableData[index] = newData;
          changeExactasHeat(selectedRoundId, selectedHeat?.id, modTableData);
          resolve('success');
        } else {
          reject('failed');
        }
      });
    });

  const rowUpdateHandlerQs = (newData: ExactaOddRow) => {
    const modTableData = JSON.parse(JSON.stringify(selectedHeat?.athletes));
    modTableData.some((exactasEventOdd: ExactaOddRow, index: number) => {
      if (exactasEventOdd.id === newData.id) {
        modTableData[index] = newData;
        changeExactasHeat(selectedRoundId, selectedHeat?.id, modTableData);
      }
    });
  };

  const addExactasEventOddsData = async (
    payload: AddExactasOddsDTO,
    message = 'Added Event Matchup Successfully',
  ) => {
    const response = await addExactasEventOdds(
      {
        sport,
        eventId,
        exactasType: payload.participants.length === 2 ? EXACTAS_TYPE[2] : EXACTAS_TYPE[3],
        payload,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          refreshExactasHeatOdds();
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  const columns: Column<ExactaOddRow>[] = generateExactasEventOddsColumns({
    rowUpdateHandler,
    rowUpdateHandlerQs,
    holdPercentagesOptions,
    handleToggleChange,
    oddType,
    setTempVoidedRowId,
    handleToggleConfirmVoidDialog,
    sport,
    isHeatWinnerMarketVoided: selectedHeat?.isHeatWinnerMarketVoided,
  });

  return (
    <>
      <Grid
        container
        alignItems={'center'}
        item
        xs={12}
        sx={{
          mt: 4,
          mb: 2,
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { xs: 'flex-start', sm: 'space-between' },
          gap: { xs: '1rem', sm: 0 },
        }}
      >
        <Grid container alignItems={'center'} item xs={12} sm={6}>
          <ExportBtn handleOnClick={() => exportHandler({ heat: selectedHeat })} />
          <PublishOdds
            clientUpdatedAtDate={selectedHeat?.clientUpdatedAtDate || ''}
            traderUpdatedAtDate={selectedHeat?.traderUpdatedAtDate || ''}
            traderUpdatedAtDates={selectedHeat?.traderUpdatedAtDate || null}
            isVoided={selectedHeat?.isHeatWinnerMarketVoided}
            handlePublishOdds={() => {
              handlePublishOdds(ODDS_PROJECTION_TYPE.exactasHeatProjections, selectedHeat?.id);
            }}
          />
        </Grid>
        <Grid
          container
          alignItems={'center'}
          item
          xs={12}
          sm={6}
          sx={{ justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}
        >
          <ResetOddsBtn
            disabled={!selectedHeat?.hasBeenEdited || selectedHeat?.isHeatWinnerMarketVoided}
            resetOddsHandler={() => {
              setSelectedToBeResetHeatId(selectedHeat?.id);
              handleToggleResetDialog();
            }}
          />
          <SaveOddsBtn
            disabled={!selectedHeat?.hasBeenEdited || selectedHeat?.isHeatWinnerMarketVoided}
            saveOddsHandler={() => {
              setCurrentHeatSelection(selectedHeat);
              handleToggleEditConfirmDialog();
            }}
          />
          <ConfirmSettleButton
            id="confirmAndSettleBtn"
            disableElevation
            variant="contained"
            onClick={handleToggleConfirmDialog}
            disabled={
              exactasEventOddsFreshWinnerDrawCount(selectedHeat?.athletes) === 0 ||
              selectedHeat?.isHeatWinnerMarketVoided
                ? true
                : false
            }
            sx={{ ml: 2 }}
          >
            Confirm and settle
          </ConfirmSettleButton>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: '0.5rem' }}>
        <MaterialTable
          data={selectedHeat?.athletes || []}
          icons={tableIcons}
          columns={columns}
          editable={
            selectedHeat?.isHeatWinnerMarketVoided ||
            selectedHeat.heatStatus === HeatStatus.COMPLETED
              ? undefined
              : {
                  isEditHidden: () => !!selectedHeat?.isHeatWinnerMarketVoided,
                  isDeleteHidden: () => true,
                  onRowUpdate: (newData: any) => rowUpdateHandler(newData),
                  onRowDelete: () =>
                    new Promise(() => {
                      //
                    }),
                }
          }
          options={{
            actionsColumnIndex: -1,
            toolbar: false,
            thirdSortClick: false,
            ...defaultTableOptions,
            pageSize: createTablePageOptions(selectedHeat?.athletes?.length || 0).pageSize,
            pageSizeOptions: createTablePageOptions(selectedHeat?.athletes?.length || 0)
              .pageSizeOptions,
            idSynonym: 'heatOddId',
          }}
          components={{
            Pagination: () => (
              <td>
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: '0.5rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box>
                    <Button
                      id="addNewExactaBtn"
                      startIcon={<AddIcon />}
                      disabled={
                        selectedHeat?.isHeatWinnerMarketVoided ||
                        selectedHeat.heatStatus === HeatStatus.COMPLETED
                      }
                      onClick={
                        selectedHeat?.isHeatWinnerMarketVoided ||
                        selectedHeat.heatStatus === HeatStatus.COMPLETED
                          ? undefined
                          : () => {
                              setAddMatchUpPopUp(true);
                            }
                      }
                      color="primary"
                    >
                      Add a new exacta
                    </Button>
                  </Box>
                </Grid>
              </td>
            ),
          }}
        />
      </Box>
      {showAddMatchUpPopup && (
        <AddEventExactasEventDialog
          open={showAddMatchUpPopup}
          handleClose={() => setAddMatchUpPopUp(false)}
          playerDropdownOptions={playerDropdownOptions}
          addExactasEventOddsData={addExactasEventOddsData}
          roundHeatId={selectedHeat?.id}
        />
      )}
      {isConfirmDialogOpen && (
        <ConfirmDialog
          open={isConfirmDialogOpen}
          handleClose={handleToggleConfirmDialog}
          handleConfirm={handleConfirmAndSettle}
          title={'ARE YOU SURE YOU WANT TO SETTLE ALL WINNERS/ DRAWS EXACTAS HEAT ODDS?'}
          body={
            'This will establish the winners/ draws and will require voiding if there are any errors. Make sure these are all correct.'
          }
        />
      )}
      {isConfirmVoidDialogOpen && (
        <ConfirmDialog
          open={isConfirmVoidDialogOpen}
          handleClose={() => {
            handleToggleConfirmVoidDialog();
            handleToggleChange({
              checked: false,
              type: TOGGLE_OPTIONS.VOIDED,
              rowMatchUpId: tempVoidedRowId,
            });
            handleResetTempVoidRowId();
          }}
          handleConfirm={handleConfirmVoid}
          title={'ARE YOU SURE YOU WANT TO VOID EXACTAS HEAT ODDS?'}
          body={'This will void the exactas heat odds.'}
        />
      )}
    </>
  );
};

export default ExactasHeatOddsDefaultView;
