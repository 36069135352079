import {
  EventOddPosition,
  ModifiedEventOddPosition,
  EventPositionalOdd,
  ShowAndPodiumOdd,
  ModifiedShowAndPodiumOdds,
  OddOfShowAndPodium,
} from '@/types/helpers/odds';
import round from 'lodash.round';
import * as oddslib from 'oddslib';

import { fractionPattern } from '@/constants/misc';
import {
  MAX_PROBABILITY,
  MIN_PROBABILITY,
  MIN_DECIMAL_ODDS,
  MAX_DECIMAL_ODDS,
  MAX_NEGATIVE_AMERICAN_ODDS,
  MIN_AMERICAN_ODDS,
  MIN_FRACTION_ODDS,
  MAX_FRACTION_ODDS,
} from '@/constants/oddRanges';
import { ExactaOddRow } from '@/types/odds/getOdds';

enum DisplayOddMetric {
  DECIMAL = '0',
  FRACTIONAL = '1',
  AMERICAN = '2',
}

// Get decimal odds from decimal odds.
export const getDecimalOdds = (decimalOdds: number): number => round(+decimalOdds, 2);

// Get fractional odds from decimal odds.
export const getFractionalOdds = (decimalOdds: number) => {
  // `${(decimalOdds - 1).toFixed(2)}/ 1`

  // Truncate decimal precision to 2 else oddslib throws an error
  // return round(+decimalOdds, 2) > 1
  //   ? oddslib.from('decimal', round(+decimalOdds, 2)).to('fractional')
  //   : 0;
  const formattedDecimalOdds = round(+decimalOdds, 2);
  return formattedDecimalOdds >= MIN_DECIMAL_ODDS && formattedDecimalOdds <= MAX_DECIMAL_ODDS
    ? `${round(formattedDecimalOdds - 1, 2)}/1`
    : 0;
};

// Get american odds from decimal odds.
export const getAmericanOdds = (decimalOdds: number): number => {
  // // in order to calculate american odds
  // // first the system must determine who has a higher probability of winning since higher than 50% probability
  // // gets the negative odd while lower than 50% gets the positive odd

  return round(+decimalOdds, 2) >= MIN_DECIMAL_ODDS
    ? round(oddslib.from('decimal', round(+decimalOdds, 2)).to('moneyline'))
    : 0;
};

// Get decimal odds from fractional or american odds.
export const getDecimalOddsFromOtherTypes = (oddsType: string, odds: number | string): number => {
  if (+odds === 0) return 0;
  if (oddsType === DisplayOddMetric.FRACTIONAL) {
    const newValue = odds.toString();
    const decimal = decimalFromFraction(newValue);

    if (
      !newValue.match(fractionPattern) ||
      !(decimal >= MIN_FRACTION_ODDS && decimal <= MAX_FRACTION_ODDS)
    ) {
      return 0;
    } else {
      return decimal + 1;
    }
    // return round(oddslib.from('fractional', odds).to('decimal') || 0, 2);
  } else if (
    oddsType === DisplayOddMetric.AMERICAN &&
    (+odds <= MAX_NEGATIVE_AMERICAN_ODDS || +odds >= MIN_AMERICAN_ODDS)
  ) {
    return round(oddslib.from('moneyline', +odds).to('decimal') || 0, 2);
  } else {
    return 0;
  }
};

export const getProbabilityFromDecimalOdds = (odds: number) =>
  odds < MIN_DECIMAL_ODDS
    ? 0
    : round(oddslib.from('decimal', odds).to('impliedProbability') * 100, 2);

export const getDecimalOddsFromProbability = (probability = 0) =>
  round(+probability, 2) > MIN_PROBABILITY && round(+probability, 2) <= MAX_PROBABILITY
    ? round(oddslib.from('impliedProbability', round(+probability, 2) / 100).to('decimal'), 2)
    : round(+probability, 2) > MAX_PROBABILITY
    ? round(oddslib.from('impliedProbability', round(MAX_PROBABILITY, 2) / 100).to('decimal'))
    : 0;

export const getAmericanOddsFromProbability = (probability = 0) =>
  round(+probability, 2) > MIN_PROBABILITY && round(+probability, 2) <= MAX_PROBABILITY
    ? round(oddslib.from('impliedProbability', round(+probability, 2) / 100).to('moneyline'))
    : round(+probability, 2) > MAX_PROBABILITY
    ? round(oddslib.from('impliedProbability', round(MAX_PROBABILITY, 2) / 100).to('moneyline'))
    : 0;

export const getProbabilityFromAmericanOdds = (odds = 0) =>
  +odds <= MAX_NEGATIVE_AMERICAN_ODDS || +odds >= MIN_AMERICAN_ODDS
    ? round(oddslib.from('moneyline', +odds).to('impliedProbability') * 100, 2)
    : 0;

export const getNewProbability = (
  probability = 0,
  holdPercentage = 100,
  defaultHoldPercentage = 100,
) => {
  if (holdPercentage === defaultHoldPercentage) return probability;
  const calculatedHoldPercentage = holdPercentage / defaultHoldPercentage;
  const newProbability = round(+probability, 2) * calculatedHoldPercentage;
  if (probability > MAX_PROBABILITY || newProbability > MAX_PROBABILITY) return MAX_PROBABILITY;
  if (probability < MIN_PROBABILITY || newProbability < MIN_PROBABILITY) return MIN_PROBABILITY;
  return newProbability;
};

export const getNewProbabilityAfterEdit = ({
  probability = 0,
  newHoldPercentage = 100,
  oldHoldPercentage = 100,
  defaultHoldPercentage = 100,
}) => {
  const oldHoldPercentageChange = Number(oldHoldPercentage) / Number(defaultHoldPercentage);
  const originalVal = Number(probability) / oldHoldPercentageChange;

  const newHoldPercentageChange = Number(newHoldPercentage) / Number(defaultHoldPercentage);
  const newProbability = round(+originalVal, 2) * newHoldPercentageChange;

  if (probability > MAX_PROBABILITY || newProbability > MAX_PROBABILITY) return MAX_PROBABILITY;
  if (probability < MIN_PROBABILITY || newProbability < MIN_PROBABILITY) return MIN_PROBABILITY;
  return newProbability;
};

export const decimalFromFraction = (odds: string) => {
  const [numerator, denominator] = odds.split('/');
  const computedDecimalOdd = round(+numerator / +denominator, 2);
  return computedDecimalOdd;
};

export const getTotalProbability = (athleteList: any[]) => {
  if (!athleteList || (athleteList && athleteList?.length === 0)) return 0;

  const totalProbability = round(
    athleteList.reduce((previousValue: any, currentValue: any) => {
      return previousValue + round(+currentValue.probability, 2);
    }, 0),
  );
  return totalProbability === 0 ? 100 : totalProbability;
};

export const getEditableAthletesCount = (athleteList: any[]) =>
  athleteList.reduce((previousValue: any, currentValue: any) => {
    if (!currentValue.hasModifiedProbability) {
      return previousValue + 1;
    } else {
      return previousValue;
    }
  }, 0);

export const eventOddsPositionModifier = ({
  eventOddsPosition,
  newHoldPercentage,
  oldHoldPercentage = 100,
  defaultHoldPercentage = 100,
}: {
  eventOddsPosition: EventOddPosition[];
  newHoldPercentage: number;
  oldHoldPercentage?: number;
  defaultHoldPercentage?: number;
}) => {
  // if there are no odds then return an empty array
  if (!eventOddsPosition || (eventOddsPosition && eventOddsPosition.length === 0)) return [];

  const modifiedEventOdds: ModifiedEventOddPosition[] = [];

  // modify event odds position
  eventOddsPosition?.map((eventOddPos) => {
    // Get new probability
    const newProbability = getNewProbabilityAfterEdit({
      probability: Number(eventOddPos?.probability),
      newHoldPercentage,
      oldHoldPercentage,
      defaultHoldPercentage,
    });
    // get new odds
    const newOdds = getDecimalOddsFromProbability(+newProbability);

    const eventOdd: ModifiedEventOddPosition = {
      ...eventOddPos,
      odds: newOdds,
      decimalOdds: getDecimalOdds(newOdds).toFixed(2),
      fractionalOdds: getFractionalOdds(newOdds).toString(),
      americanOdds:
        getAmericanOddsFromProbability(newProbability) > 0
          ? `+${getAmericanOddsFromProbability(newProbability)}`
          : `${getAmericanOddsFromProbability(newProbability)}`,
      probability: newProbability > 0 ? newProbability.toFixed(2) : 0,
      // hasModifiedProbability: false,
    };

    modifiedEventOdds.push(eventOdd);
    return eventOddPos;
  });

  const sortedOdds = modifiedEventOdds.sort(
    (a, b) => Number(b.probability) - Number(a.probability),
  );
  return sortedOdds;
};

export const editRowFormatter = (modifiedRow: any, odds: any[] | undefined = []) => {
  const oddRows = odds ? [...odds] : [];

  const oldRow = oddRows.find((row) => row.id === modifiedRow.id);

  const probabilityChangeDifference =
    Number(oldRow?.probability) - Number(modifiedRow?.probability);

  // finding the modified row and replacing it
  const athleteOdds = odds.map((obj) => [modifiedRow].find((o) => o.id === obj.id) || obj);

  const unlockedAthletes = getEditableAthletesCount(athleteOdds);

  // Difference that has to be added/subtracted from every unlocked athlete
  const unlockedAthletesChangeDelta = round(probabilityChangeDifference / unlockedAthletes, 2);

  const updatedAthleteOdds = athleteOdds.map((athlete) => {
    if (athlete.hasModifiedProbability) {
      return athlete;
    } else {
      const newProbability =
        round(+athlete.probability, 2) > 0
          ? round(+athlete.probability, 2) + unlockedAthletesChangeDelta
          : 0;
      const normalisedProbability = newProbability < 0 ? 0 : newProbability;
      // const normalisedProbability = newProbability;
      const newOdds = getDecimalOddsFromProbability(normalisedProbability);
      const updatedUnlockedOdds = {
        ...athlete,
        odds: newOdds,
        decimalOdds: getDecimalOdds(newOdds).toString(),
        fractionalOdds: getFractionalOdds(newOdds).toString(),
        americanOdds:
          getAmericanOddsFromProbability(normalisedProbability) > 0
            ? `+${getAmericanOddsFromProbability(normalisedProbability)}`
            : `${getAmericanOddsFromProbability(normalisedProbability)}`,
        probability: round(normalisedProbability, 2),
      };
      return updatedUnlockedOdds;
    }
  });

  return updatedAthleteOdds;
};

/**
 * Return the total percentage of all rows that are either modified or not modified
 */
export const findTotalPercentage = (
  eventOdds: ModifiedEventOddPosition[] | ModifiedShowAndPodiumOdds[] | ExactaOddRow[] = [],
  modifiedRowId: string,
  {
    modifiedRowsOnly = false,
    excludeModifiedRows = false,
  }: {
    modifiedRowsOnly?: boolean;
    excludeModifiedRows?: boolean;
  },
) => {
  if (!eventOdds || eventOdds?.length === 0) return 0;

  const totalPercentage = eventOdds
    .filter((data) => {
      let result = data.id !== modifiedRowId;

      if (modifiedRowsOnly) result = result && data.hasModifiedProbability;
      if (excludeModifiedRows) result = result && !data.hasModifiedProbability;

      return result;
    })
    .reduce((total, item) => total + round(+item.probability, 2), 0);

  return totalPercentage;
};

export const positionalOddsPayloadFormatter = (
  eventOddsPositions: ModifiedEventOddPosition[] = [],
) => {
  if (!eventOddsPositions || (eventOddsPositions && eventOddsPositions?.length === 0)) return [];

  const payload: EventPositionalOdd[] = [];

  eventOddsPositions.map((data) => {
    const eventOddsPosition = {
      id: data?.id,
      probability: round(+data?.probability, 2),
      hasModifiedProbability: data?.hasModifiedProbability,
      odds: round(+data?.odds, 2),
    };
    payload.push(eventOddsPosition);
    return data;
  });

  return payload;
};

export const eventOddsDataLoader = (eventOddsPosition: EventOddPosition[]) => {
  // if there are no odds then return an empty array
  if (!eventOddsPosition || (eventOddsPosition && eventOddsPosition.length === 0)) return [];

  const modifiedEventOdds: ModifiedEventOddPosition[] = [];

  // modify event odds position
  eventOddsPosition?.map((eventOddPos) => {
    const probability = +eventOddPos?.probability;
    const odds = getDecimalOddsFromProbability(probability);
    const eventOdd: ModifiedEventOddPosition = {
      ...eventOddPos,
      odds: odds,
      decimalOdds: getDecimalOdds(odds).toString(),
      fractionalOdds: getFractionalOdds(odds).toString(),
      americanOdds:
        getAmericanOddsFromProbability(probability) > 0
          ? `+${getAmericanOddsFromProbability(probability)}`
          : `${getAmericanOddsFromProbability(probability)}`,
    };

    modifiedEventOdds.push(eventOdd);
    return eventOddPos;
  });

  const sortedOdds = modifiedEventOdds.sort(
    (a, b) => Number(b.probability) - Number(a.probability),
  );
  return sortedOdds;
};

export const showAndPodiumModifier = ({
  eventOddsPosition,
  newHoldPercentage = 100,
  oldHoldPercentage = 100,
  defaultHoldPercentage = 100,
}: {
  eventOddsPosition: ShowAndPodiumOdd[];
  newHoldPercentage: number;
  oldHoldPercentage?: number;
  defaultHoldPercentage?: number;
}) => {
  // if there are no odds then return an empty array
  if (!eventOddsPosition || (eventOddsPosition && eventOddsPosition.length === 0)) return [];

  const modifiedEventOdds: ModifiedShowAndPodiumOdds[] = [];

  // modify event odds position
  eventOddsPosition?.map((eventOddPos) => {
    // Get new probability
    const newProbability = getNewProbabilityAfterEdit({
      probability: Number(eventOddPos?.probability),
      newHoldPercentage,
      oldHoldPercentage,
      defaultHoldPercentage,
    });
    // get new odds
    const newOdds = getDecimalOddsFromProbability(+newProbability);
    const eventOdd: ModifiedShowAndPodiumOdds = {
      ...eventOddPos,
      odds: newOdds,
      decimalOdds: getDecimalOdds(newOdds).toFixed(2),
      fractionalOdds: getFractionalOdds(newOdds).toString(),
      americanOdds:
        getAmericanOddsFromProbability(newProbability) > 0
          ? `+${getAmericanOddsFromProbability(newProbability)}`
          : `${getAmericanOddsFromProbability(newProbability)}`,
      probability: newProbability.toFixed(2),
      // hasModifiedProbability: false,
    };

    modifiedEventOdds.push(eventOdd);
    return eventOddPos;
  });

  const sortedOdds = modifiedEventOdds.sort(
    (a, b) => Number(b.probability) - Number(a.probability),
  );
  return sortedOdds;
};

export const showsAndPodiumsOddsDataLoader = (eventOddsPosition: ShowAndPodiumOdd[]) => {
  // if there are no odds then return an empty array
  if (!eventOddsPosition || (eventOddsPosition && eventOddsPosition.length === 0)) return [];

  const modifiedEventOdds: ModifiedShowAndPodiumOdds[] = [];

  // modify event odds position
  eventOddsPosition?.map((eventOddPos) => {
    const probability = +eventOddPos?.probability;
    const odds = getDecimalOddsFromProbability(probability);
    const eventOdd: ModifiedShowAndPodiumOdds = {
      ...eventOddPos,
      odds: odds,
      decimalOdds: getDecimalOdds(odds).toString(),
      fractionalOdds: getFractionalOdds(odds).toString(),
      americanOdds:
        getAmericanOddsFromProbability(probability) > 0
          ? `+${getAmericanOddsFromProbability(probability)}`
          : `${getAmericanOddsFromProbability(probability)}`,
    };

    modifiedEventOdds.push(eventOdd);
    return eventOddPos;
  });

  const sortedOdds = modifiedEventOdds.sort(
    (a, b) => Number(b.probability) - Number(a.probability),
  );
  return sortedOdds;
};

export const showsAndPodiumsOddsPayloadFormatter = (
  eventOddsPositions: ModifiedShowAndPodiumOdds[] = [],
) => {
  if (!eventOddsPositions || (eventOddsPositions && eventOddsPositions?.length === 0)) return [];

  const payload: OddOfShowAndPodium[] = [];

  eventOddsPositions.map((data) => {
    const eventOddsPosition = {
      id: data?.id,
      probability: round(+data?.probability, 2),
      hasModifiedProbability: data?.hasModifiedProbability,
      odds: round(+data?.odds, 2),
    };
    payload.push(eventOddsPosition);
    return data;
  });

  return payload;
};

export const editShowAndPodiumRowFormatter = (
  modifiedRow: ModifiedShowAndPodiumOdds,
  odds: ModifiedShowAndPodiumOdds[] | undefined = [],
) => {
  const oddRows = odds ? [...odds] : [];

  const oldRow = oddRows.find((row) => row.id === modifiedRow.id);

  const probabilityChangeDifference =
    Number(oldRow?.probability) - Number(modifiedRow?.probability);

  // finding the modified row and replacing it
  const athleteOdds = odds.map((obj) => [modifiedRow].find((o) => o.id === obj.id) || obj);

  const unlockedAthletes = getEditableAthletesCount(athleteOdds);

  // Difference that has to be added/subtracted from every unlocked athlete
  const unlockedAthletesChangeDelta = round(probabilityChangeDifference / unlockedAthletes, 2);

  const updatedAthleteOdds = athleteOdds.map((athlete) => {
    if (athlete.hasModifiedProbability) {
      return athlete;
    } else {
      const newProbability =
        round(+athlete.probability, 2) > 0
          ? round(+athlete.probability, 2) + unlockedAthletesChangeDelta
          : 0;
      const normalisedProbability = newProbability < 0 ? 0 : newProbability;
      // const normalisedProbability = newProbability;
      const newOdds = getDecimalOddsFromProbability(normalisedProbability);
      const updatedUnlockedOdds = {
        ...athlete,
        odds: newOdds,
        decimalOdds: getDecimalOdds(newOdds).toString(),
        fractionalOdds: getFractionalOdds(newOdds).toString(),
        americanOdds:
          getAmericanOddsFromProbability(normalisedProbability) > 0
            ? `+${getAmericanOddsFromProbability(normalisedProbability)}`
            : `${getAmericanOddsFromProbability(normalisedProbability)}`,
        probability: round(normalisedProbability, 2),
      };
      return updatedUnlockedOdds;
    }
  });

  return updatedAthleteOdds;
};
