import React from 'react';
import { Typography, Stack, styled, SxProps, Theme } from '@mui/material';
import { AntSwitch } from '@/components/Switch';
import useToggleOddMarkets from '@/hooks/odds/toggleOddMarkets/useToggleOddMarkets';
import SPORT_TYPES from '@/constants/sportTypes';

const ToggleTextContainer = styled(Typography)({
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
});

const toggleText = {
  on: (
    <ToggleTextContainer>
      Markets are <strong>OPEN</strong>. Toggle to close.
    </ToggleTextContainer>
  ),
  off: (
    <ToggleTextContainer>
      Markets are <strong>CLOSED</strong>. Toggle to open.
    </ToggleTextContainer>
  ),
};

interface OddMarketToggleProps {
  sport: string;
  marketType: string;
  eventId: string;
  heatId?: string;
  playerHeadToHeadId?: string;
  isMarketOpen: boolean;
  refetchOdds: () => void;
  sx?: SxProps<Theme>;
}

const OddMarketToggle = ({
  sport,
  marketType,
  eventId,
  heatId,
  playerHeadToHeadId,
  refetchOdds,
  isMarketOpen = false,
  sx = {},
}: OddMarketToggleProps) => {
  const [isToggling, setIsToggling] = React.useState(false);
  const [isOddMarketEnabled, setIsOddMarketEnabled] = React.useState(false);
  const { mutate: toggleOddMarkets } = useToggleOddMarkets();

  React.useEffect(() => {
    setIsOddMarketEnabled(isMarketOpen);
  }, [marketType, heatId, isMarketOpen]);

  const toggleNotificationHandler = () => {
    setIsToggling(true);
    toggleOddMarkets(
      { sport, marketType, eventId, heatId, playerHeadToHeadId, isMarketOpen: !isOddMarketEnabled },
      {
        onSuccess: () => {
          refetchOdds();
          setIsToggling(false);
          setIsOddMarketEnabled(!isOddMarketEnabled);
        },
        onError: () => {
          setIsToggling(false);
        },
      },
    );
  };

  if (
    ![
      SPORT_TYPES.WSL,
      SPORT_TYPES.SLS,
      SPORT_TYPES.NRX,
      SPORT_TYPES.BKFC,
      SPORT_TYPES.MOTOCRS,
      SPORT_TYPES.FDRIFT,
      SPORT_TYPES.MOTOAMERICA,
      SPORT_TYPES.BYB,
      SPORT_TYPES.POWERSLAP,
      SPORT_TYPES.USAC,
      SPORT_TYPES.NHRA,
      SPORT_TYPES.SPRMTCRS,
      SPORT_TYPES.F1,
    ].includes(sport)
  )
    return null;

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{
        mt: 2,
        mb: 2,
        ...sx,
      }}
    >
      <AntSwitch
        id="marketSuspensionToggle"
        checked={isOddMarketEnabled}
        disabled={isToggling}
        onChange={toggleNotificationHandler}
      />
      {isOddMarketEnabled ? toggleText.on : toggleText.off}
    </Stack>
  );
};

export default OddMarketToggle;
