import React, { useEffect, useMemo } from 'react';
import { useNavigate, useLocation, matchPath } from 'react-router-dom';

import routes from '@/constants/routes';
import { getAuthToken } from '@/constants/localStorageKeys';
import {
  disconnectSocket,
  initializeTabVisibilityHandler,
  initiateSocketConnection,
} from '@/utils/socketService';

const namespace = '';

export default function RedirectWrapper({ children }: { children: React.ReactElement }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const firebaseToken = getAuthToken();

  const cannotAccess = useMemo(() => {
    return !![].find((loc: string) => matchPath({ path: loc }, pathname));
  }, [pathname]);

  useEffect(() => {
    if (firebaseToken) {
      initiateSocketConnection(namespace, firebaseToken);

      if (cannotAccess) {
        navigate(routes.events.home);
      }
    }
    // else {
    //   navigate(routes.public.login);
    // }
    return () => {
      disconnectSocket(namespace);
    };
  }, [pathname, firebaseToken]);

  useEffect(() => {
    initializeTabVisibilityHandler();
  }, []);

  return children;
}
