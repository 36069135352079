import Editor from '@/components/Editor';
import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import usePublishNotes from '@/hooks/odds/notes/usePublishNotes';
import { enqueueSnackbar } from 'notistack';
import SPORT_TYPES from '@/constants/sportTypes';

const OddMarketNotes = ({
  eventId,
  marketType,
  tourYearId,
  futureType,
  sportType,
  note = '',
  refetchOdds,
}: {
  eventId?: string;
  marketType?: string;
  tourYearId?: string;
  futureType?: string;
  sportType: string;
  note: string;
  refetchOdds: () => void;
}) => {
  const [value, setValue] = React.useState(note);
  const [initialValue, setInitialValue] = React.useState(note);
  const [isChanged, setIsChanged] = React.useState(false);
  const { mutateAsync: publishNotes } = usePublishNotes();

  React.useEffect(() => {
    setInitialValue(note);
    setValue(note);
  }, [note]);

  React.useEffect(() => {
    setIsChanged(value !== initialValue);
  }, [value, initialValue]);

  const handleEditorChange = (newValue: string) => {
    setValue(newValue);
  };

  const handleSaveNote = () => {
    publishNotes(
      { eventId, marketType, sport: sportType, tourYearId, futureType, payload: { note: value } },
      {
        onSettled: (_, err) => {
          if (!err) {
            enqueueSnackbar('Added Note successfully!');
            refetchOdds();
            setInitialValue(value); // Update the initial value after successful save
          }
        },
      },
    );
  };

  if (
    ![
      SPORT_TYPES.WSL,
      SPORT_TYPES.SLS,
      SPORT_TYPES.NRX,
      SPORT_TYPES.BKFC,
      SPORT_TYPES.MOTOCRS,
      SPORT_TYPES.FDRIFT,
      SPORT_TYPES.MOTOAMERICA,
      SPORT_TYPES.BYB,
      SPORT_TYPES.POWERSLAP,
      SPORT_TYPES.USAC,
      SPORT_TYPES.NHRA,
      SPORT_TYPES.SPRMTCRS,
      SPORT_TYPES.F1,
    ].includes(sportType)
  )
    return null;

  return (
    <Box mb={4} mt={1}>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: '700', color: '#000000', mb: 0.5 }}>
        Notes
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: '400', color: '#000000', mb: 1 }}>
        Any notes here will be made available to the clients
      </Typography>
      <Box sx={{ mb: 2, width: '100%' }}>
        <Editor
          theme="snow"
          value={value}
          onChange={handleEditorChange}
          sx={{ width: { xs: '100%', md: '50%' } }}
        />
      </Box>

      <Button
        variant="contained"
        id="saveNotes"
        disableElevation
        disabled={!isChanged}
        onClick={handleSaveNote}
      >
        Save
      </Button>
    </Box>
  );
};

export default OddMarketNotes;
