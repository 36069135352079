export const DECIMAL_ODDS_VALIDATION_MSG = 'Decimal odds must be between 1.01 and 200';
export const FRACTION_ODDS_VALIDATION_MSG = 'Please enter a valid fraction like 20/1, 9.2/1 etc.';
export const AMERICAN_ODDS_VALIDATION_MSG =
  'American odds must be between (-10000, -101) and (101, 100000).';

export const ODD_WINNERS_DECIMAL_ODDS_VALIDATION_MSG =
  'Decimal odds must be greater than 1 or equal to 0';
export const ODD_WINNERS_AMERICAN_ODDS_VALIDATION_MSG =
  'American odds must be greater than 100, 0 or less than -100.';

export const PLAYER_MATCH_UP_PROBABILITY_VALIDATION_MSG =
  'Probability must be between 0.5 and 99.5, inclusive.';
