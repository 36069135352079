import round from 'lodash.round';

import * as oddsHelpers from '@/helpers/odds';
import ODDS_TYPES from '@/constants/oddsTypes';
import { PlayerHeadToHeadOddRow } from '@/types/playerHeadToHeadTab';

// Helper function to format odds consistently
const formatOdds = (probability: number, odds: number) => {
  const americanOdds = oddsHelpers.getAmericanOddsFromProbability(probability);
  return {
    decimalOdds: oddsHelpers.getDecimalOdds(odds).toString(),
    fractionalOdds: oddsHelpers.getFractionalOdds(odds).toString(),
    americanOdds: americanOdds > 0 ? `+${americanOdds}` : `${americanOdds}`,
    probability: round(probability, 2),
    odds,
  };
};

export const playerMatchUpOddsModifier = ({
  playerMatchUp,
  newHoldPercentage = 100,
  oldHoldPercentage = 100,
  defaultHoldPercentage = 100,
}: {
  playerMatchUp: PlayerHeadToHeadOddRow;
  newHoldPercentage: number;
  oldHoldPercentage: number;
  defaultHoldPercentage?: number;
}) => {
  if (!playerMatchUp) return {};

  const computeNewOdds = (participant: any) => {
    if (!participant) return null;

    const newProbability = oddsHelpers.getNewProbabilityAfterEdit({
      probability: participant.probability,
      newHoldPercentage,
      oldHoldPercentage,
      defaultHoldPercentage,
    });

    const newOdds = oddsHelpers.getDecimalOddsFromProbability(+newProbability);

    return {
      ...participant,
      position: participant.position || '--',
      eventParticipantId: participant.id || '',
      athleteId: participant.athlete?.id || '',
      label: [
        participant.athlete?.firstName,
        participant.athlete?.middleName,
        participant.athlete?.lastName,
      ]
        .filter(Boolean)
        .join(' '),
      ...formatOdds(newProbability, newOdds),
    };
  };

  return {
    ...playerMatchUp,
    holdingPercentage: +newHoldPercentage,
    eventParticipant1: computeNewOdds(playerMatchUp.eventParticipant1),
    eventParticipant2: computeNewOdds(playerMatchUp.eventParticipant2),
  };
};

export const editMatchUpRowProbabilityFormatter = (
  newProbability: number,
  matchUp: any,
  {
    isPlayer1Modified = false,
    isPlayer2Modified = false,
  }: { isPlayer1Modified?: boolean; isPlayer2Modified?: boolean },
) => {
  const remainingProbability = +matchUp.holdingPercentage - +newProbability;

  // Compute new probabilities
  const newPlayer1Probability = isPlayer1Modified ? +newProbability : remainingProbability;
  const newPlayer2Probability = isPlayer2Modified ? +newProbability : remainingProbability;

  // Normalize probabilities
  const normalizeProbability = (prob: number) =>
    prob <= 0 || prob === +matchUp.holdingPercentage ? 0 : prob;
  const normalizedProbabilityPlayer1 = normalizeProbability(newPlayer1Probability);
  const normalizedProbabilityPlayer2 = normalizeProbability(newPlayer2Probability);

  // Helper function to format odds
  const formatOdds = (probability: number) => {
    const decimalOdds = oddsHelpers.getDecimalOddsFromProbability(probability);
    const americanOdds = oddsHelpers.getAmericanOddsFromProbability(probability);
    return {
      decimalOdds: decimalOdds.toString(),
      fractionalOdds: oddsHelpers.getFractionalOdds(decimalOdds).toString(),
      americanOdds: americanOdds > 0 ? `+${americanOdds}` : `${americanOdds}`,
      probability: round(probability, 2),
      odds: decimalOdds,
      hasModifiedProbability: true,
    };
  };

  return {
    ...matchUp,
    newWinnerId: matchUp.winnerParticipantId || '',
    editedVoid: matchUp.voided || false,
    eventParticipant1: {
      ...matchUp.eventParticipant1,
      ...formatOdds(normalizedProbabilityPlayer1),
    },
    eventParticipant2: {
      ...matchUp.eventParticipant2,
      ...formatOdds(normalizedProbabilityPlayer2),
    },
  };
};

export const editMatchUpRowOddsFormatter = (
  newOdd: any,
  matchUp: any,
  { isPlayer1Modified = false, isPlayer2Modified = false, oddsType = ODDS_TYPES.DECIMAL },
) => {
  // Convert odds to decimal format
  const convertedOdds =
    oddsType === ODDS_TYPES.DECIMAL
      ? newOdd
      : oddsHelpers.getDecimalOddsFromOtherTypes(oddsType, newOdd);

  // Get probability from decimal odds
  const newProbability = round(
    oddsHelpers.getProbabilityFromDecimalOdds(convertedOdds),
    oddsType === ODDS_TYPES.AMERICAN ? 1 : 2,
  );

  // Convert odds into different formats
  const convertedOddTypes = {
    decimalOdds: convertedOdds,
    fractionalOdds:
      oddsType === ODDS_TYPES.FRACTIONAL
        ? newOdd.toString()
        : oddsHelpers.getFractionalOdds(convertedOdds).toString(),
    americanOdds:
      oddsType === ODDS_TYPES.AMERICAN
        ? newOdd > 0
          ? `+${newOdd}`
          : `${newOdd}`
        : oddsHelpers.getAmericanOddsFromProbability(newProbability) > 0
        ? `+${oddsHelpers.getAmericanOddsFromProbability(newProbability)}`
        : `${oddsHelpers.getAmericanOddsFromProbability(newProbability)}`,
    odds: convertedOdds,
  };

  const calculateProbability = (isModified: boolean, probability: number) =>
    isModified ? probability : matchUp.holdingPercentage - probability;

  const normalizeProbability = (probability: number) =>
    probability < 0 ? 0 : probability === Infinity ? matchUp.holdingPercentage : probability;

  const updateParticipant = (participant: any, isModified: boolean, probability: number) => {
    const normalizedProbability = normalizeProbability(probability);
    const newOdds = oddsHelpers.getDecimalOddsFromProbability(normalizedProbability);

    return {
      ...participant,
      probability: round(normalizedProbability, 2),
      ...(isModified
        ? convertedOddTypes
        : {
            decimalOdds: oddsHelpers.getDecimalOdds(newOdds).toString(),
            fractionalOdds: oddsHelpers.getFractionalOdds(newOdds).toString(),
            americanOdds:
              oddsHelpers.getAmericanOddsFromProbability(normalizedProbability) > 0
                ? `+${oddsHelpers.getAmericanOddsFromProbability(normalizedProbability)}`
                : `${oddsHelpers.getAmericanOddsFromProbability(normalizedProbability)}`,
            probability: round(normalizedProbability, 2),
            odds: newOdds,
          }),
      hasModifiedProbability: true,
    };
  };

  return {
    ...matchUp,
    eventParticipant1: updateParticipant(
      matchUp.eventParticipant1,
      isPlayer1Modified,
      calculateProbability(isPlayer1Modified, newProbability),
    ),
    eventParticipant2: updateParticipant(
      matchUp.eventParticipant2,
      isPlayer2Modified,
      calculateProbability(isPlayer2Modified, newProbability),
    ),
  };
};
