import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { marketOddsPayload } from '../marketHelper';

import {
  // Styled Components
  AccordionSummary,
} from '../components';
import MarketHeaderContent from './MarketHeaderContent';
import { HEADER_BACKGROUND_COLOR } from '../constants';

const AccordionSummaryWithMarketHeader = ({
  value,
  holdPercentage,
  holdPercentagesOptions,
  oddId,
  setFieldValue,
  toggleIsMarketActive,
  toggleMarketLock,
  disabledMarkets,
  bet,
}: {
  value: any;
  holdPercentage: number;
  holdPercentagesOptions: number[];
  oddId: number;
  setFieldValue: any;
  toggleIsMarketActive: Function;
  toggleMarketLock: Function;
  disabledMarkets: { [key: string]: boolean };
  bet: any;
}) => {
  const isMarketActive = marketOddsPayload({ odds: [value] })?.some((odd) => odd?.isMarketActive);
  return (
    <AccordionSummary
      expandIcon={<ArrowDropDownIcon sx={{ fontSize: '2rem' }} />}
      sx={{
        backgroundColor: isMarketActive
          ? HEADER_BACKGROUND_COLOR.ACTIVE
          : HEADER_BACKGROUND_COLOR.SUSPENDED,
      }}
    >
      <MarketHeaderContent
        value={value}
        holdPercentage={holdPercentage}
        holdPercentagesOptions={holdPercentagesOptions}
        oddId={oddId}
        setFieldValue={setFieldValue}
        toggleIsMarketActive={toggleIsMarketActive}
        toggleMarketLock={toggleMarketLock}
        disabledMarkets={disabledMarkets}
        bet={bet}
      />
    </AccordionSummary>
  );
};

export default AccordionSummaryWithMarketHeader;
