import React, { useState, useEffect } from 'react';
import round from 'lodash.round';
import { useRecoilState } from 'recoil';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import MaterialTable, { Column } from '@material-table/core';
import { parseISO } from 'date-fns';

import { Box, Grid, Typography, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { oddsType } from '@/atoms/oddsType';

import { EventListingSkeleton } from '@/components/PageSkeletons';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import ResetOddsBtn from '@/components/ResetOddsBtn';
import SaveOddsBtn from '@/components/SaveOddsBtn';
import ExportBtn from '@/components/ExportBtn';
import PublishFutureOdds from '@/components/PublishFutureOdds';

import useFetchFutureOdds from '@/hooks/futures/odds/useFetchFutureOdds';
import useUpdateFutureOdds from '@/hooks/futures/odds/useUpdateFutureOdds';

// import { ODD_DATA_TYPES } from '@/constants/oddDataTypes';
import * as httpStatusCodes from '@/constants/httpStatus';
// import { ODDS_PROJECTION_TYPE } from '@/constants/oddsProjectionType';
// import { SPORT_NAMES } from '@/constants/sportTypes';

import * as futureOddsHelpers from '@/helpers/futureOddHelpers';
import { futuresExcelDownloader } from '@/helpers/fileDownloader';
import { futureOddsToCSVFormatModifier } from '@/helpers/futureOddsToCSVFormatModifier';
import { invalidateFutureOdds } from '@/helpers/cachedQueries';
import { displayInPT as format } from '@/helpers/timeConverters';

import { generateFutureOddsColumns } from './columns';

import { tableIcons, defaultTableOptions, createTablePageOptions } from '@/utils/TableMisc';

import { FutureOdd, UpdateFutureOddsPayloadDTO } from '@/types/helpers/futureOdds';
import { SportTourFutures } from '@/types/futures';
import OddMarketNotes from '../Notes';
import SPORT_TYPES from '@/constants/sportTypes';

interface FutureOddsTabProps {
  sportType: string;
  tourYearId: string;
  futureType: string;
  sportTourFutures: SportTourFutures;
  handlePublishFutureOdds: (arg: string) => void;
}

const FutureOddsTab = (props: FutureOddsTabProps) => {
  const { sportType, tourYearId, futureType, sportTourFutures, handlePublishFutureOdds } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [oddType] = useRecoilState(oddsType);
  const { data: oddsData } = useFetchFutureOdds({
    sportType,
    tourYearId,
    futureType,
  });
  const [modifiedFutureOdds, setModifiedFutureOdds] = useState<FutureOdd[]>([]);
  const [holdPercentage, setHoldPercentage] = useState(
    futureOddsHelpers.getTotalProbability(oddsData?.odds || []),
  );
  const [hasOddsBeenEdited, setHasOddsBeenEdited] = useState(false);
  const [showOddsUpdateDialog, setShowOddsUpdateDialog] = useState(false);
  const [showResetOddsDialog, setShowResetOddsDialog] = useState(false);
  const { mutateAsync: updateFutureOdds } = useUpdateFutureOdds();
  const queryClient = useQueryClient();
  const eventWinnerAthleteId = oddsData?.eventWinnerAthleteId || null;

  const setInitialOddsData = (odds: FutureOdd[]) => {
    setModifiedFutureOdds(futureOddsHelpers.futureOddsDataLoader(odds));
    // total probability value increases cause of odds modification
    const calculatedProbability = futureOddsHelpers.getTotalProbability(odds);
    setHoldPercentage(calculatedProbability);
    if (hasOddsBeenEdited) {
      handleToggleHasOddsBeenEdited();
    }
  };

  const refetchOdds = () => {
    invalidateFutureOdds({ queryClient, sportType, tourYearId, futureType });
  };

  const saveFutureOddsData = async (
    payload: UpdateFutureOddsPayloadDTO,
    message = 'Successfully updated odds',
  ) => {
    const response = await updateFutureOdds(
      { sportType, tourYearId, futureType, payload },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          refetchOdds();
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  React.useEffect(() => {
    refetchOdds();
  }, [sportType, tourYearId, futureType]);

  useEffect(() => {
    if (oddsData?.odds) {
      // Handles initial data setting when api data changes
      setInitialOddsData(oddsData?.odds);
    }
  }, [JSON.stringify(oddsData)]);

  const handleToggleConfirmDialog = () => setShowOddsUpdateDialog(!showOddsUpdateDialog);

  const handleToggleResetDialog = () => setShowResetOddsDialog(!showResetOddsDialog);

  const handleToggleHasOddsBeenEdited = () => setHasOddsBeenEdited(!hasOddsBeenEdited);

  const saveOddsHandler = (odds: FutureOdd[]) => {
    const totalProbability = futureOddsHelpers.getTotalProbability(odds);
    setHoldPercentage(totalProbability);
    setModifiedFutureOdds(odds);

    if (!hasOddsBeenEdited) {
      handleToggleHasOddsBeenEdited();
    }
  };

  const updateOddsData = async () => {
    const payload = futureOddsHelpers.futureOddsPayloadFormatter(modifiedFutureOdds);
    const responseStatus = await saveFutureOddsData({ items: payload });

    if (responseStatus === httpStatusCodes.OK && hasOddsBeenEdited) {
      handleToggleHasOddsBeenEdited();
      const calculatedProbability = futureOddsHelpers.getTotalProbability(oddsData?.odds || []);
      setHoldPercentage(calculatedProbability);
    }
  };

  const futureOddsHeaders: Column<any>[] = generateFutureOddsColumns({
    oddType,
    holdPercentage,
    eventWinnerAthleteId,
    sportType,
  });

  const MIN_HOLD_PERCENTAGE = React.useMemo(() => {
    if (modifiedFutureOdds && modifiedFutureOdds.length > 0) {
      let total = 0;
      modifiedFutureOdds.map((data: any) => {
        total += +data?.trueProbability;
        return data;
      });
      return round(total);
    }
    return 100;
  }, [modifiedFutureOdds]);

  // Max limit value from the default hold percentage
  // Note: Allowed scaling of vig to 2x the base hold for DGPT sport. ref [ASDTECH-571](https://altsportsdata.atlassian.net/browse/ASDTECH-571) for more details.
  const MAX_PERCENTAGE_LIMIT_VALUE = sportType === SPORT_TYPES.DGPT ? 2 : 1.3;
  const MAX_ALLOWED_HOLD_PERCENTAGE_VALUE = MIN_HOLD_PERCENTAGE * MAX_PERCENTAGE_LIMIT_VALUE;

  const MAX_HOLD_PERCENTAGE = MIN_HOLD_PERCENTAGE * 2;

  const holdPercentagesOptions = React.useMemo(
    () =>
      Array(MAX_HOLD_PERCENTAGE - MIN_HOLD_PERCENTAGE + 1)
        .fill(0)
        .map((_, idx) => MIN_HOLD_PERCENTAGE + idx),
    [MIN_HOLD_PERCENTAGE, MAX_HOLD_PERCENTAGE],
  );

  const exportHandler = () => {
    const modData = JSON.parse(JSON.stringify(modifiedFutureOdds || []));

    const { title, headers, csvData } = futureOddsToCSVFormatModifier({
      data: modData,
      futureType,
      oddType,
      sportType,
    });

    if (csvData.length > 0) {
      const lastItemUpdatedAt = oddsData?.traderUpdatedAtDate;
      const updatedAt = lastItemUpdatedAt
        ? format(parseISO(lastItemUpdatedAt), 'MM_dd_yyyy_HH_mm_aaa')
        : '';
      futuresExcelDownloader({
        title,
        headers,
        csvData,
        updatedAt,
        name: sportTourFutures?.name || '',
        year: sportTourFutures?.year || 0,
        sport: sportType,
      });
    }

    if (csvData.length === 0) enqueueSnackbar(`No Data Found for ${title}`);
  };

  if (!oddsData?.odds) return <EventListingSkeleton />;

  return (
    <Box>
      <OddMarketNotes
        sportType={sportType}
        futureType={futureType}
        tourYearId={tourYearId}
        note={oddsData?.notes || ''}
        refetchOdds={refetchOdds}
      />
      <Grid
        container
        alignItems={'center'}
        item
        xs={12}
        sx={{
          // mt: 4,
          mb: 2,
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { xs: 'flex-start', sm: 'space-between' },
          gap: { xs: '1rem', sm: 0 },
        }}
      >
        <Grid container alignItems={'center'} item xs={12} sm={8}>
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 600, color: 'info.main' }}>
            HOLD PERCENTAGE:
          </Typography>
          &nbsp;
          <Select
            id="selectHoldPercentage"
            value={holdPercentage}
            onChange={(event: SelectChangeEvent<typeof holdPercentage>) => {
              const {
                target: { value },
              } = event;
              const newHoldPercentage = Number(value);
              if (newHoldPercentage !== holdPercentage) {
                setModifiedFutureOdds(
                  futureOddsHelpers.futureOddsModifier({
                    futureOdds: modifiedFutureOdds as FutureOdd[],
                    newHoldPercentage,
                    oldHoldPercentage: holdPercentage,
                    defaultHoldPercentage: MIN_HOLD_PERCENTAGE,
                  }),
                );
                setHoldPercentage(newHoldPercentage);
                if (!hasOddsBeenEdited) {
                  handleToggleHasOddsBeenEdited();
                }
              }
            }}
            sx={{ height: 30 }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
          >
            {React.Children.toArray(
              holdPercentagesOptions.map((percent) => (
                <MenuItem
                  value={percent}
                  disabled={percent > MAX_ALLOWED_HOLD_PERCENTAGE_VALUE ? true : false}
                >
                  {percent}
                </MenuItem>
              )),
            )}
          </Select>
          &nbsp;&nbsp;
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontWeight: 600,
              color: 'neutral.main',
              marginLeft: '0.75rem',
            }}
          >
            MARGIN:
          </Typography>
          &nbsp;
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 600, color: 'neutral.main' }}>
            {holdPercentage - MIN_HOLD_PERCENTAGE}%
          </Typography>
          &nbsp;
        </Grid>
        <Grid
          container
          alignItems={'center'}
          item
          xs={12}
          sm={4}
          sx={{ justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}
        >
          <ResetOddsBtn
            disabled={!hasOddsBeenEdited}
            resetOddsHandler={() => {
              handleToggleResetDialog();
            }}
          />
          <SaveOddsBtn
            disabled={!hasOddsBeenEdited}
            saveOddsHandler={() => {
              handleToggleConfirmDialog();
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        // justifyContent={'flex-end'}
        alignItems={'flex-start'}
        item
        xs={12}
        sx={{ marginY: '1rem' }}
      >
        <ExportBtn handleOnClick={exportHandler} />
        <PublishFutureOdds
          clientUpdatedAtDate={oddsData?.clientUpdatedAtDate || ''}
          traderUpdatedAtDate={oddsData?.traderUpdatedAtDate || ''}
          traderUpdatedAtDates={oddsData?.traderUpdatedAtDate || null}
          handlePublishFutureOdds={() => {
            handlePublishFutureOdds(futureType);
          }}
          isMarketOpen={sportTourFutures?.isOpen}
        />
      </Grid>
      <Box>
        <MaterialTable
          data={modifiedFutureOdds || []}
          icons={tableIcons}
          columns={futureOddsHeaders}
          options={{
            toolbar: false,
            actionsColumnIndex: -1,
            // sorting: true,
            thirdSortClick: false,
            ...defaultTableOptions,
            pageSize: createTablePageOptions(modifiedFutureOdds?.length || 0).pageSize,
            pageSizeOptions: createTablePageOptions(modifiedFutureOdds?.length || 0)
              .pageSizeOptions,
          }}
          editable={{
            isDeleteHidden: () => true,
            onRowUpdate: (oddRow: FutureOdd) => {
              return new Promise((resolve) => {
                setTimeout(() => {
                  const finalRows = futureOddsHelpers?.editRowFormatter(
                    { ...oddRow, hasModifiedProbability: true },
                    modifiedFutureOdds,
                  );
                  saveOddsHandler(finalRows);
                  resolve('success');
                }, 1000);
              });
            },
            onRowDelete: () =>
              new Promise(() => {
                //
              }),
          }}
          localization={{
            header: {
              actions: '',
            },
          }}
        />
      </Box>
      {showOddsUpdateDialog && (
        <ConfirmDialog
          open={showOddsUpdateDialog}
          handleClose={handleToggleConfirmDialog}
          handleConfirm={() => {
            handleToggleConfirmDialog();
            updateOddsData();
          }}
          title={`ARE YOU SURE YOU WANT TO UPDATE THE ODDS?`}
          body={'This will update the odds.'}
        />
      )}
      {showResetOddsDialog && (
        <ConfirmDialog
          open={showResetOddsDialog}
          handleClose={handleToggleResetDialog}
          handleConfirm={() => {
            handleToggleResetDialog();
            setInitialOddsData(oddsData?.odds as FutureOdd[]);
          }}
          title={`ARE YOU SURE YOU WANT TO RESET THE ODDS?`}
          body={'This will reset the odds.'}
        />
      )}
    </Box>
  );
};

export default FutureOddsTab;
