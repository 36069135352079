import { Manager, Socket } from 'socket.io-client';

import config from '@/config/vars';
import { WEB_SOCKET_EVENT_TYPES } from '@/constants/socket';
import * as firebaseHelpers from '@/hooks/firebase';
import { PLAYER_URL, removeAuthToken, setAuthToken } from '@/constants/localStorageKeys';

interface SocketMap {
  [key: string]: Socket | null;
}

interface SocketVisibilityState {
  [key: string]: boolean;
}

let manager: Manager | null = null;
const socketMap: SocketMap = {};
const wasConnectedMap: SocketVisibilityState = {};

let isTabActive = !document.hidden;

let tokenCheckInterval: NodeJS.Timeout | null = null;
let currentAuthToken: string | null = null;

export const startTokenExpiryCheck = async (): Promise<void> => {
  if (tokenCheckInterval) clearTimeout(tokenCheckInterval); // Prevent multiple timers

  const user = await firebaseHelpers.fetchCurrentUser();
  if (!user) return;

  // Get token expiry details based on currentAuthToken
  const tokenResult = await user.getIdTokenResult();
  const expiryTime = new Date(tokenResult.expirationTime).getTime();
  const currentTime = Date.now();
  const timeUntilExpiry = expiryTime - currentTime;

  // Set timer to check again 5 minutes before expiry (or at least 30sec)
  const refreshTime = Math.max(timeUntilExpiry - 5 * 60 * 1000, 30 * 1000);

  tokenCheckInterval = setTimeout(startTokenExpiryCheck, refreshTime);
};

export const initializeTabVisibilityHandler = (): void => {
  document.addEventListener('visibilitychange', () => {
    const isTabVisible = !document.hidden;
    if (isTabVisible && !isTabActive) {
      // User has returned to the tab
      Object.entries(socketMap).forEach(([namespace, socket]) => {
        if (wasConnectedMap[namespace] && socket && !socket.connected) {
          if (config.isDevelop) {
            console.log(`Tab is visible again, reconnecting socket for namespace: ${namespace}`);
          }
          socket.connect();
        }
      });
    }

    isTabActive = isTabVisible;
  });
};

export const getSocketManager = (url: string, token: string): Manager => {
  if (!manager) {
    manager = new Manager(url, {
      reconnectionDelay: 1_000,
      reconnectionDelayMax: 30_000,
      randomizationFactor: 0.5,
      reconnectionAttempts: 10,
      extraHeaders: {
        authorization: `Bearer ${token}`,
      },
    });
  }
  return manager;
};

export const initiateSocketConnection = (namespace: string, token: string | undefined): void => {
  if (socketMap[namespace]) {
    if (config.isDevelop)
      console.log(`Socket connection to namespace ${namespace} already established.`);
    return;
  }

  if (!token) {
    if (config.isDevelop)
      console.error('User is not authenticated, socket connection not established.');
    return;
  }

  const manager = getSocketManager(config.socketBaseUrl, token);
  const socket = manager.socket(`/${namespace}`);

  socket.on('connect', () => {
    wasConnectedMap[namespace] = true;
    if (config.isDevelop) console.log('Connected to socket with namespace:', namespace);
  });

  socket.on('connect_error', async (err) => {
    if (config.isDevelop) console.error('Connection failed:', err?.message);
    if (err?.message === WEB_SOCKET_EVENT_TYPES.AUTHORIZATION_ERROR) {
      await handleAuthError();
    }
  });

  socket.on('error', async (error) => {
    if (config.isDevelop) console.error('Error', error);
    if (error?.message === WEB_SOCKET_EVENT_TYPES.AUTHORIZATION_ERROR) {
      await handleAuthError();
    }
  });

  socket.on('disconnect', (reason) => {
    if (config.isDevelop) console.warn(`Socket disconnected: ${reason}`);
    wasConnectedMap[namespace] = socket?.connected || false;

    // When the server explicitly disconnects the socket by calling socket.disconnect()
    // Then socket.io doesn't automatically try to reconnect.
    // Hence manually call socket.connect() to re-establish the connection after every 2 secs.
    if (reason === 'io server disconnect') {
      if (isTabActive) {
        setTimeout(() => {
          if (!socket.connected) {
            if (config.isDevelop) console.log('Reconnecting socket...');
            socket.connect();
          }
        }, 2000);
      }
    }
  });

  socket.on('reconnect_attempt', (attemptNumber) => {
    if (config.isDevelop)
      console.log(`Socket attempting to reconnect... Attempt #${attemptNumber}`);
  });

  socket.on('reconnect', (attemptNumber) => {
    if (config.isDevelop)
      console.log(`Socket successfully reconnected after ${attemptNumber} attempts.`);
  });

  socket.on('reconnect_error', (error) => {
    if (config.isDevelop) console.error('Socket reconnection failed:', error);
  });

  socketMap[namespace] = socket;

  if (isTabActive) {
    socket.connect();
  }

  startTokenExpiryCheck();
};

export const disconnectSocket = (namespace: string): void => {
  const socket = socketMap[namespace];
  if (socket) {
    wasConnectedMap[namespace] = false; // Update connection tracking
    socket.disconnect();
    if (config.isDevelop) console.log(`Socket disconnected from namespace ${namespace}`);
    socketMap[namespace] = null;
  }
};

export const subscribeToEvent = (
  namespace: string,
  event: string,
  callback: (data: any) => void,
): void => {
  const socket = socketMap[namespace];
  if (!socket) return;
  socket.on(event, (data) => {
    if (config.isDevelop) console.log(`Received event ${event} from ${namespace}:`, data);
    callback(data);
  });
};

// Subscribe to all events in WEB_SOCKET_EVENT_TYPES
export const subscribeToAllEvents = (
  namespace: string,
  callback: (event: string, data: any) => void,
): (() => void) => {
  const socket = socketMap[namespace];
  if (!socket) return () => {};

  Object.values(WEB_SOCKET_EVENT_TYPES).forEach((eventType) => {
    const boundCallback = (data: any) => {
      if (config.isDevelop) console.log(`Received event ${eventType} from ${namespace}:`, data);
      return callback(eventType, data);
    };
    socket.on(eventType, boundCallback);
  });

  return () => {
    if (!socket) return;
    Object.values(WEB_SOCKET_EVENT_TYPES).forEach((eventType) => {
      socket.removeAllListeners(eventType);
    });
  };
};

export const sendEvent = (
  namespace: string,
  event: string,
  data: any,
  callback?: (response: any) => void,
): void => {
  const socket = socketMap[namespace];
  if (!socket) return;

  if (config.isDevelop) console.log(`Sending event ${event} from ${namespace}:`, data);

  socket.volatile.emit(event, data, (response: any) => {
    if (callback) callback?.(response);
  });
};

export const updateSocketAuthToken = async (newToken: string): Promise<void> => {
  if (!newToken || newToken === currentAuthToken) return;

  currentAuthToken = newToken;

  startTokenExpiryCheck();

  if (manager) {
    manager.opts.extraHeaders = { authorization: `Bearer ${newToken}` };

    Object.values(socketMap).forEach((socket) => {
      if (socket) {
        socket.disconnect();
        socket.connect();
      }
    });

    if (config.isDevelop) console.log('Updated socket authentication with new token');
  }
};

// Update hooks/index.ts as well
export const refreshToken = async (): Promise<string | null> => {
  try {
    const user = await firebaseHelpers.fetchCurrentUser();

    const token = await firebaseHelpers.fetchToken(user);
    setAuthToken(token);

    return token;
  } catch (error) {
    localStorage.removeItem(PLAYER_URL);
    removeAuthToken();
    firebaseHelpers.logout();
    window.location.href = window.location.host;
    return null;
  }
};

export const handleAuthError = async () => {
  const newToken = await refreshToken();
  if (newToken) updateSocketAuthToken(newToken as unknown as string);
};
