import React from 'react';
import round from 'lodash.round';
import { Column } from '@material-table/core';

import { Box, Typography, TextField } from '@mui/material';

import DecimalField from '@/components/DecimalField';

import * as oddsHelpers from '@/helpers/odds';

import ODDS_TYPES from '@/constants/oddsTypes';
import { fractionPattern } from '@/constants/misc';
import * as oddRanges from '@/constants/oddRanges';
import * as validationMessages from '@/constants/validationMessages';

import Winnerenabledicon from '@/images/icons/winner-enabled-icon.svg';

import { tableCellStyle, editCellTextFieldStyle, headToHeadTableStyles } from '@/utils/TableMisc';

export const generateHeatWinnerOddsColumns = ({ oddType, selectedHeat, holdPercentage }: any) => {
  const headers: Column<any>[] = [
    {
      title: 'Athlete',
      field: 'name',
      editable: 'never',
      render: (rowData: any) => (
        <>
          <Typography
            component={'span'}
            data-testid="athleteName"
            sx={{ ...headToHeadTableStyles.athleteNameStyle }}
          >
            {[rowData?.firstName, rowData?.middleName, rowData?.lastName].filter(Boolean).join(' ')}
          </Typography>
          {rowData.id === selectedHeat?.heatWinnerAthleteId ? (
            <Box
              component="img"
              alt="winner icon"
              src={Winnerenabledicon}
              sx={{ ...headToHeadTableStyles.winnerIconsStyle }}
            ></Box>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: 'Decimal Odds',
      field: 'decimalOdds',
      hidden: ODDS_TYPES.DECIMAL === oddType ? false : true,
      cellStyle: tableCellStyle,
      customSort: (a: any, b: any) => +a?.probability - +b?.probability,
      validate: (row: any) => {
        const newValue = +row?.decimalOdds;
        return newValue < oddRanges.MIN_DECIMAL_ODDS && newValue !== 0
          ? {
              isValid: false,
              helperText: validationMessages.ODD_WINNERS_DECIMAL_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (props: any) => (
        <>
          <DecimalField
            data-testid="decimalOdds"
            value={props.value}
            onBlur={(e: any) => {
              const odds = +e.target.value;
              const probability = oddsHelpers.getProbabilityFromDecimalOdds(odds);
              const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();
              const americanOdds =
                oddsHelpers.getAmericanOddsFromProbability(probability) > 0
                  ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
                  : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;

              props.onRowDataChange({
                ...props.rowData,
                probability,
                odds,
                decimalOdds: odds,
                fractionalOdds,
                americanOdds,
              });
            }}
            onChange={(e: any) => {
              props.onRowDataChange({
                ...props.rowData,
                odds: e.target.value,
                decimalOdds: e.target.value,
              });
            }}
            error={props?.error}
            helperText={props?.helperText || ''}
            thousandSeparator={false}
            decimalScale={2}
            sx={editCellTextFieldStyle}
          />
        </>
      ),
    },
    {
      title: 'Fractional Odds',
      field: 'fractionalOdds',
      hidden: ODDS_TYPES.FRACTIONAL === oddType ? false : true,
      cellStyle: tableCellStyle,
      customSort: (a: any, b: any) => +a?.probability - +b?.probability,
      validate: (row: any) => {
        const newValue = row?.fractionalOdds;
        return !newValue.match(fractionPattern) && newValue !== '0'
          ? {
              isValid: false,
              helperText: validationMessages.FRACTION_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (props: any) => (
        <>
          <TextField
            data-testid="fractionalOdds"
            value={props.value}
            onBlur={(e: any) => {
              const odds = oddsHelpers.getDecimalOddsFromOtherTypes(oddType, e.target.value);
              const probability = oddsHelpers.getProbabilityFromDecimalOdds(odds);
              const fractionalOdds = e.target.value;
              const americanOdds =
                oddsHelpers.getAmericanOddsFromProbability(probability) > 0
                  ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
                  : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;

              props.onRowDataChange({
                ...props.rowData,
                probability,
                odds,
                decimalOdds: odds,
                fractionalOdds,
                americanOdds,
              });
            }}
            onChange={(e: any) => {
              props.onRowDataChange({
                ...props.rowData,
                fractionalOdds: e.target.value,
              });
            }}
            error={props?.error}
            helperText={props?.helperText || ''}
            sx={editCellTextFieldStyle}
          />
        </>
      ),
    },
    {
      title: 'American Odds',
      field: 'americanOdds',
      hidden: ODDS_TYPES.AMERICAN === oddType ? false : true,
      cellStyle: tableCellStyle,
      customSort: (a: any, b: any) => +a?.probability - +b?.probability,
      validate: (row: any) => {
        const newValue = +row?.americanOdds;
        return newValue > oddRanges.MAX_NEGATIVE_AMERICAN_ODDS &&
          newValue < oddRanges.MIN_AMERICAN_ODDS &&
          newValue !== 0
          ? {
              isValid: false,
              helperText: validationMessages.ODD_WINNERS_AMERICAN_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (props: any) => (
        <>
          <DecimalField
            data-testid="americanOdds"
            value={props.value}
            onBlur={(e: any) => {
              const odds = oddsHelpers.getDecimalOddsFromOtherTypes(oddType, +e.target.value);
              const probability = round(oddsHelpers.getProbabilityFromDecimalOdds(odds), 1);
              const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();

              props.onRowDataChange({
                ...props.rowData,
                probability,
                odds,
                decimalOdds: odds,
                fractionalOdds,
                americanOdds: +e.target.value > 0 ? `+${e.target.value}` : e.target.value,
              });
            }}
            onChange={(e: any) => {
              props.onRowDataChange({
                ...props.rowData,
                americanOdds: +e.target.value > 0 ? `+${e.target.value}` : e.target.value,
              });
            }}
            error={props?.error}
            helperText={props?.helperText || ''}
            thousandSeparator={false}
            decimalScale={0}
            allowNegative={true}
            sx={editCellTextFieldStyle}
          />
        </>
      ),
    },
    {
      title: 'Probability',
      field: 'probability',
      defaultSort: 'desc',
      customSort: (a: any, b: any) => +a?.probability - +b?.probability,
      align: 'left',
      validate: (row: any) => {
        const totalPercentageModified = oddsHelpers.findTotalPercentage(
          selectedHeat?.athletes,
          row?.id,
          {
            modifiedRowsOnly: true,
          },
        );
        const newRemainingTotalProbability = holdPercentage - totalPercentageModified;
        return Number(row?.probability) < 0
          ? 'Enter Probability'
          : Number(row?.probability || 0) > newRemainingTotalProbability
          ? `Probability can't be more than ${round(newRemainingTotalProbability, 2)}`
          : true;
      },
      render: (rowData: any) => (
        <Typography data-testid="probability" sx={tableCellStyle}>
          {round(+rowData?.probability, 2)}
        </Typography>
      ),
      editComponent: (props: any) => (
        <TextField
          data-testid="probability"
          variant="outlined"
          value={round(+props?.value, 2)}
          type="number"
          inputProps={{
            step: '0.01',
          }}
          onBlur={(e) => {
            const probability = +e.target.value;
            const odds = oddsHelpers.getDecimalOddsFromProbability(probability);
            const decimalOdds = odds.toString();
            const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();
            const americanOdds =
              oddsHelpers.getAmericanOddsFromProbability(probability) > 0
                ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
                : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;
            props.onRowDataChange({
              ...props.rowData,
              probability,
              odds,
              decimalOdds,
              fractionalOdds,
              americanOdds,
            });
          }}
          onChange={(e: any) => {
            props.onRowDataChange({
              ...props.rowData,
              probability: +e.target.value,
            });
          }}
          error={props?.error}
          helperText={props?.helperText || ''}
          sx={editCellTextFieldStyle}
        />
      ),
    },
  ];

  return headers;
};
