import * as React from 'react';
import { useDebouncedCallback } from 'use-debounce';

import {
  Box,
  IconButton,
  styled,
  TextField,
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps,
  AccordionSummaryProps,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import UnstyledDecimalField from '@/components/DecimalField';

import { editCellTextFieldStyle } from '@/utils/TableMisc';
import ODDS_TYPES from '@/constants/oddsTypes';
// import { fractionPattern } from '@/constants/misc';
// import * as oddRanges from '@/constants/oddRanges';
// import * as validationMessages from '@/constants/validationMessages';
import * as oddsHelpers from '@/helpers/odds';

import { JASubMarketTypes, JABetTypes } from '@/constants/jaialai';
import { BORDER_COLOR, TEXT_COLOR } from './constants';

// Market Types Toggle------

export const MarketTypeToggleLabel = styled(Typography)<{ component?: string }>({
  fontSize: '0.875rem',
  lineHeight: '1.5rem',
  fontWeight: 600,
});

export const MarketToggleIcon = styled(IconButton)(() => ({
  backgroundColor: TEXT_COLOR.WHITE,
  border: `1px solid ${TEXT_COLOR.GRAY}`,
  borderRadius: '0.25rem',
  padding: '0.2rem',
  '&:first-of-type': {
    borderRight: 0,
    borderRadius: '0.25rem 0 0 0.25rem',
  },
  '&:last-of-type': {
    borderRadius: '0 0.25rem 0.25rem 0',
  },
}));

// ------------

// Market Types Details

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  width: '100%',
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowDropDownIcon />} {...props} />
))(() => ({
  width: '100%',

  border: `1px solid ${BORDER_COLOR.ACTIVE}`,
  // padding: '0.4rem',
  backgroundColor: '#F3F4F6',
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  padding: '0.4rem',
  borderRadius: '0.25rem',
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
  width: '100%',
}));

export const OddSubMarketLabel = styled(Typography)<{ component?: string }>({
  color: '#212529',
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: '0.0625rem',
});

export const GridCell = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: `0 0 0 1px ${BORDER_COLOR.ACTIVE}`,
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  paddingRight: '0.5rem',
  paddingLeft: '0.5rem',
});

export const CellLabel = styled(Typography)<{ component?: string }>({
  fontWeight: 600,
  fontSize: '0.8125rem',
  lineHeight: '1.25rem',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
});

export const DebugLabel = styled(Typography)<{ component?: string }>({
  fontWeight: 600,
  fontSize: '0.8125rem',
  lineHeight: '1.25rem',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  position: 'relative',
  zIndex: 4,
});

export const UnLockedIcon = styled(LockOpenIcon)({
  color: TEXT_COLOR.GRAY,
  transform: 'scaleX(-1)',
  fontSize: '1.5rem',
});

export const LockedIcon = styled(LockIcon)({
  color: TEXT_COLOR.GRAY,
  transform: 'scaleX(-1)',
  fontSize: '1.5rem',
});

export const CalculatedDecimalField = styled(UnstyledDecimalField)({
  ...editCellTextFieldStyle,
  width: '8ch',
  '& input': {
    textAlign: 'center',
    padding: 0,
  },
  '& .MuiInputBase-input': {
    padding: '0.35rem 0.75rem',
    fontSize: '0.812rem',
  },
  backgroundColor: '#DFDFDF',
});

export const DecimalOddTextField = ({
  row,
  changeHandler,
  disabled = false,
  id,
}: {
  row: any;
  changeHandler: Function;
  disabled: boolean;
  id: string;
}) => {
  // const validate = () => {
  //   const newValue = row?.odds;

  //   return newValue < oddRanges.MIN_DECIMAL_ODDS
  //     ? {
  //         isValid: false,
  //         helperText: validationMessages.DECIMAL_ODDS_VALIDATION_MSG,
  //       }
  //     : { isValid: true, helperText: '' };
  // };
  return (
    <UnstyledDecimalField
      id={id}
      value={row?.odds}
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        const odds = +e.target.value;
        const probability = oddsHelpers.getProbabilityFromDecimalOdds(odds);
        const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();
        const americanOdds =
          oddsHelpers.getAmericanOddsFromProbability(probability) > 0
            ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
            : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;
        if (changeHandler) {
          changeHandler({
            ...row,
            odds,
            decimalOdds: odds,
            probability,
            fractionalOdds,
            americanOdds,
            hasModifiedProbability: true,
          });
        }
      }}
      // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
      //   if (changeHandler) {
      //     changeHandler({
      //       ...row,
      //       odds: +e.target.value,
      //       decimalOdds: e.target.value,
      //       hasModifiedProbability: true,
      //     });
      //   }
      // }}
      // error={!validate()?.isValid}
      // helperText={validate()?.helperText}
      thousandSeparator={false}
      decimalScale={2}
      sx={{
        ...editCellTextFieldStyle,
        width: '8ch',
        '& input': {
          textAlign: 'center',
          padding: 0,
        },
        '& .MuiInputBase-input': {
          padding: '0.35rem 0.75rem',
          fontSize: '0.812rem',
        },
      }}
      disabled={disabled}
    />
  );
};

export const FractionalOddTextField = ({
  row,
  changeHandler,
  disabled = false,
  id,
}: {
  row: any;
  changeHandler: Function;
  disabled: boolean;
  id: string;
}) => {
  // const validate = () => {
  //   const newValue = (row?.fractionalOdds || oddRanges.MIN_FRACTION_ODDS).toString();

  //   return !newValue.match(fractionPattern) && newValue !== '0'
  //     ? {
  //         isValid: false,
  //         helperText: validationMessages.FRACTION_ODDS_VALIDATION_MSG,
  //       }
  //     : { isValid: true, helperText: '' };
  // };
  return (
    <TextField
      id={id}
      value={row?.fractionalOdds}
      onBlur={(e) => {
        const odds = oddsHelpers.getDecimalOddsFromOtherTypes(
          ODDS_TYPES.FRACTIONAL,
          e.target.value,
        );
        const probability = oddsHelpers.getProbabilityFromDecimalOdds(odds);
        const fractionalOdds = e.target.value;
        const americanOdds =
          oddsHelpers.getAmericanOddsFromProbability(probability) > 0
            ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
            : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;
        if (changeHandler) {
          changeHandler({
            ...row,
            probability,
            odds,
            decimalOdds: odds,
            fractionalOdds,
            americanOdds,
            hasModifiedProbability: true,
          });
        }
      }}
      // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
      //   if (changeHandler) {
      //     changeHandler({
      //       ...row,
      //       fractionalOdds: e.target.value,
      //       hasModifiedProbability: true,
      //     });
      //   }
      // }}
      // error={!validate()?.isValid}
      // helperText={validate()?.helperText}
      sx={editCellTextFieldStyle}
      disabled={disabled}
    />
  );
};

export const AmericanOddTextField = ({
  row,
  changeHandler,
  disabled = false,
  id,
}: {
  row: any;
  changeHandler: Function;
  disabled: boolean;
  id: string;
}) => {
  // const validate = () => {
  //   const newValue = Number(row?.americanOdds);
  //   return newValue > oddRanges.MAX_NEGATIVE_AMERICAN_ODDS && newValue < oddRanges.MIN_AMERICAN_ODDS
  //     ? {
  //         isValid: false,
  //         helperText: validationMessages.AMERICAN_ODDS_VALIDATION_MSG,
  //       }
  //     : { isValid: true, helperText: '' };
  // };
  return (
    <UnstyledDecimalField
      id={id}
      value={row?.americanOdds}
      onBlur={(e: any) => {
        const probability = oddsHelpers.getProbabilityFromAmericanOdds(+e.target.value);
        const odds = oddsHelpers.getDecimalOddsFromProbability(probability);
        const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();
        const americanOdds = e.target.value;

        if (changeHandler) {
          changeHandler({
            ...row,
            probability,
            odds,
            decimalOdds: odds,
            fractionalOdds,
            americanOdds,
            hasModifiedProbability: true,
          });
        }
      }}
      // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
      //   if (changeHandler) {
      //     changeHandler({
      //       ...row,
      //       americanOdds: +e.target.value > 0 ? `+${e.target.value}` : e.target.value,
      //       hasModifiedProbability: true,
      //     });
      //   }
      // }}
      // error={!validate()?.isValid}
      // helperText={validate()?.helperText}
      thousandSeparator={false}
      decimalScale={0}
      allowNegative={true}
      sx={editCellTextFieldStyle}
      disabled={disabled}
    />
  );
};

export const CommonOddField = ({
  oddType,
  bet,
  subMarketType,
  teamType,
  updateSubMarketTeam,
  disabled,
  id,
}: any) => {
  switch (oddType) {
    case ODDS_TYPES.DECIMAL:
      return (
        <DecimalOddTextField
          id={id}
          row={bet?.[subMarketType]?.[teamType] || {}}
          changeHandler={(row: any) => updateSubMarketTeam(row, subMarketType, teamType)}
          disabled={disabled}
        />
      );
    case ODDS_TYPES.FRACTIONAL:
      return (
        <FractionalOddTextField
          id={id}
          row={bet?.[subMarketType]?.[teamType] || {}}
          changeHandler={(row: any) => updateSubMarketTeam(row, subMarketType, teamType)}
          disabled={disabled}
        />
      );
    case ODDS_TYPES.AMERICAN:
      return (
        <AmericanOddTextField
          id={id}
          row={bet?.[subMarketType]?.[teamType] || {}}
          changeHandler={(row: any) => updateSubMarketTeam(row, subMarketType, teamType)}
          disabled={disabled}
        />
      );
    default:
      return null;
  }
};

export const subMarketTypeStyles = ({
  betType,
  subMarketType,
}: {
  betType: number;
  subMarketType: number;
}) => {
  // DEPRECATED: Market, Bet and Sub Market code
  // const commonGridTemplateAreas = `"lock lock" "homeTeamCalculatedValue odds" "awayTeamCalculatedValue odds"`;
  const gridTemplateAreasConfig = {
    [JASubMarketTypes.OUTCOME_1]: {
      // DEPRECATED: Market, Bet and Sub Market code
      // [JABetTypes.CORRECT_SETS]: commonGridTemplateAreas,
      [JABetTypes.MATCH_WINNER]: `"lock ." ". homeTeamOutcome1" ". awayTeamOutcome1"`,
    },
    [JASubMarketTypes.OUTCOME_2]: {
      // DEPRECATED: Market, Bet and Sub Market code
      // [JABetTypes.CORRECT_SETS]: commonGridTemplateAreas,
      [JABetTypes.MATCH_WINNER]: `"lock ." "homeTeamCalculatedValue homeTeamOutcome2" "awayTeamCalculatedValue awayTeamOutcome2"`,
    },
    [JASubMarketTypes.OUTCOME_3]: {
      // DEPRECATED: Market, Bet and Sub Market code
      /* [JABetTypes.CORRECT_SETS]: commonGridTemplateAreas,
      [JABetTypes.TOTAL_SETS]: `". lock" "calculatedValue homeTeamOutcome3" "calculatedValue awayTeamOutcome3"`, */
      [JABetTypes.MATCH_WINNER]: `"lock ." "calculatedValue homeTeamOutcome3" "calculatedValue awayTeamOutcome3"`,
    },
    // DEPRECATED: Market, Bet and Sub Market code
    /* [JASubMarketTypes.OUTCOME_4]: {
      [JABetTypes.CORRECT_SETS]: commonGridTemplateAreas,
    }, */
  };

  const gridTemplateAreas = gridTemplateAreasConfig[subMarketType]?.[betType] || '';

  return {
    gridTemplateAreas,
  };
};

// --------------

// Counter Button -------------

const CounterInput = styled(UnstyledDecimalField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: TEXT_COLOR.WHITE,
    '& input': {
      textAlign: 'center',
      width: 30,
      padding: 0,
    },
    '& fieldset': { border: 'none' },
  },
  '& .MuiInputBase-input': {
    padding: '0.25rem 0.75rem',
    fontSize: '0.812rem',
  },
});

const CounterIconButton = styled(IconButton)({
  padding: '0.3rem',
});

export const CounterButton = ({
  value = 0,
  changeHandler,
  bgColor,
  disabled = false,
  incrId = '',
  decId = '',
  valId = '',
}: {
  value: number;
  changeHandler?: (arg0: number) => void;
  bgColor?: string;
  disabled?: boolean;
  incrId?: string;
  decId?: string;
  valId?: string;
}) => {
  const [count, setCount] = React.useState<number>(value || 0);

  const debouncedChangeHandler = useDebouncedCallback((newValue: number) => {
    if (changeHandler) {
      changeHandler(newValue);
    }
  }, 300);

  const handleChange = (e: any) => {
    const newValue = +e.target.value || 0;
    setCount(newValue);
    debouncedChangeHandler(newValue);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    debouncedChangeHandler(+e.target.value || 0);
  };

  React.useEffect(() => {
    setCount(value);
  }, [value]);

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        backgroundColor: bgColor ? bgColor : TEXT_COLOR.WHITE,
        borderRadius: '0.25rem',
        border: '1px solid #CED4DA',
      }}
    >
      <CounterIconButton
        disableRipple
        onClick={() => {
          const newValue = count - 1;
          setCount(newValue);
          debouncedChangeHandler(newValue);
        }}
        disabled={disabled}
        id={decId}
      >
        <RemoveIcon fontSize="small" />
      </CounterIconButton>
      <CounterInput
        size="small"
        onChange={handleChange}
        onBlur={handleBlur}
        value={count}
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: bgColor ? bgColor : TEXT_COLOR.WHITE,
          },
        }}
        allowNegative
        disabled={disabled}
        id={valId}
      />
      <CounterIconButton
        disableRipple
        onClick={() => {
          const newValue = count + 1;
          setCount(newValue);
          debouncedChangeHandler(newValue);
        }}
        disabled={disabled}
        id={incrId}
      >
        <AddIcon fontSize="small" />
      </CounterIconButton>
    </Box>
  );
};

// -----------------------
