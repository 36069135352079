import { Select, Typography, TextField, MenuItem, Switch, SelectChangeEvent } from '@mui/material';
import round from 'lodash.round';
import { Column, EditComponentProps } from '@material-table/core';

import DecimalField from '@/components/DecimalField';

import ODDS_TYPES from '@/constants/oddsTypes';
import * as oddRanges from '@/constants/oddRanges';
import * as validationMessages from '@/constants/validationMessages';
// import { TOGGLE_OPTIONS, TOGGLE_OPTIONS_VALUES } from '.';
import { HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS } from '@/constants/misc';
import { exactasEventOddsModifier } from '@/helpers/exactas';

import { ExactaOddRow } from '@/types/odds/getOdds';

import { headToHeadTableStyles, editCellTextFieldStyle, tableCellStyle } from '@/utils/TableMisc';
import { fractionPattern } from '@/constants/misc';
import * as oddsHelpers from '@/helpers/odds';

// import Winnerenabledicon from '@/images/icons/winner-enabled-icon.svg';
// import Winnerdisabledicon from '@/images/icons/winner-disabled-icon.svg';

export type TOGGLE_OPTIONS_KEYS = 'WINNER' | 'VOIDED' | 'DRAW';
export type TOGGLE_OPTIONS_VALUES = 'winner' | 'voided' | 'draw';
export const TOGGLE_OPTIONS: Record<TOGGLE_OPTIONS_KEYS, TOGGLE_OPTIONS_VALUES> = {
  WINNER: 'winner',
  VOIDED: 'voided',
  DRAW: 'draw',
};

export const generateExactasEventOddsColumns = ({
  rowUpdateHandlerQs,
  holdPercentagesOptions,
  handleToggleChange,
  oddType,
  setTempVoidedRowId,
  handleToggleConfirmVoidDialog,
  sport,
  isHeatWinnerMarketVoided,
}: {
  rowUpdateHandler: (row: ExactaOddRow) => void;
  rowUpdateHandlerQs: (row: ExactaOddRow) => void;
  holdPercentagesOptions: number[];
  handleToggleChange: ({
    checked,
    type,
    rowMatchUpId,
    winnerId,
  }: {
    checked: boolean;
    type: TOGGLE_OPTIONS_VALUES;
    rowMatchUpId: string;
    winnerId?: string;
  }) => void;
  oddType: string;
  setTempVoidedRowId: (id: string) => void;
  handleToggleConfirmVoidDialog: () => void;
  sport: string;
  isHeatWinnerMarketVoided?: boolean;
}) => {
  const isSortAllowed = !HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport) ? true : false;
  const headers: Column<ExactaOddRow>[] = [
    {
      title: 'Hold Percentage',
      field: 'holdingPercentage',
      editable: 'never',
      width: '5%',
      sorting: false,
      render: (row: ExactaOddRow) => (
        <Select
          data-testid="selectHoldPercentage"
          value={row.holdingPercentage}
          onChange={(event: SelectChangeEvent<typeof row.holdingPercentage>) => {
            const {
              target: { value },
            } = event;
            const newHoldPercentage = Number(value);

            if (row.holdingPercentage !== newHoldPercentage) {
              const [minHoldPercentage] = holdPercentagesOptions;
              const modExactasEventOddRow = exactasEventOddsModifier({
                exactasEventOdd: row,
                newHoldPercentage,
                oldHoldPercentage: Number(row?.holdingPercentage) || 100,
                defaultHoldPercentage: minHoldPercentage,
              });
              rowUpdateHandlerQs(modExactasEventOddRow);
            }
          }}
          sx={{ height: 30, ...headToHeadTableStyles.athleteNameStyle }}
          MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
          disabled={row.voided || row.draw || isHeatWinnerMarketVoided}
        >
          {holdPercentagesOptions.map((percent) => (
            <MenuItem key={percent} value={percent}>
              {percent}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      title: 'Margin',
      editable: 'never',
      width: '5%',
      sorting: false,
      render: (row: ExactaOddRow) => (
        <Typography
          data-testid="margin"
          component="span"
          sx={{ ...headToHeadTableStyles.athleteNameStyle }}
        >
          {Number(row?.holdingPercentage) - 100}%
        </Typography>
      ),
    },
    {
      title: '1st Place',
      field: 'firstParticipant',
      editable: 'never',
      // headerStyle: { minWidth: '4.375rem', maxWidth: '4.375rem' },
      sorting: false,
      render: (row: ExactaOddRow) => (
        <Typography
          data-testid="firstParticipant"
          component="span"
          sx={{ ...headToHeadTableStyles.athleteNameStyle }}
        >
          {row?.firstParticipant || ''}
        </Typography>
      ),
    },
    {
      title: '2nd Place',
      field: 'secondParticipant',
      editable: 'never',
      // headerStyle: { minWidth: '4.375rem', maxWidth: '4.375rem' },
      sorting: false,
      render: (row: ExactaOddRow) => (
        <Typography
          data-testid="secondParticipant"
          component="span"
          sx={{ ...headToHeadTableStyles.athleteNameStyle }}
        >
          {row?.secondParticipant || ''}
        </Typography>
      ),
    },
    {
      title: '3rd Place',
      field: 'thirdParticipant',
      editable: 'never',
      // headerStyle: { minWidth: '4.375rem', maxWidth: '4.375rem' },
      sorting: false,
      render: (row: ExactaOddRow) => (
        <Typography
          data-testid="thirdParticipant"
          component="span"
          sx={{ ...headToHeadTableStyles.athleteNameStyle }}
        >
          {row?.thirdParticipant || ''}
        </Typography>
      ),
    },
    {
      title: 'Probability',
      field: 'probability',
      width: '5%',
      align: 'left',
      // customSort: (a: Team, b: Team) => a.probability - b.probability,
      validate: (row: ExactaOddRow) => {
        return Number(row?.probability) < 0
          ? 'Enter Probability'
          : Number(row?.probability) > Number(row?.maxProbability)
          ? `Probability can't be more than ${Number(row?.maxProbability)}`
          : Number(row?.probability) < Number(row?.trueProbability)
          ? `Probability can't be less than ${Number(row?.trueProbability)}`
          : true;
      },
      editComponent: (props: EditComponentProps<ExactaOddRow> & { helperText?: string }) => (
        <TextField
          data-testid="probability"
          variant="outlined"
          value={round(+props?.value, 2)}
          type="number"
          inputProps={{
            step: '0.01',
          }}
          onBlur={(e) => {
            const probability = +e.target.value;
            const odds = oddsHelpers.getDecimalOddsFromProbability(probability);
            const decimalOdds = odds.toString();
            const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();
            const americanOdds =
              oddsHelpers.getAmericanOddsFromProbability(probability) > 0
                ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
                : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;
            const probabilityChangeDelta = round(
              (probability / Number(props.rowData.trueProbability)) * 100,
            );

            props.onRowDataChange({
              ...props.rowData,
              probability,
              odds,
              decimalOdds,
              fractionalOdds,
              americanOdds,
              holdingPercentage: probabilityChangeDelta <= 100 ? 100 : probabilityChangeDelta,
            });
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onRowDataChange({
              ...props.rowData,
              probability: +e.target.value,
            });
          }}
          error={props?.error}
          helperText={props?.helperText || ''}
          sx={editCellTextFieldStyle}
        />
      ),
      cellStyle: tableCellStyle,
    },

    {
      title: 'Decimal Odds',
      field: 'decimalOdds',
      hidden: ODDS_TYPES.DECIMAL === oddType ? false : true,
      width: '5%',
      // customSort: (a: Team, b: Team) => a.probability - b.probability,
      validate: (row: ExactaOddRow) => {
        const newValue = Number(row?.decimalOdds);
        const prob = oddsHelpers.getProbabilityFromDecimalOdds(newValue);
        return prob < Number(row?.trueProbability) && newValue !== 0
          ? {
              isValid: false,
              helperText: `Decimal odds cannot be more than ${Number(row?.maxDecimalOdds)}`,
            }
          : prob > Number(row?.maxProbability) && newValue !== 0
          ? {
              isValid: false,
              helperText: `Decimal odds cannot be less than ${Number(row?.minDecimalOdds)}`,
            }
          : true;
      },
      editComponent: (props: EditComponentProps<ExactaOddRow> & { helperText?: string }) => (
        <DecimalField
          data-testid="decimalOdds"
          value={props.value}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            const odds = +e.target.value;
            const probability = oddsHelpers.getProbabilityFromDecimalOdds(odds);
            const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();
            const americanOdds =
              oddsHelpers.getAmericanOddsFromProbability(probability) > 0
                ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
                : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;
            const probabilityChangeDelta = round(
              (probability / Number(props.rowData.trueProbability)) * 100,
            );
            props.onRowDataChange({
              ...props.rowData,
              probability,
              odds,
              decimalOdds: odds,
              fractionalOdds,
              americanOdds,
              holdingPercentage: probabilityChangeDelta <= 100 ? 100 : probabilityChangeDelta,
            });
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onRowDataChange({
              ...props.rowData,
              odds: +e.target.value,
              decimalOdds: e.target.value,
            });
          }}
          error={props?.error}
          helperText={props?.helperText || ''}
          thousandSeparator={false}
          decimalScale={2}
          sx={editCellTextFieldStyle}
        />
      ),
      cellStyle: tableCellStyle,
    },
    {
      title: 'Fractional Odds',
      field: 'fractionalOdds',
      hidden: ODDS_TYPES.FRACTIONAL === oddType ? false : true,
      width: '5%',
      // customSort: (a: Team, b: Team) => a.probability - b.probability,
      validate: (row: ExactaOddRow) => {
        const newValue = (row?.fractionalOdds || oddRanges.MIN_FRACTION_ODDS).toString();
        const convertedOdd = oddsHelpers.decimalFromFraction(newValue);
        const prob = oddsHelpers.getProbabilityFromDecimalOdds(convertedOdd);
        return !newValue.match(fractionPattern) && newValue !== '0'
          ? {
              isValid: false,
              helperText: validationMessages.FRACTION_ODDS_VALIDATION_MSG,
            }
          : prob < Number(row?.trueProbability) || prob > Number(row?.maxProbability)
          ? {
              isValid: false,
              helperText: validationMessages.FRACTION_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (props: EditComponentProps<ExactaOddRow> & { helperText?: string }) => (
        <TextField
          data-testid="fractionalOdds"
          value={props.value}
          onBlur={(e) => {
            const odds = oddsHelpers.getDecimalOddsFromOtherTypes(oddType, e.target.value);
            const probability = oddsHelpers.getProbabilityFromDecimalOdds(odds);
            const fractionalOdds = e.target.value;
            const americanOdds =
              oddsHelpers.getAmericanOddsFromProbability(probability) > 0
                ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
                : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;
            const probabilityChangeDelta = round(
              (probability / Number(props.rowData.trueProbability)) * 100,
            );
            props.onRowDataChange({
              ...props.rowData,
              probability,
              odds,
              decimalOdds: odds,
              fractionalOdds,
              americanOdds,
              holdingPercentage: probabilityChangeDelta <= 100 ? 100 : probabilityChangeDelta,
            });
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onRowDataChange({
              ...props.rowData,
              fractionalOdds: e.target.value,
            });
          }}
          error={props?.error}
          helperText={props?.helperText || ''}
          sx={editCellTextFieldStyle}
        />
      ),
      cellStyle: tableCellStyle,
    },
    {
      title: 'American Odds',
      field: 'americanOdds',
      hidden: ODDS_TYPES.AMERICAN === oddType ? false : true,
      width: '5%',
      // customSort: (a: Team, b: Team) => a.probability - b.probability,
      validate: (row: ExactaOddRow) => {
        const newValue = Number(row?.americanOdds);
        const convertedOdd = oddsHelpers.getDecimalOddsFromOtherTypes(oddType, newValue);
        const prob = oddsHelpers.getProbabilityFromDecimalOdds(convertedOdd);
        return newValue > oddRanges.MAX_NEGATIVE_AMERICAN_ODDS &&
          newValue < oddRanges.MIN_AMERICAN_ODDS &&
          newValue !== 0
          ? {
              isValid: false,
              helperText: validationMessages.ODD_WINNERS_AMERICAN_ODDS_VALIDATION_MSG,
            }
          : prob < Number(row?.trueProbability)
          ? {
              isValid: false,
              helperText: `American odds must be  ${
                Number(row?.minAmericanOdds) < 0
                  ? `greater than ${Number(row?.maxAmericanOdds)}`
                  : `less than ${Number(row?.maxAmericanOdds)}`
              }.`,
            }
          : prob > Number(row?.maxProbability)
          ? {
              isValid: false,
              helperText: `American odds must be  ${
                Number(row?.minAmericanOdds) < 0
                  ? `less than ${Number(row?.minAmericanOdds)}`
                  : `greater than ${Number(row?.minAmericanOdds)}`
              }.`,
            }
          : true;
      },
      editComponent: (props: EditComponentProps<ExactaOddRow> & { helperText?: string }) => (
        <DecimalField
          data-testid="americanOdds"
          value={props.value}
          onBlur={(e: any) => {
            const odds = oddsHelpers.getDecimalOddsFromOtherTypes(oddType, +e.target.value);
            const probability = round(oddsHelpers.getProbabilityFromDecimalOdds(odds), 1);
            const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();
            const probabilityChangeDelta = round(
              (probability / Number(props.rowData.trueProbability)) * 100,
            );

            props.onRowDataChange({
              ...props.rowData,
              probability,
              odds,
              decimalOdds: odds,
              fractionalOdds,
              americanOdds: +e.target.value > 0 ? `+${e.target.value}` : e.target.value,
              holdingPercentage: probabilityChangeDelta <= 100 ? 100 : probabilityChangeDelta,
            });
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onRowDataChange({
              ...props.rowData,
              americanOdds: +e.target.value > 0 ? `+${e.target.value}` : e.target.value,
            });
          }}
          error={props?.error}
          helperText={props?.helperText || ''}
          thousandSeparator={false}
          decimalScale={0}
          allowNegative={true}
          sx={editCellTextFieldStyle}
        />
      ),
      cellStyle: tableCellStyle,
    },

    {
      title: 'Draw',
      field: 'draw',
      align: 'right',
      editable: 'never',
      width: '5%',
      sorting: isSortAllowed,
      render: (row: ExactaOddRow) => (
        <>
          <Switch
            data-testid="drawToggle"
            disabled={row.voided || row.editedVoid || row.draw || isHeatWinnerMarketVoided}
            checked={row.editedDraw}
            onChange={(e) =>
              handleToggleChange({
                checked: e.target.checked,
                type: TOGGLE_OPTIONS.DRAW,
                rowMatchUpId: row.id,
              })
            }
          />
        </>
      ),
    },
    {
      title: 'Voided',
      field: 'voided',
      align: 'right',
      editable: 'never',
      width: '5%',
      sorting: isSortAllowed,
      render: (row: ExactaOddRow) => (
        <>
          <Switch
            data-testid="voidToggle"
            disabled={row.voided || row.draw || row.editedDraw || isHeatWinnerMarketVoided}
            checked={row.editedVoid}
            onChange={(e) => {
              handleToggleChange({
                checked: e.target.checked,
                type: TOGGLE_OPTIONS.VOIDED,
                rowMatchUpId: row.id,
              });
              setTempVoidedRowId(row?.id);
              handleToggleConfirmVoidDialog();
            }}
          />
        </>
      ),
    },
  ];

  return headers;
};
