import React, { useState, useEffect } from 'react';
import round from 'lodash.round';
import { useRecoilState } from 'recoil';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import MaterialTable, { Column } from '@material-table/core';
import { parseISO } from 'date-fns';

import { Box, Typography, TextField, MenuItem, Grid } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { oddsType } from '@/atoms/oddsType';

import DecimalField from '@/components/DecimalField';
import { EventListingSkeleton } from '@/components/PageSkeletons';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import ResetOddsBtn from '@/components/ResetOddsBtn';
import SaveOddsBtn from '@/components/SaveOddsBtn';
import ExportBtn from '@/components/ExportBtn';
import PublishOdds from '@/components/PublishOdds';

import useFetchPositionalOdds from '@/hooks/odds/positionalOdds/useFetchPositionalOdds';
import useUpdatePositionalOdds from '@/hooks/odds/positionalOdds/useUpdatePositionalOdds';

import ODDS_TYPES from '@/constants/oddsTypes';
import * as oddRanges from '@/constants/oddRanges';
import * as validationMessages from '@/constants/validationMessages';
import { ODD_DATA_TYPES } from '@/constants/oddDataTypes';
import { fractionPattern } from '@/constants/misc';
import * as httpStatusCodes from '@/constants/httpStatus';
import SPORT_TYPES, { SPORT_NAMES } from '@/constants/sportTypes';
import keys from '@/constants/queryKeys';

import * as oddsHelpers from '@/helpers/odds';
import { excelDownloader } from '@/helpers/fileDownloader';
import { oddsToCSVFormatModifier } from '@/helpers/oddsToCSVFormatModifier';
import { invalidatePositionalOdds } from '@/helpers/cachedQueries';
import { displayInPT as format } from '@/helpers/timeConverters';

import {
  EVENT_WINNERS_POSITION,
  FASTEST_LAP_POSITION,
  POSITION_MARKET_TOGGLE_MAPPING,
  POSITION_NOTES_MAPPING,
  POSITION_PROJECTION_MAPPING,
  RACE_TOP_10_POSITION,
  RACE_TOP_3_POSITION,
  RACE_TOP_5_POSITION,
} from '@/constants/oddTabs';

import { EventOddPosition, ModifiedEventOddPosition } from '@/types/helpers/odds';

import Winnerenabledicon from '@/images/icons/winner-enabled-icon.svg';
import SelectWinnerIcon from '@/images/icons/table-select-winner-icon.png';

import {
  tableIcons,
  tableCellStyle,
  editCellTextFieldStyle,
  defaultTableOptions,
  createTablePageOptions,
  headToHeadTableStyles,
} from '@/utils/TableMisc';
import { UpdateOddsPayloadDTO } from '@/types/odds/updateOdds';
import OddMarketNotes from '@/features/odds/Notes';
import OddMarketToggle from '../../OddMarketToggle';

interface OddsWinnerTabProps {
  sport: string;
  eventId: string;
  position: number;
  handlePublishOdds: (
    projectionType: string,
    roundHeatId?: string | null | undefined,
    roundId?: string | null | undefined,
    additionalParams?: { [key: string]: string | number },
  ) => void;
  handleSelectWinner: any;
}

const OddsWinnerTab = (props: OddsWinnerTabProps) => {
  const { sport, eventId, position, handlePublishOdds, handleSelectWinner } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [oddType] = useRecoilState(oddsType);
  const { data: oddsData } = useFetchPositionalOdds({
    sport,
    eventId,
    position,
  });
  const [modifiedEventOdds, setModifiedEventOdds] = useState<ModifiedEventOddPosition[]>([]);
  const [holdPercentage, setHoldPercentage] = useState(
    oddsHelpers.getTotalProbability(oddsData?.odds || []),
  );
  const [hasOddsBeenEdited, setHasOddsBeenEdited] = useState(false);
  const [showOddsUpdateDialog, setShowOddsUpdateDialog] = useState(false);
  const [showResetOddsDialog, setShowResetOddsDialog] = useState(false);
  const [showSelectWinnerDialog, setShowSelectWinnerDialog] = useState(false);
  const [selectedAthlete, setSelectedAthlete] = useState<any>(null);
  const { mutateAsync: updatePositionalOdds } = useUpdatePositionalOdds();
  const queryClient = useQueryClient();
  const eventWinnerAthleteId = oddsData?.eventWinnerAthleteId || null;
  const quickestAthleteId = oddsData?.quickestAthleteId || null;

  const event: any = queryClient.getQueryData([keys.events.fetchEvent, sport, eventId, 'Event']);

  const setInitialOddsData = (odds: EventOddPosition[]) => {
    setModifiedEventOdds(oddsHelpers.eventOddsDataLoader(odds));
    // total probability value increases cause of odds modification
    const calculatedProbability = oddsHelpers.getTotalProbability(odds);
    setHoldPercentage(calculatedProbability);
    if (hasOddsBeenEdited) {
      handleToggleHasOddsBeenEdited();
    }
  };

  const refetchOdds = () => {
    invalidatePositionalOdds(queryClient, sport, eventId, position);
  };

  const savePositionalOddsData = async (
    payload: UpdateOddsPayloadDTO,
    position: number,
    message = 'Successfully updated odds',
  ) => {
    const response = await updatePositionalOdds(
      { sport, eventId, payload },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          refetchOdds();
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  React.useEffect(() => {
    refetchOdds();
  }, [eventId]);

  useEffect(() => {
    if (oddsData?.odds) {
      // Handles initial data setting when api data changes
      setInitialOddsData(oddsData?.odds);
    }
  }, [JSON.stringify(oddsData)]);

  const handleToggleConfirmDialog = () => setShowOddsUpdateDialog(!showOddsUpdateDialog);

  const handleToggleResetDialog = () => setShowResetOddsDialog(!showResetOddsDialog);

  const handleToggleSelectWinnerDialog = (rowData: any = null) => {
    setShowSelectWinnerDialog(!showSelectWinnerDialog);
    setSelectedAthlete(rowData);
  };

  const handleToggleHasOddsBeenEdited = () => setHasOddsBeenEdited(!hasOddsBeenEdited);

  const saveOddsHandler = (odds: ModifiedEventOddPosition[]) => {
    const totalProbability = oddsHelpers.getTotalProbability(odds);
    setHoldPercentage(totalProbability);
    setModifiedEventOdds(odds);

    if (!hasOddsBeenEdited) {
      handleToggleHasOddsBeenEdited();
    }
  };

  const updateOddsData = async () => {
    const payload = oddsHelpers.positionalOddsPayloadFormatter(modifiedEventOdds);
    const responseStatus = await savePositionalOddsData({ items: payload }, position);

    if (responseStatus === httpStatusCodes.OK && hasOddsBeenEdited) {
      handleToggleHasOddsBeenEdited();
      const calculatedProbability = oddsHelpers.getTotalProbability(oddsData?.odds || []);
      setHoldPercentage(calculatedProbability);
    }
  };

  const eventPositionHeaders: Column<any>[] = [
    // {
    //   title: 'Event Seed',
    //   field: 'athlete.seedNo',
    //   // render: (rowData: ModifiedEventOddPosition) => (
    //   //   <Typography>{`${rowData?.athlete?.seedNo || ''}`}</Typography>
    //   // ),
    //   editable: 'never',
    //   cellStyle: tableCellStyle,
    // },
    {
      title: 'Athlete',
      field: 'name',
      render: (rowData: ModifiedEventOddPosition) => (
        <>
          <Typography
            component="span"
            data-testid="athleteName"
            sx={{ ...headToHeadTableStyles.athleteNameStyle }}
          >
            {[rowData?.athlete?.firstName, rowData?.athlete?.middleName, rowData?.athlete?.lastName]
              .filter(Boolean)
              .join(' ')}
          </Typography>
          {(position === EVENT_WINNERS_POSITION && rowData.athlete.id === eventWinnerAthleteId) ||
          (position === FASTEST_LAP_POSITION && rowData.athlete.id === quickestAthleteId) ||
          ([RACE_TOP_3_POSITION, RACE_TOP_5_POSITION, RACE_TOP_10_POSITION].includes(position) &&
            rowData?.isTopXWinner) ? (
            <Box
              component="img"
              alt="winner icon"
              src={Winnerenabledicon}
              sx={{ ...headToHeadTableStyles.winnerIconsStyle }}
            ></Box>
          ) : (
            <></>
          )}
        </>
      ),
      editable: 'never',
      cellStyle: tableCellStyle,
    },
    // {
    //   title: 'Nationality',
    //   field: 'nationality',
    //   render: (rowData: ModifiedEventOddPosition) => (
    //     <Typography sx={tableCellStyle}>{`${rowData?.athlete?.nationality || ''}`}</Typography>
    //   ),
    //   editable: 'never',
    //   cellStyle: tableCellStyle,
    // },
    // {
    //   title: 'Stance',
    //   field: 'stance',
    //   render: (rowData: ModifiedEventOddPosition) => (
    //     <Typography sx={tableCellStyle}>{`${rowData?.athlete?.stance || ''}`}</Typography>
    //   ),
    //   editable: 'never',
    //   cellStyle: tableCellStyle,
    // },
    {
      title: 'Probability',
      field: 'probability',
      defaultSort: 'desc',
      customSort: (a: any, b: any) => +a?.probability - +b?.probability,
      align: 'left',
      validate: (row: ModifiedEventOddPosition) => {
        const totalPercentageModified = oddsHelpers.findTotalPercentage(
          modifiedEventOdds,
          row?.id,
          {
            modifiedRowsOnly: true,
          },
        );
        const newRemainingTotalProbability = holdPercentage - totalPercentageModified;
        return +row?.probability < 0
          ? 'Enter Probability'
          : +row?.probability > newRemainingTotalProbability ||
            +row?.probability > oddRanges.MAX_PROBABILITY
          ? `Probability can't be more than ${
              newRemainingTotalProbability < oddRanges.MAX_PROBABILITY
                ? round(newRemainingTotalProbability, 2)
                : oddRanges.MAX_PROBABILITY
            }`
          : true;
      },
      render: (rowData: any) => (
        <Typography data-testid="probability" sx={tableCellStyle}>
          {round(+rowData?.probability, 2)}
        </Typography>
      ),
      editComponent: (props: any) => (
        <TextField
          data-testid="probability"
          variant="outlined"
          value={round(+props?.value, 2)}
          type="number"
          inputProps={{
            step: '0.01',
          }}
          onBlur={(e) => {
            const probability = +e.target.value;
            const odds = oddsHelpers.getDecimalOddsFromProbability(probability);
            const decimalOdds = odds.toString();
            const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();
            const americanOdds =
              oddsHelpers.getAmericanOddsFromProbability(probability) > 0
                ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
                : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;
            props.onRowDataChange({
              ...props.rowData,
              probability,
              odds,
              decimalOdds,
              fractionalOdds,
              americanOdds,
            });
          }}
          onChange={(e: any) => {
            props.onRowDataChange({
              ...props.rowData,
              probability: +e.target.value,
            });
          }}
          error={props?.error}
          helperText={props?.helperText || ''}
          sx={editCellTextFieldStyle}
        />
      ),
      cellStyle: tableCellStyle,
    },
    {
      title: 'Decimal Odds',
      field: 'decimalOdds',
      hidden: ODDS_TYPES.DECIMAL === oddType ? false : true,
      customSort: (a: any, b: any) => +a?.probability - +b?.probability,
      validate: (row: any) => {
        const newValue = +row?.decimalOdds;
        return newValue < oddRanges.MIN_DECIMAL_ODDS && newValue !== 0
          ? {
              isValid: false,
              helperText: validationMessages.ODD_WINNERS_DECIMAL_ODDS_VALIDATION_MSG,
            }
          : newValue > oddRanges.MAX_DECIMAL_ODDS
          ? {
              isValid: false,
              helperText: validationMessages.DECIMAL_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (props: any) => (
        <>
          <DecimalField
            data-testid="decimalOdds"
            value={props.value}
            onBlur={(e: any) => {
              const odds = +e.target.value;
              const probability = oddsHelpers.getProbabilityFromDecimalOdds(odds);
              const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();
              const americanOdds =
                oddsHelpers.getAmericanOddsFromProbability(probability) > 0
                  ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
                  : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;

              props.onRowDataChange({
                ...props.rowData,
                probability,
                odds,
                decimalOdds: odds,
                fractionalOdds,
                americanOdds,
              });
            }}
            onChange={(e: any) => {
              props.onRowDataChange({
                ...props.rowData,
                odds: e.target.value,
                decimalOdds: e.target.value,
              });
            }}
            error={props?.error}
            helperText={props?.helperText || ''}
            thousandSeparator={false}
            decimalScale={2}
            sx={editCellTextFieldStyle}
          />
        </>
      ),
      cellStyle: tableCellStyle,
    },
    {
      title: 'Fractional Odds',
      field: 'fractionalOdds',
      hidden: ODDS_TYPES.FRACTIONAL === oddType ? false : true,
      customSort: (a: any, b: any) => +a?.probability - +b?.probability,
      validate: (row: any) => {
        const newValue = row?.fractionalOdds;
        const convertedOdd = oddsHelpers.decimalFromFraction(newValue);
        return !newValue.match(fractionPattern) && newValue !== '0'
          ? {
              isValid: false,
              helperText: validationMessages.FRACTION_ODDS_VALIDATION_MSG,
            }
          : convertedOdd > oddRanges.MAX_FRACTION_ODDS
          ? {
              isValid: false,
              helperText: validationMessages.FRACTION_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (props: any) => (
        <>
          <TextField
            data-testid="fractionalOdds"
            value={props.value}
            onBlur={(e: any) => {
              const odds = oddsHelpers.getDecimalOddsFromOtherTypes(oddType, e.target.value);
              const probability = oddsHelpers.getProbabilityFromDecimalOdds(odds);
              const fractionalOdds = e.target.value;
              const americanOdds =
                oddsHelpers.getAmericanOddsFromProbability(probability) > 0
                  ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
                  : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;

              props.onRowDataChange({
                ...props.rowData,
                probability,
                odds,
                decimalOdds: odds,
                fractionalOdds,
                americanOdds,
              });
            }}
            onChange={(e: any) => {
              props.onRowDataChange({
                ...props.rowData,
                fractionalOdds: e.target.value,
              });
            }}
            error={props?.error}
            helperText={props?.helperText || ''}
            sx={editCellTextFieldStyle}
          />
        </>
      ),
      cellStyle: tableCellStyle,
    },
    {
      title: 'American Odds',
      field: 'americanOdds',
      hidden: ODDS_TYPES.AMERICAN === oddType ? false : true,
      customSort: (a: any, b: any) => +a?.probability - +b?.probability,
      validate: (row: any) => {
        const newValue = +row?.americanOdds;
        return newValue > oddRanges.MAX_NEGATIVE_AMERICAN_ODDS &&
          newValue < oddRanges.MIN_AMERICAN_ODDS &&
          newValue !== 0
          ? {
              isValid: false,
              helperText: validationMessages.ODD_WINNERS_AMERICAN_ODDS_VALIDATION_MSG,
            }
          : newValue < oddRanges.MIN_NEGATIVE_AMERICAN_ODDS ||
            newValue > oddRanges.MAX_AMERICAN_ODDS
          ? {
              isValid: false,
              helperText: validationMessages.AMERICAN_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (props: any) => (
        <>
          <DecimalField
            data-testid="americanOdds"
            value={props.value}
            onBlur={(e: any) => {
              const odds = oddsHelpers.getDecimalOddsFromOtherTypes(oddType, +e.target.value);
              const probability = round(oddsHelpers.getProbabilityFromDecimalOdds(odds), 1);
              const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();

              props.onRowDataChange({
                ...props.rowData,
                probability,
                odds,
                decimalOdds: odds,
                fractionalOdds,
                americanOdds: +e.target.value > 0 ? `+${e.target.value}` : e.target.value,
              });
            }}
            onChange={(e: any) => {
              props.onRowDataChange({
                ...props.rowData,
                americanOdds: +e.target.value > 0 ? `+${e.target.value}` : e.target.value,
              });
            }}
            error={props?.error}
            helperText={props?.helperText || ''}
            thousandSeparator={false}
            decimalScale={0}
            allowNegative={true}
            sx={editCellTextFieldStyle}
          />
        </>
      ),
      cellStyle: tableCellStyle,
    },
  ];

  const MIN_HOLD_PERCENTAGE = React.useMemo(() => {
    if (modifiedEventOdds && modifiedEventOdds.length > 0) {
      let total = 0;
      modifiedEventOdds.map((data: any) => {
        total += +data?.trueProbability;
        return data;
      });
      return round(total);
    }
    return 100;
  }, [modifiedEventOdds]);

  // Max limit value from the default hold percentage
  // Note: Allowed scaling of vig to 2x the base hold for DGPT sport. ref [ASDTECH-571](https://altsportsdata.atlassian.net/browse/ASDTECH-571) for more details.
  const MAX_PERCENTAGE_LIMIT_VALUE = sport === SPORT_TYPES.DGPT ? 2 : 1.25;
  const MAX_ALLOWED_HOLD_PERCENTAGE_VALUE = MIN_HOLD_PERCENTAGE * MAX_PERCENTAGE_LIMIT_VALUE;

  const MAX_HOLD_PERCENTAGE = MIN_HOLD_PERCENTAGE * 2;

  const holdPercentagesOptions = React.useMemo(
    () =>
      Array(MAX_HOLD_PERCENTAGE - MIN_HOLD_PERCENTAGE + 1)
        .fill(0)
        .map((_, idx) => MIN_HOLD_PERCENTAGE + idx),
    [MIN_HOLD_PERCENTAGE, MAX_HOLD_PERCENTAGE],
  );

  const exportHandler = () => {
    const modData = JSON.parse(JSON.stringify(modifiedEventOdds || []));
    const { title, headers, csvData } = oddsToCSVFormatModifier({
      data: modData,
      oddDataType: ODD_DATA_TYPES.ODDS,
      position,
      oddType,
    });

    if (csvData.length > 0) {
      const eventInfo = {
        eventName: event?.name,
        year: event?.year,
        sport: SPORT_NAMES[sport],
        ...(event?.tour && { tour: event?.tour.name }),
        ...(event?.league && { league: event?.league.name }),
      };
      const lastItemUpdatedAt = oddsData?.traderUpdatedAtDate;
      const updatedAt = lastItemUpdatedAt
        ? format(parseISO(lastItemUpdatedAt), 'MM_dd_yyyy_HH_mm_aaa')
        : '';
      excelDownloader({ title, headers, csvData, updatedAt, ...eventInfo });
    }

    if (csvData.length === 0) enqueueSnackbar(`No Data Found for ${title}`);
  };

  const getWinnerAction = () => [
    (rowData: any) => ({
      icon: () => (
        <>
          {[RACE_TOP_3_POSITION, RACE_TOP_5_POSITION, RACE_TOP_10_POSITION].includes(position) &&
          rowData?.isTopXWinner ? (
            <></>
          ) : (
            <Box
              component="img"
              alt="select winner icon"
              src={SelectWinnerIcon}
              data-testid="selectWinnerBtn"
              sx={{ width: '1.25rem', imageRendering: '-webkit-optimize-contrast' }}
            />
          )}
        </>
      ),
      tooltip: 'Select as winner',
      onClick: (_: any, rowData: any) => {
        handleToggleSelectWinnerDialog(rowData);
      },
    }),
  ];

  if (!oddsData?.odds) return <EventListingSkeleton />;

  return (
    <Box>
      <OddMarketNotes
        eventId={eventId}
        sportType={sport}
        marketType={POSITION_NOTES_MAPPING[position as keyof {}]}
        note={oddsData?.notes || ''}
        refetchOdds={refetchOdds}
      />
      <OddMarketToggle
        eventId={eventId}
        sport={sport}
        marketType={POSITION_MARKET_TOGGLE_MAPPING[position as keyof {}]}
        isMarketOpen={Boolean(oddsData?.isMarketOpen)}
        refetchOdds={refetchOdds}
      />
      <Grid
        container
        alignItems={'center'}
        item
        xs={12}
        sx={{
          // mt: 4,
          mb: 2,
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { xs: 'flex-start', sm: 'space-between' },
          gap: { xs: '1rem', sm: 0 },
        }}
      >
        <Grid container alignItems={'center'} item xs={12} sm={8}>
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 600, color: 'info.main' }}>
            HOLD PERCENTAGE:
          </Typography>
          &nbsp;
          <Select
            id="selectHoldPercentage"
            value={holdPercentage}
            onChange={(event: SelectChangeEvent<typeof holdPercentage>) => {
              const {
                target: { value },
              } = event;
              const newHoldPercentage = Number(value);
              if (newHoldPercentage !== holdPercentage) {
                setModifiedEventOdds(
                  oddsHelpers.eventOddsPositionModifier({
                    eventOddsPosition: (modifiedEventOdds || []) as EventOddPosition[],
                    newHoldPercentage,
                    oldHoldPercentage: holdPercentage,
                    defaultHoldPercentage: MIN_HOLD_PERCENTAGE,
                  }),
                );
                setHoldPercentage(newHoldPercentage);
                if (!hasOddsBeenEdited) {
                  handleToggleHasOddsBeenEdited();
                }
              }
            }}
            sx={{ height: 30 }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
          >
            {React.Children.toArray(
              holdPercentagesOptions.map((percent) => (
                <MenuItem
                  value={percent}
                  disabled={
                    percent > MAX_ALLOWED_HOLD_PERCENTAGE_VALUE
                      ? // || !canSelectHoldPercentage[percent]
                        true
                      : false
                  }
                >
                  {percent}
                </MenuItem>
              )),
            )}
          </Select>
          &nbsp;&nbsp;
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontWeight: 600,
              color: 'neutral.main',
              marginLeft: '0.75rem',
            }}
          >
            MARGIN:
          </Typography>
          &nbsp;
          <Typography
            sx={{ fontSize: '0.875rem', fontWeight: 600, color: 'neutral.main' }}
            id="marginContainer"
          >
            {holdPercentage - MIN_HOLD_PERCENTAGE}%
          </Typography>
          &nbsp;
        </Grid>
        <Grid
          container
          alignItems={'center'}
          item
          xs={12}
          sm={4}
          sx={{ justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}
        >
          <ResetOddsBtn
            disabled={!hasOddsBeenEdited}
            resetOddsHandler={() => {
              handleToggleResetDialog();
            }}
          />
          <SaveOddsBtn
            disabled={!hasOddsBeenEdited}
            saveOddsHandler={() => {
              handleToggleConfirmDialog();
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="flex-start"
        item
        xs={12}
        sx={{ marginY: '1rem', display: 'flex', flexDirection: 'column' }}
      >
        <ExportBtn handleOnClick={exportHandler} />
        <PublishOdds
          clientUpdatedAtDate={oddsData?.clientUpdatedAtDate || ''}
          traderUpdatedAtDate={oddsData?.traderUpdatedAtDate || ''}
          traderUpdatedAtDates={oddsData?.traderUpdatedAtDate || null}
          handlePublishOdds={() => {
            handlePublishOdds(
              POSITION_PROJECTION_MAPPING[position as keyof {}],
              null,
              null,
              sport === SPORT_TYPES.F1 ? { position } : undefined,
            );
          }}
        />
      </Grid>

      <Box>
        <MaterialTable
          data={modifiedEventOdds || []}
          icons={tableIcons}
          columns={eventPositionHeaders}
          options={{
            toolbar: false,
            actionsColumnIndex: -1,
            // sorting: true,
            thirdSortClick: false,
            ...defaultTableOptions,
            pageSize: createTablePageOptions(modifiedEventOdds?.length || 0).pageSize,
            pageSizeOptions: createTablePageOptions(modifiedEventOdds?.length || 0).pageSizeOptions,
          }}
          editable={{
            isDeleteHidden: () => true,
            onRowUpdate: (oddRow: ModifiedEventOddPosition) => {
              return new Promise((resolve) => {
                setTimeout(() => {
                  const finalRows = oddsHelpers?.editRowFormatter(
                    { ...oddRow, hasModifiedProbability: true },
                    modifiedEventOdds,
                  );
                  saveOddsHandler(finalRows);
                  resolve('success');
                }, 1000);
              });
            },
            onRowDelete: () =>
              new Promise(() => {
                //
              }),
          }}
          localization={{
            header: {
              actions: '',
            },
          }}
          actions={
            (position === EVENT_WINNERS_POSITION && !eventWinnerAthleteId) ||
            (position === FASTEST_LAP_POSITION && !quickestAthleteId) ||
            [RACE_TOP_3_POSITION, RACE_TOP_5_POSITION, RACE_TOP_10_POSITION].includes(position)
              ? getWinnerAction()
              : []
          }
        />
      </Box>
      {showOddsUpdateDialog && (
        <ConfirmDialog
          open={showOddsUpdateDialog}
          handleClose={handleToggleConfirmDialog}
          handleConfirm={() => {
            handleToggleConfirmDialog();
            updateOddsData();
          }}
          title={`ARE YOU SURE YOU WANT TO UPDATE THE ODDS?`}
          body={'This will update the odds.'}
        />
      )}
      {showResetOddsDialog && (
        <ConfirmDialog
          open={showResetOddsDialog}
          handleClose={handleToggleResetDialog}
          handleConfirm={() => {
            handleToggleResetDialog();
            setInitialOddsData(oddsData?.odds as EventOddPosition[]);
          }}
          title={`ARE YOU SURE YOU WANT TO RESET THE ODDS?`}
          body={'This will reset the odds.'}
        />
      )}
      {showSelectWinnerDialog && (
        <ConfirmDialog
          open={showSelectWinnerDialog}
          handleClose={handleToggleSelectWinnerDialog}
          handleConfirm={() => {
            handleToggleSelectWinnerDialog();
            handleSelectWinner({
              athleteId: selectedAthlete?.athlete?.id as any,
              position,
            });
          }}
          title={`ARE YOU SURE YOU WANT TO MAKE ${[
            selectedAthlete?.athlete?.firstName,
            selectedAthlete?.athlete?.middleName,
            selectedAthlete?.athlete?.lastName,
          ]
            .filter(Boolean)
            .join(' ')} THE WINNER?`}
          body={'This action will select the winner.'}
        />
      )}
    </Box>
  );
};

export default OddsWinnerTab;
