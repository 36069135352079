import axios, { InternalAxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import config from '../config/vars';
import {
  getAuthToken,
  setAuthToken,
  removeAuthToken,
  PLAYER_URL,
} from '@/constants/localStorageKeys';
import * as firebaseHelpers from '@/hooks/firebase';
import { updateSocketAuthToken } from '@/utils/socketService';
// import { queryClient } from '@/react-query/queryClient';

const authenticatedAxios = axios.create({
  baseURL: `${config.apiBaseUrl}/api/v1/`,
  headers: { 'Content-Type': 'application/json' },
});

authenticatedAxios.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    // const token = localStorage.getItem('access_token') || 'temporary token';
    const token = getAuthToken();
    if (token && config.headers) {
      updateSocketAuthToken(token);
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

// Update utils/socketService.ts as well
const refreshAuthToken = async (failedRequest: any) => {
  try {
    const user = await firebaseHelpers.fetchCurrentUser();

    if (!user) {
      return Promise.reject(failedRequest);
      // return Promise.reject(new Error('User not found!'));
    }

    const token = await firebaseHelpers.fetchToken(user);
    setAuthToken(token);
    if (token) {
      failedRequest.response.config.headers.Authorization = `Bearer ${token}`;
    }
    return Promise.resolve();
  } catch (error) {
    localStorage.removeItem(PLAYER_URL);
    removeAuthToken();
    firebaseHelpers.logout();
    // queryClient.clear();
    window.location.href = window.location.host;
    return Promise.reject(error);
  }
};

createAuthRefreshInterceptor(authenticatedAxios, refreshAuthToken, {
  statusCodes: [403],
});

export { authenticatedAxios };
