import * as React from 'react';
import { Box, Button } from '@mui/material';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import v from 'voca';

import { teamTypeToKey, TEXT_COLOR } from '../constants';
import { getKeysWithObjectValues } from '@/helpers/misc';
import MarketToggleOption from './MarketToggleOption';
import { JAIALAIGameContext } from '@/contexts/jaialaiGame.context';
import {
  JABetTypesValues,
  JAMarketTypes,
  JAMarketTypesValue,
  JASubMarketTypesValuesMod,
} from '@/constants/jaialai';
import FeatureEnabled from '@/components/FeatureEnabled';
import { FEATURE_FLAGS } from '@/constants/featureFlags';
import SPORT_TYPES from '@/constants/sportTypes';

interface BetDetails {
  homeTeamValue: any;
  marketType: number;
  eventNumber: string;
  setName: string;
  points: number;
}

interface ToggleMarketProps {
  value: any;
  oddId: number;
  betId?: number;
  subMarket?: number;
  isMarketActive: boolean;
}

interface MarketTypesToggleProps {
  formik: any;
  disabledMarkets: { [key: string]: boolean };
  setDisabledMarkets: (markets: { [key: string]: boolean }) => void;
}

const TreeItemStyled = React.memo(({ nodeId, label, children, ...props }: any) => (
  <TreeItem
    nodeId={nodeId}
    label={label}
    sx={{
      '.Mui-focused': { backgroundColor: 'transparent' },
      '.MuiTreeItem-content:hover': { backgroundColor: 'transparent' },
      '.MuiTreeItem-content.Mui-selected': { backgroundColor: 'transparent' },
      '.MuiTreeItem-content': { padding: '0 0 0.2rem 0' },
    }}
    {...props}
  >
    {children}
  </TreeItem>
));

TreeItemStyled.displayName = 'TreeItemStyled';

const ActionButton = React.memo(({ onClick, disabled, color, label, id }: any) => (
  <Button
    variant="contained"
    disableElevation
    sx={{
      py: '4px',
      backgroundColor: color,
      color: TEXT_COLOR.WHITE,
      '&:hover': {
        color: TEXT_COLOR.WHITE,
        backgroundColor: color,
      },
    }}
    onClick={onClick}
    disabled={disabled}
    id={id}
  >
    {label}
  </Button>
));

ActionButton.displayName = 'ActionButton';

const MarketTypesToggle: React.FC<MarketTypesToggleProps> = ({ formik, disabledMarkets }) => {
  const { isDisabledMatch } = React.useContext(JAIALAIGameContext);
  const { values, setFieldValue } = formik;

  const toggleIsMarketActive = React.useCallback(
    ({ value, oddId, betId, subMarket, isMarketActive }: ToggleMarketProps) => {
      const teams = [teamTypeToKey.homeTeam, teamTypeToKey.awayTeam];

      const updateTeamMarketActive = (bet: number, subMarketVal: string | number, team: string) => {
        setFieldValue(
          `odds[${oddId}].bets[${bet}].${subMarketVal}.${team}.isMarketActive`,
          isMarketActive,
        );
      };

      if (subMarket) {
        teams.forEach((team) => updateTeamMarketActive(betId!, subMarket, team));
        return;
      }

      const processSubMarket = (bet: number, subMarketVal: string, isCustomBetIndex = false) => {
        const currentSubMarket = value?.bets[isCustomBetIndex ? 0 : bet]?.[subMarketVal];
        if (!currentSubMarket) return;

        teams.forEach((team) => {
          if (currentSubMarket[team]) {
            updateTeamMarketActive(bet, subMarketVal, team);
          }
        });
      };

      if (betId !== undefined) {
        const subMarkets = getKeysWithObjectValues(value.bets[0]) || [];
        subMarkets.forEach((subMarketVal) => processSubMarket(betId, subMarketVal, true));
        return;
      }

      value?.bets?.forEach((_: any, betNo: number) => {
        const subMarkets = getKeysWithObjectValues(value.bets[betNo]) || [];
        subMarkets.forEach((subMarketVal) => processSubMarket(betNo, subMarketVal));
      });
    },
    [setFieldValue],
  );

  const toggleAllMarkets = React.useCallback(
    (isMarketActive: boolean) => {
      values?.odds?.forEach((value: any, oddId: number) => {
        toggleIsMarketActive({ value, oddId, isMarketActive });
      });
    },
    [values, toggleIsMarketActive],
  );

  const useBetDetails = React.useCallback((bet: any): BetDetails => {
    const BET_COMMON_VALUE = bet[getKeysWithObjectValues(bet)?.[0]];
    const homeTeamValue = BET_COMMON_VALUE?.[teamTypeToKey.homeTeam];
    const marketType = +homeTeamValue?.marketType;

    return {
      homeTeamValue,
      marketType,
      eventNumber: homeTeamValue?.round?.eventNumber || '',
      setName: homeTeamValue?.round?.name || '',
      points: +homeTeamValue?.points || 1,
    };
  }, []);

  const betLabel = React.useCallback(
    (bet: any): string => {
      // DEPRECATED: Market, Bet and Sub Market code
      // const { marketType, eventNumber, setName, points } = useBetDetails(bet);
      const { marketType, eventNumber, setName } = useBetDetails(bet);

      if ([JAMarketTypes.SETS].includes(marketType)) {
        // const pointNumber = marketType === JAMarketTypes.SERVE ? `, POINT ${points}` : '';
        // return v.titleCase(`MATCH ${eventNumber}, SET ${setName}${pointNumber}`);
        return v.titleCase(`MATCH ${eventNumber}, SET ${setName}`);
      }
      return v.titleCase(JABetTypesValues[bet?.betType as keyof {}] || '');
    },
    [useBetDetails],
  );

  const defaultExpandedOptions = React.useMemo(
    () => [
      ...((values?.odds && values?.odds?.map((val: any) => val?.marketType)) || ''),
      ...((values?.odds &&
        values?.odds
          ?.map((val: any) => val.bets.map((bet: any) => `${val?.marketType}-${bet?.betType}`))
          .flat()) ||
        ''),
    ],
    [values?.odds],
  );

  const isSomeMarketDisabled = React.useMemo(
    () => Object.values(disabledMarkets).some(Boolean),
    [disabledMarkets],
  );

  const renderSubMarkets = React.useCallback(
    (value: any, bet: any, oddId: number, betId: number) =>
      Object.entries(bet).map(([subMktKey, subMkt]: any) => {
        if (typeof subMkt !== 'object') return null;

        return (
          <TreeItemStyled
            key={`${value?.marketType}-${bet?.betType}-${subMktKey}`}
            nodeId={`${value?.marketType}-${bet?.betType}-${subMktKey}`}
            label={
              <MarketToggleOption
                value={{
                  ...value,
                  bets: [{ betType: bet?.betType, [subMktKey]: subMkt }],
                }}
                oddId={oddId}
                betId={betId}
                subMarket={Number(subMktKey)}
                toggleIsMarketActive={toggleIsMarketActive}
                disabledMarkets={disabledMarkets}
                label={v.titleCase(JASubMarketTypesValuesMod[subMkt?.subMarketType as keyof {}])}
                currentBetLabel={betLabel(bet)}
              />
            }
          />
        );
      }),
    [toggleIsMarketActive, disabledMarkets, betLabel],
  );

  return (
    <Box sx={{ display: 'grid', gap: '0.1rem' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '0.5rem', mb: 2 }}>
        <ActionButton
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            toggleAllMarkets(true);
          }}
          disabled={isDisabledMatch || isSomeMarketDisabled}
          color="#15AA65"
          label="ACTIVATE ALL"
          id="activate-all-markets-btn"
        />
        <ActionButton
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            toggleAllMarkets(false);
          }}
          disabled={isDisabledMatch || isSomeMarketDisabled}
          color="#DC3545"
          label="SUSPEND ALL"
          id="suspend-all-markets-btn"
        />
      </Box>

      <FeatureEnabled
        isFeatureEnabled={FEATURE_FLAGS[SPORT_TYPES.JAIALAI].OVERALL_MARKET_SUSPENSION_CONTROL}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          {values.odds
            .filter((odd: any) => +odd?.marketType !== JAMarketTypes.SERVE)
            .map((value: any, oddId: number) => (
              <MarketToggleOption
                key={oddId}
                value={value}
                oddId={oddId}
                toggleIsMarketActive={toggleIsMarketActive}
                disabledMarkets={disabledMarkets}
                label={v.titleCase(JAMarketTypesValue[value?.marketType as keyof {}])}
              />
            ))}
        </Box>
      </FeatureEnabled>

      <FeatureEnabled
        isFeatureEnabled={FEATURE_FLAGS[SPORT_TYPES.JAIALAI].GRANULAR_MARKET_SUSPENSION_CONTROL}
      >
        <TreeView
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          defaultExpanded={defaultExpandedOptions}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.2rem' }}>
            {values.odds.map((value: any, oddId: number) => (
              <TreeItemStyled
                key={value.marketType}
                nodeId={value.marketType}
                label={
                  <MarketToggleOption
                    value={value}
                    oddId={oddId}
                    toggleIsMarketActive={toggleIsMarketActive}
                    disabledMarkets={disabledMarkets}
                    label={v.titleCase(JAMarketTypesValue[value?.marketType as keyof {}])}
                  />
                }
              >
                {value.bets.map((bet: any, betId: number) => (
                  <React.Fragment key={`${value?.marketType}-${bet?.betType}`}>
                    {/*  // DEPRECATED: Market, Bet and Sub Market code */}
                    {/* {+bet?.betType === JABetTypes.CORRECT_SETS ? (
                      <TreeItemStyled
                        nodeId={`${value?.marketType}-${bet?.betType}`}
                        label={
                          <MarketToggleOption
                            value={{ ...value, bets: [value?.bets[betId]] }}
                            oddId={oddId}
                            betId={betId}
                            toggleIsMarketActive={toggleIsMarketActive}
                            disabledMarkets={disabledMarkets}
                            label={betLabel(bet)}
                            currentBetLabel={betLabel(bet)}
                          />
                        }
                      />
                    ) : (
                      <TreeItemStyled
                        nodeId={`${value?.marketType}-${bet?.betType}`}
                        label={
                          <MarketToggleOption
                            value={{ ...value, bets: [value?.bets[betId]] }}
                            oddId={oddId}
                            betId={betId}
                            toggleIsMarketActive={toggleIsMarketActive}
                            disabledMarkets={disabledMarkets}
                            label={betLabel(bet)}
                            currentBetLabel={betLabel(bet)}
                          />
                        }
                      >
                        {renderSubMarkets(value, bet, oddId, betId)}
                      </TreeItemStyled>
                    )} */}
                    <TreeItemStyled
                      nodeId={`${value?.marketType}-${bet?.betType}`}
                      label={
                        <MarketToggleOption
                          value={{ ...value, bets: [value?.bets[betId]] }}
                          oddId={oddId}
                          betId={betId}
                          toggleIsMarketActive={toggleIsMarketActive}
                          disabledMarkets={disabledMarkets}
                          label={betLabel(bet)}
                          currentBetLabel={betLabel(bet)}
                        />
                      }
                    >
                      {renderSubMarkets(value, bet, oddId, betId)}
                    </TreeItemStyled>
                  </React.Fragment>
                ))}
              </TreeItemStyled>
            ))}
          </Box>
        </TreeView>
      </FeatureEnabled>
    </Box>
  );
};

export default MarketTypesToggle;
