export const MODEL_GENERATED_TYPES = {
  MODEL_GENERATED: 0,
  CUSTOM: 1,
  TRADER_UPDATED: 2,
  EXISTING_MATCHUP: 3,
};

export const MODEL_GENERATED_TEXT_AND_COLOR = {
  [MODEL_GENERATED_TYPES.CUSTOM]: {
    tooltipText:
      'This output did not exist in the model, so the odds need to be updated by someone on the trading team.',
    color: '#FFC107',
  },
  [MODEL_GENERATED_TYPES.TRADER_UPDATED]: {
    tooltipText:
      'Odds have been updated by trading team, but they don’t update automatically. Please monitor and edit if necessary',
    color: '#52BE80',
  },
  [MODEL_GENERATED_TYPES.EXISTING_MATCHUP]: {
    tooltipText: `This matchup already exists. Duplicate matchup's are not allowed.`,
    color: '#FFC107',
  },
};
