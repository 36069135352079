import React, { useState, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import MaterialTable from '@material-table/core';
import { parseISO } from 'date-fns';
import { Column } from '@material-table/core';
import { Typography, Box, Grid, Button, Tooltip, IconButton } from '@mui/material';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LastPageIcon from '@mui/icons-material/LastPage';

import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
// import { grey } from '@mui/material/colors';

import { oddsType } from '@/atoms/oddsType';

import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import ResetOddsBtn from '@/components/ResetOddsBtn';
import SaveOddsBtn from '@/components/SaveOddsBtn';
import ExportBtn from '@/components/ExportBtn';
import PublishOdds from '@/components/PublishOdds';

import useFetchParticipants from '@/hooks/athletes/useFetchParticipants';
import useFetchHeadToHeadOdds from '@/hooks/odds/headToHeadOdds/useFetchHeadToHeadOdds';
import useAddHeadToHeadOdds from '@/hooks/odds/headToHeadOdds/useAddHeadToHeadOdds';
import useUpdateHeadToHeadOdds from '@/hooks/odds/headToHeadOdds/useUpdateHeadToHeadOdds';
import useUpdateHeadToHeadOddsPayout from '@/hooks/odds/headToHeadOdds/useUpdateHeadToHeadOddsPayout';

import { initialDataLoader, headToHeadFreshWinnerDrawCount } from '@/helpers/playerHeadToHead';
import { excelDownloader } from '@/helpers/fileDownloader';
import { oddsToCSVFormatModifier } from '@/helpers/oddsToCSVFormatModifier';
import { invalidateHeadToHeadOdds } from '@/helpers/cachedQueries';
import { displayInPT as format } from '@/helpers/timeConverters';

import { ODDS_PROJECTION_TYPE } from '@/constants/oddsProjectionType';
import { ODD_DATA_TYPES } from '@/constants/oddDataTypes';
import * as httpStatusCodes from '@/constants/httpStatus';
import { SPORT_NAMES } from '@/constants/sportTypes';
import keys from '@/constants/queryKeys';

import { PlayerHeadToHeadOddRow } from '@/types/playerHeadToHeadTab';
import { UpdateOddsPayloadDTO } from '@/types/odds/updateOdds';
import { UpdateOddsPayoutPayloadDTO } from '@/types/odds/updateOddsPayout';
import { AddOddsPayloadDTO } from '@/types/odds/addOdds';

import {
  tableIcons,
  defaultTableOptions,
  createTablePageOptions,
  headToHeadTableStyles,
} from '@/utils/TableMisc';

import { generatePlayerHeadToHeadColumns } from './columns';
import AddEventHeadToHeadDialog from './AddEventHeadToHeadDialog';
import { HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS, filterObj } from '@/constants/misc';
import OddMarketNotes from '@/features/odds/Notes';
import { ODD_MARKET_NOTES, ODD_MARKET_TOGGLE_TYPE } from '@/constants/oddTabs';
import OddMarketToggle from '../../OddMarketToggle';
import useFetchHeadToHeadPossibleMatchups from '@/hooks/odds/headToHeadOdds/useFetchHeahToHeadPossibleMatchups';
import round from 'lodash.round';

const ConfirmSettleButton = styled(Button)({
  fontSize: '0.875rem',
  padding: '0.485rem 0.768rem',
  fontWeight: '400',
});

export type TOGGLE_OPTIONS_KEYS = 'WINNER' | 'VOIDED' | 'DRAW';
export type TOGGLE_OPTIONS_VALUES = 'winner' | 'voided' | 'draw';
export const TOGGLE_OPTIONS: Record<TOGGLE_OPTIONS_KEYS, TOGGLE_OPTIONS_VALUES> = {
  WINNER: 'winner',
  VOIDED: 'voided',
  DRAW: 'draw',
};
const DEFAULT_SORT_VALUE = { sortColumn: '', sortOrder: '' };

const PlayerHeadToHead = (props: any) => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState(DEFAULT_SORT_VALUE);
  const { sport, eventId, handlePublishOdds } = props;
  const { data: athletes } = useFetchParticipants(sport, eventId);
  const headToHeadOddsKey: {
    sport: string;
    eventId: string;
    params?: { query: string; page: number; sortColumn: string; sortOrder: string };
  } = { sport, eventId };
  if (HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)) {
    headToHeadOddsKey.params = { page, query, ...sort };
  }
  const { data: oddsData } = useFetchHeadToHeadOdds({ ...headToHeadOddsKey });
  const { data: possibleMatchupsData } = useFetchHeadToHeadPossibleMatchups({ eventId, sport });
  const [tableData, setTableData] = useState<any>([]);
  const [listOfToggles, setListOfToggles] = useState<any>([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [hasOddsBeenEdited, setHasOddsBeenEdited] = useState(false);
  const [showOddsUpdateDialog, setShowOddsUpdateDialog] = useState(false);
  const [showResetOddsDialog, setShowResetOddsDialog] = useState(false);
  const [isConfirmVoidDialogOpen, setIsConfirmVoidDialogOpen] = useState(false);
  const [tempVoidedRowId, setTempVoidedRowId] = useState('');
  const [oddType] = useRecoilState(oddsType);
  const { mutateAsync: addHeadToHeadOdds } = useAddHeadToHeadOdds();
  const { mutateAsync: updateHeadToHeadOdds } = useUpdateHeadToHeadOdds();
  const { mutateAsync: updateHeadToHeadOddsPayout } = useUpdateHeadToHeadOddsPayout();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const event: any = queryClient.getQueryData([keys.events.fetchEvent, sport, eventId, 'Event']);

  const playerDropdownOptions = useMemo(() => {
    const modifiedAthletes = athletes
      ? athletes?.map((player: any) => {
          return {
            label: [
              player?.athlete?.firstName,
              player?.athlete?.middleName,
              player?.athlete?.lastName,
            ]
              .filter(Boolean)
              .join(' '),
            eventParticipantId: player?.id || '',
            athleteId: player?.athlete?.id || '',
            ...player.athlete,
          };
        })
      : [];
    return modifiedAthletes;
  }, [JSON.stringify(athletes)]);

  const possibleMatchups = useMemo(() => {
    const matchupMap: Map<string, string> = new Map();

    possibleMatchupsData?.forEach(({ matchupId, athleteIds }) => {
      // Create a key from the athlete IDs
      const originalKey = [...athleteIds].join('-');

      // Create a reversed key without mutating the original array
      const reversedKey = [...athleteIds].reverse().join('-');

      // Set both keys in the map
      matchupMap.set(originalKey, matchupId);
      matchupMap.set(reversedKey, matchupId);
    });

    return matchupMap;
  }, [possibleMatchupsData]);

  const existingMatchups = useMemo(() => {
    const matchupMap: Map<string, string> = new Map();
    if (oddsData?.odds && oddsData?.odds?.length > 0)
      oddsData?.odds?.forEach(({ id, eventParticipant1, eventParticipant2 }: any) => {
        // Create a key from the athlete IDs
        const originalKey = [eventParticipant1?.athlete?.id, eventParticipant2?.athlete?.id].join(
          '-',
        );

        // Create a reversed key without mutating the original array
        const reversedKey = [eventParticipant1?.athlete?.id, eventParticipant2?.athlete?.id]
          .reverse()
          .join('-');

        // Set both keys in the map
        matchupMap.set(originalKey, id);
        matchupMap.set(reversedKey, id);
      });

    return matchupMap;
  }, [oddsData]);

  const handleToggleSaveDialog = () => setShowOddsUpdateDialog(!showOddsUpdateDialog);
  const handleToggleResetDialog = () => setShowResetOddsDialog(!showResetOddsDialog);
  const handleToggleHasOddsBeenEdited = () => setHasOddsBeenEdited(!hasOddsBeenEdited);
  const handleToggleConfirmVoidDialog = () => setIsConfirmVoidDialogOpen(!isConfirmVoidDialogOpen);
  const handleResetTempVoidRowId = () => setTempVoidedRowId('');

  const setInitialOddsData = (reset = false) => {
    if (oddsData?.odds && oddsData?.odds.length > 0) {
      const playerHeadToHeadsCopy = JSON.parse(JSON.stringify(oddsData?.odds));
      const tableDataCopy = JSON.parse(JSON.stringify(tableData));
      let resetWinnerVoidValues = reset ? true : false;
      if (!reset) {
        // Initial data loading
        if (tableData.length === 0) resetWinnerVoidValues = true;
      }

      const newHeadToHeads = initialDataLoader({
        newHeadToHeads: playerHeadToHeadsCopy,
        currentHeadToHeads: tableDataCopy,
        resetToggleValues: resetWinnerVoidValues,
      });

      setTableData(newHeadToHeads);
    }
  };

  const updateTableData = (rowData: any) => {
    const modPlayerHeadToHeads = JSON.parse(JSON.stringify(tableData)).map((tableRow: any) =>
      tableRow.id === rowData.id ? rowData : tableRow,
    );
    setTableData(modPlayerHeadToHeads);

    if (!hasOddsBeenEdited) handleToggleHasOddsBeenEdited();
  };

  const refetchOdds = () => {
    invalidateHeadToHeadOdds(
      queryClient,
      sport,
      eventId,
      HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)
        ? filterObj({ page, query, ...sort })
        : undefined,
    );
  };

  const removeHeadToHeadOdds = () => {
    queryClient.removeQueries({
      queryKey: [
        keys.odds.fetchHeadToHeadOdds,
        sport,
        eventId,
        HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)
          ? filterObj({ page, query, ...sort })
          : undefined,
      ],
      exact: true,
    });
  };

  React.useEffect(() => {
    refetchOdds();
  }, [eventId]);

  React.useEffect(() => {
    setInitialOddsData();
  }, [JSON.stringify(oddsData)]);

  const [showAddMatchUpPopup, setAddMatchUpPopUp] = useState(false); // Handle winner toggle.

  const handleToggleChange = ({
    checked,
    type,
    rowMatchUpId,
    winnerId,
  }: {
    checked: boolean;
    type: TOGGLE_OPTIONS_VALUES;
    rowMatchUpId: string;
    winnerId?: string;
  }) => {
    findAndUpdateTableEntry({ type, rowMatchUpId, checked, winnerId });

    if (type !== TOGGLE_OPTIONS.VOIDED) {
      const updatedListOfToggles = [...listOfToggles];
      const index = updatedListOfToggles.findIndex((row) => row.id === rowMatchUpId);
      if (checked) {
        const key = type === TOGGLE_OPTIONS.WINNER ? 'eventParticipantWinnerId' : type;
        const value = type === TOGGLE_OPTIONS.WINNER ? winnerId : checked;
        if (index !== -1) {
          updatedListOfToggles[index][key] = value;
        } else {
          updatedListOfToggles.push({
            id: rowMatchUpId,
            [key]: value,
          });
        }
      } else {
        if (index !== -1) updatedListOfToggles.splice(index, 1);
      }
      setListOfToggles(updatedListOfToggles);
    }
  };

  const findAndUpdateTableEntry = ({
    type,
    rowMatchUpId = '',
    checked = false,
    winnerId = null,
  }: {
    type: TOGGLE_OPTIONS_VALUES;
    rowMatchUpId: string;
    checked: boolean;
    winnerId?: string | null;
  }) => {
    const updatedTableData = [...tableData]; // Find index of table entry.
    const tableEntryIndex = updatedTableData.findIndex(
      (tableEntry: any) => tableEntry.id === rowMatchUpId,
    ); // Update table entry to toggle value
    if (tableEntryIndex !== -1 && type === TOGGLE_OPTIONS.WINNER) {
      updatedTableData[tableEntryIndex].newWinnerId = checked ? winnerId : '';
    } else if (tableEntryIndex !== -1 && type === TOGGLE_OPTIONS.VOIDED) {
      updatedTableData[tableEntryIndex].editedVoid = checked;
    } else if (tableEntryIndex !== -1 && type === TOGGLE_OPTIONS.DRAW) {
      updatedTableData[tableEntryIndex].editedDraw = checked;
    }
    setTableData(updatedTableData);
  };

  const addHeadToHeadOddsData = async (
    payload: AddOddsPayloadDTO,
    message = 'Added Event Matchup Successfully',
  ) => {
    const response = await addHeadToHeadOdds(
      { sport, eventId, payload },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          refetchOdds();
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  const updateHeadToHeadOddsData = async ({
    payload,
    message = 'Updated Event Matchup Successfully',
  }: {
    payload: UpdateOddsPayloadDTO;
    message?: string;
  }) => {
    const response = await updateHeadToHeadOdds(
      { sport, eventId, payload },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          refetchOdds();
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  const handleHeadToHeadConfirmVoid = async (
    payload: UpdateOddsPayoutPayloadDTO,
    message = 'Toggled Void Successfully!',
  ) => {
    const response = await updateHeadToHeadOddsPayout(
      { sport, eventId, payload },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          refetchOdds();
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  const handleHeadToHeadConfirmAndSettle = async (
    payload: UpdateOddsPayoutPayloadDTO,
    message = 'Payouts updated Successfully!',
  ) => {
    const response = await updateHeadToHeadOddsPayout(
      { sport, eventId, payload },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          refetchOdds();
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  const handleToggleVoid = async () => {
    handleToggleConfirmVoidDialog();
    const payload = {
      items: [
        {
          id: tempVoidedRowId,
          voided: !tableData.find((row: any) => row.id === tempVoidedRowId)?.voided,
        },
      ],
    };
    const responseStatus = await handleHeadToHeadConfirmVoid({ items: payload.items });

    if (responseStatus === httpStatusCodes.OK) {
      handleResetTempVoidRowId();
    }
  };

  const handleConfirmAndSettle = async () => {
    handleToggleConfirmDialog(); // Close confirm dialog/modal.
    const responseStatus = await handleHeadToHeadConfirmAndSettle({ items: listOfToggles });
    if (responseStatus === httpStatusCodes.OK) {
      setListOfToggles([]);
      if (hasOddsBeenEdited) handleToggleHasOddsBeenEdited();
    }
  };

  const handleToggleConfirmDialog = () => setIsConfirmDialogOpen(!isConfirmDialogOpen);

  const holdPercentagesOptions = Array(200 - 100 + 1)
    .fill(0)
    .map((_, idx) => 100 + idx);

  const updatePlayerHeadToHeadHandler = async () => {
    const payload = tableData.map((tableRow: any) => {
      return {
        id: tableRow.id,
        player1HasModifiedProbability: tableRow.eventParticipant1.hasModifiedProbability,
        player2HasModifiedProbability: tableRow.eventParticipant2.hasModifiedProbability,
        player1Probability: round(+tableRow.eventParticipant1.probability || 0, 2),
        player2Probability: round(+tableRow.eventParticipant2.probability || 0, 2),
        player1Odds: round(+tableRow.eventParticipant1.odds || 0, 2),
        player2Odds: round(+tableRow.eventParticipant2.odds || 0, 2),
        holdingPercentage: round(+tableRow.holdingPercentage || 0),
        player1Position: +tableRow.eventParticipant1.position,
        player2Position: +tableRow.eventParticipant2.position,
      };
    });
    await updateHeadToHeadOddsData({ payload: { items: payload } });
  };

  const exportHandler = () => {
    const modData = JSON.parse(JSON.stringify(tableData || []));
    const { title, headers, csvData } = oddsToCSVFormatModifier({
      data: modData,
      oddDataType: ODD_DATA_TYPES.HEAD_TO_HEAD,
      oddType,
    });

    if (csvData.length > 0) {
      const eventInfo = {
        eventName: event?.name,
        year: event?.year,
        sport: SPORT_NAMES[sport],
        ...(event?.tour && { tour: event?.tour.name }),
        ...(event?.league && { league: event?.league.name }),
      };
      const lastItemUpdatedAt = oddsData?.traderUpdatedAtDate;
      const updatedAt = lastItemUpdatedAt
        ? format(parseISO(lastItemUpdatedAt), 'MM_dd_yyyy_HH_mm_aaa')
        : '';
      excelDownloader({ title, headers, csvData, updatedAt, ...eventInfo });
    }

    if (csvData.length === 0) enqueueSnackbar(`No Data Found for ${title}`);
  };

  const columns: Column<PlayerHeadToHeadOddRow>[] = generatePlayerHeadToHeadColumns({
    updateTableData,
    holdPercentagesOptions,
    handleToggleChange,
    oddType,
    setTempVoidedRowId,
    handleToggleConfirmVoidDialog,
    sport,
  });

  const handlePageChange = (val: number) => {
    removeHeadToHeadOdds();
    if (val === Number.NEGATIVE_INFINITY) {
      setPage(1);
    }
    if (val === Number.POSITIVE_INFINITY) {
      setPage(oddsData?.total || 1);
    }
    if (val === -1 || val === 1) {
      setPage((currentPage) => currentPage + val);
    }
  };

  return (
    <Box>
      <OddMarketNotes
        eventId={eventId}
        sportType={sport}
        marketType={ODD_MARKET_NOTES.HEAD_TO_HEAD_NOTES}
        note={oddsData?.notes || ''}
        refetchOdds={refetchOdds}
      />
      <OddMarketToggle
        eventId={eventId}
        sport={sport}
        marketType={ODD_MARKET_TOGGLE_TYPE.IS_PLAYER_HEAD_TO_HEAD_OPEN}
        isMarketOpen={oddsData?.isMarketOpen as boolean}
        refetchOdds={refetchOdds}
      />
      <Box sx={{ marginBottom: '0.875rem' }} display="flex" justifyContent="flex-end">
        <ResetOddsBtn disabled={!hasOddsBeenEdited} resetOddsHandler={handleToggleResetDialog} />
        <SaveOddsBtn disabled={!hasOddsBeenEdited} saveOddsHandler={handleToggleSaveDialog} />
      </Box>
      <Box sx={{ marginBottom: '1rem', marginTop: '1rem' }}>
        <Typography sx={{ fontSize: '0.875rem', fontWeight: '400', color: 'info.main' }}>
          Odds for two athletes to finish the contest in a better position. For example, if Athlete
          1 finishes the contest in 3rd place and Athlete 2 finishes in 12th place, Athlete 1 wins.
        </Typography>
      </Box>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        item
        xs={12}
        sx={{ marginY: '1rem', gap: { xs: '1rem', sm: 0 } }}
      >
        <Grid container alignItems={'flex-start'} item xs={12} sm={6}>
          <ExportBtn handleOnClick={exportHandler} />
          <PublishOdds
            clientUpdatedAtDate={oddsData?.clientUpdatedAtDate || ''}
            traderUpdatedAtDate={oddsData?.traderUpdatedAtDate || ''}
            traderUpdatedAtDates={oddsData?.traderUpdatedAtDate || null}
            handlePublishOdds={() => {
              handlePublishOdds(
                ODDS_PROJECTION_TYPE.headToHeadProjections,
                undefined,
                undefined,
                HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)
                  ? filterObj({ page, query, ...sort })
                  : undefined,
              );
            }}
          />
        </Grid>
        <ConfirmSettleButton
          id="confirmAndSettleBtn"
          disableElevation
          variant="contained"
          onClick={handleToggleConfirmDialog}
          disabled={headToHeadFreshWinnerDrawCount(tableData) === 0 ? true : false}
        >
          Confirm and settle
        </ConfirmSettleButton>
      </Grid>
      <Box>
        <MaterialTable
          icons={tableIcons}
          columns={columns}
          data={tableData}
          options={{
            // rowStyle: (data) => {
            //   return { backgroundColor: data.voided ? grey[300] : '' };
            // },
            actionsColumnIndex: -1,
            ...defaultTableOptions,
            pageSize: createTablePageOptions(tableData?.length || 0, true).pageSize,
            pageSizeOptions: createTablePageOptions(tableData?.length || 0, true).pageSizeOptions,
            thirdSortClick: false,
            ...(HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)
              ? { showTitle: false, searchDebounceDelay: 10 }
              : { toolbar: false }),
          }}
          editable={{
            isDeleteHidden: () => true,
            isEditable: (rowData: any) =>
              ![rowData.winnerParticipantId, rowData.voided, rowData.draw].some(Boolean),
            onRowUpdate: (newData: any) =>
              new Promise((resolve) => {
                updateTableData(newData);
                resolve('success');
              }),
            onRowDelete: () =>
              new Promise(() => {
                //
              }),
          }}
          localization={{
            header: {
              actions: '',
            },
            toolbar: { searchPlaceholder: 'Search By Athlete Name' },
          }}
          components={{
            Pagination: () => (
              <td>
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: '0.5rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box>
                    <Button
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setAddMatchUpPopUp(true);
                      }}
                      color="primary"
                      id="addNewMatchupBtn"
                    >
                      Add a new matchup
                    </Button>
                  </Box>
                  {HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport) && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                      <Typography sx={{ ...headToHeadTableStyles.athleteNameStyle }}>
                        {`${oddsData?.page || '-'}-${oddsData?.total || '-'} of ${
                          oddsData?.total || '-'
                        }`}
                      </Typography>
                      <Box sx={{ display: 'flex', gap: '0.7rem', alignItems: 'center' }}>
                        <Tooltip title="First Page" placement="bottom">
                          <span>
                            <IconButton
                              id="firstPgBtn"
                              disabled={
                                oddsData?.page === 1 || oddsData?.total === 0 ? true : false
                              }
                              onClick={() => handlePageChange(Number.NEGATIVE_INFINITY)}
                            >
                              <FirstPageIcon sx={{ fontSize: '1.5rem', color: '#BDBDBD' }} />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Previous Page" placement="bottom">
                          <span>
                            <IconButton
                              id="prevPgBtn"
                              disabled={
                                oddsData?.page === 1 || oddsData?.total === 0 ? true : false
                              }
                              onClick={() => handlePageChange(-1)}
                            >
                              <ArrowBackIosIcon sx={{ fontSize: '1rem', color: '#BDBDBD' }} />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Next Page" placement="bottom">
                          <span>
                            <IconButton
                              id="nextPgBtn"
                              disabled={
                                oddsData?.page === oddsData?.total || oddsData?.total === 0
                                  ? true
                                  : false
                              }
                              onClick={() => handlePageChange(1)}
                            >
                              <ArrowForwardIosIcon sx={{ fontSize: '1rem', color: '#BDBDBD' }} />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Last Page" placement="bottom">
                          <span>
                            <IconButton
                              id="lastPgBtn"
                              disabled={
                                oddsData?.page === oddsData?.total || oddsData?.total === 0
                                  ? true
                                  : false
                              }
                              onClick={() => handlePageChange(Number.POSITIVE_INFINITY)}
                            >
                              <LastPageIcon sx={{ fontSize: '1.5rem', color: '#BDBDBD' }} />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>
                    </Box>
                  )}
                </Grid>
              </td>
            ),
          }}
          onOrderCollectionChange={
            HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)
              ? (orderByCollection) => {
                  const [firstColumn] = orderByCollection;
                  if (columns && firstColumn) {
                    const column = columns[firstColumn.orderBy];

                    const sortFieldName = column?.field
                      ? `player${column?.field.includes('eventParticipant1.') ? 1 : 2}${
                          column?.field.includes('Odds') ? 'Odds' : 'Probability'
                        }`
                      : '';
                    removeHeadToHeadOdds();
                    setSort({
                      sortColumn: sortFieldName,
                      sortOrder: firstColumn?.orderDirection?.toUpperCase() || 'ASC',
                    });
                    setPage(1);
                  }
                }
              : undefined
          }
          onSearchChange={
            HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)
              ? (searchText: string) => {
                  removeHeadToHeadOdds();
                  setQuery(searchText);
                  setPage(1);
                }
              : undefined
          }
        />
      </Box>

      {showAddMatchUpPopup && (
        <AddEventHeadToHeadDialog
          open={showAddMatchUpPopup}
          handleClose={() => setAddMatchUpPopUp(false)}
          playerDropdownOptions={playerDropdownOptions}
          addHeadToHeadOddsData={addHeadToHeadOddsData}
          possibleMatchups={possibleMatchups}
          existingMatchups={existingMatchups}
        />
      )}
      {isConfirmDialogOpen && (
        <ConfirmDialog
          open={isConfirmDialogOpen}
          handleClose={handleToggleConfirmDialog}
          handleConfirm={handleConfirmAndSettle}
          title={'ARE YOU SURE YOU WANT TO SETTLE ALL WINNERS/ DRAWS MATCH UPS?'}
          body={
            'This will establish the winners/ draws and will require voiding if there are any errors. Make sure these are all correct.'
          }
        />
      )}
      {isConfirmVoidDialogOpen && (
        <ConfirmDialog
          open={isConfirmVoidDialogOpen}
          handleClose={() => {
            handleToggleConfirmVoidDialog();

            handleToggleChange({
              checked: tableData.find((row: any) => row.id === tempVoidedRowId)?.voided,
              type: TOGGLE_OPTIONS.VOIDED,
              rowMatchUpId: tempVoidedRowId,
            });
            handleResetTempVoidRowId();
          }}
          handleConfirm={handleToggleVoid}
          title={`ARE YOU SURE YOU WANT TO ${
            tableData.find((row: any) => row.id === tempVoidedRowId)?.voided ? 'UNVOID' : 'VOID'
          }  HEAD TO HEAD?`}
          body={`This will ${
            tableData.find((row: any) => row.id === tempVoidedRowId)?.voided ? 'unvoid' : 'void'
          } the head to  head.`}
        />
      )}
      {showOddsUpdateDialog && (
        <ConfirmDialog
          open={showOddsUpdateDialog}
          handleClose={handleToggleSaveDialog}
          handleConfirm={() => {
            handleToggleSaveDialog();
            handleToggleHasOddsBeenEdited();
            updatePlayerHeadToHeadHandler();
          }}
          title={`ARE YOU SURE YOU WANT TO UPDATE THE ODDS?`}
          body={'This will update the odds.'}
        />
      )}
      {showResetOddsDialog && (
        <ConfirmDialog
          open={showResetOddsDialog}
          handleClose={handleToggleResetDialog}
          handleConfirm={() => {
            handleToggleResetDialog();
            handleToggleHasOddsBeenEdited();
            setInitialOddsData(true);
          }}
          title={`ARE YOU SURE YOU WANT TO RESET THE ODDS?`}
          body={'This will reset the odds.'}
        />
      )}
    </Box>
  );
};

export default PlayerHeadToHead;
