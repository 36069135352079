import round from 'lodash.round';

import * as oddsHelpers from '@/helpers/odds';
import { PlayerHeadToHeadOddRow } from '@/types/playerHeadToHeadTab';

export const initialDataLoader = ({
  newHeadToHeads,
  currentHeadToHeads,
  resetToggleValues,
}: {
  newHeadToHeads: PlayerHeadToHeadOddRow[];
  currentHeadToHeads: PlayerHeadToHeadOddRow[];
  resetToggleValues: boolean;
}) => {
  const currentHeadToHeadMap = new Map(currentHeadToHeads.map((head) => [head.id, head]));

  const formatParticipant = (participant?: any) => {
    if (!participant) return {};
    const { id: eventParticipantId, position = '--', odds, probability, athlete } = participant;
    return {
      ...participant,
      eventParticipantId,
      position,
      athleteId: athlete?.id || '',
      label: [athlete?.firstName, athlete?.middleName, athlete?.lastName].filter(Boolean).join(' '),
      decimalOdds: round(odds, 2),
      fractionalOdds: oddsHelpers.getFractionalOdds(odds).toString(),
      americanOdds: oddsHelpers.getAmericanOddsFromProbability(probability).toString(),
      probability: round(probability, 2),
    };
  };

  return newHeadToHeads.map((head) => {
    const existingRow = currentHeadToHeadMap.get(head.id) || null;

    const currentData = !resetToggleValues ? existingRow : null;

    const baseData = {
      ...head,
      newWinnerId: resetToggleValues
        ? head.winnerParticipantId || ''
        : currentData?.newWinnerId || '',
      editedVoid: resetToggleValues ? head.voided || false : currentData?.editedVoid || false,
      editedDraw: resetToggleValues ? head.draw || false : currentData?.editedDraw || false,
    };

    const participantData = currentData
      ? {
          eventParticipant1: currentData?.eventParticipant1,
          eventParticipant2: currentData?.eventParticipant2,
          holdingPercentage: round(
            (currentData.eventParticipant1?.probability || 0) +
              (currentData.eventParticipant2?.probability || 0),
          ),
        }
      : {
          eventParticipant1: formatParticipant(head.eventParticipant1),
          eventParticipant2: formatParticipant(head.eventParticipant2),
          holdingPercentage: round(
            (head.eventParticipant1?.probability || 0) + (head.eventParticipant2?.probability || 0),
          ),
        };

    return {
      ...baseData,
      ...participantData,
    };
  });
};

export const sortPlayerHeadToHeads = (headToHeads: PlayerHeadToHeadOddRow[]) => {
  return headToHeads.sort((a: any, b: any) => {
    const rowACanEdit = !a.voided && !a.winnerParticipantId;
    const rowBCanEdit = !b.voided && !b.winnerParticipantId;
    return rowACanEdit === rowBCanEdit ? 0 : rowACanEdit ? -1 : 1;
  });
};

export const headToHeadFreshWinnerDrawCount = (rows: any) => {
  if (!rows || (rows && rows.length === 0)) return 0;
  const payoutCount = rows.reduce((prevValue: number, row: any) => {
    if ((!row?.winnerParticipantId && row.newWinnerId) || (!row?.draw && row.editedDraw))
      return ++prevValue;
    else return prevValue;
  }, 0);
  return payoutCount;
};
