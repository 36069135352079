import React, { useEffect, useState, useMemo } from 'react';
import { useSnackbar, SnackbarKey, SnackbarMessage } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { fetchEventSource } from '@microsoft/fetch-event-source';

import { Tab, Box, styled, Button, Typography } from '@mui/material';
import { TabPanel, TabList, TabContext } from '@mui/lab';
import { ThemeProvider } from '@mui/material/styles';

import usePublishOdds from '@/hooks/odds/publishOdds/usePublishOdds';
import { refreshAuthToken } from '@/hooks/firebase';

import CustomSnackbar from '@/components/CustomSnackbar';

import { displayInPT as format } from '@/helpers/timeConverters';

import config from '@/config/vars';

import {
  OddsWinnerTab,
  HeatWinnerTab,
  FightWinnerTab,
  PlayerHeadToHead,
  PropBetsOddsTab,
  ShowsAndPodiumsOddsTab,
  DreamTeamTab,
  ExactasEventTab,
  ExactasHeatTab,
} from '@/features/odds/Tabs';

import { oddsTablesTheme } from '@/constants/theme';
import { getAuthToken } from '@/constants/localStorageKeys';
import { ODDS_PROJECTION_TYPE } from '@/constants/oddsProjectionType';
import { SSE } from '@/constants/sse';
import { CLOSE_REASON } from '@/constants/closeReason';
import oddTabs, {
  ODD_TAB_LABELS,
  EXACTAS_TYPE,
  FASTEST_LAP_POSITION,
  PROJECTION_POSITION_MAPPING,
  RACE_TOP_3_POSITION,
  RACE_TOP_5_POSITION,
  RACE_TOP_10_POSITION,
} from '@/constants/oddTabs';
import { SHOW_OR_PODIUM } from '@/constants/showOrPodium';
import {
  EVENT_TAB_QUERY_PARAMS,
  HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS,
} from '@/constants/misc';
import { EVENT_WINNERS_POSITION, EVENT_SECOND_POSITION } from '@/constants/oddTabs';

import OddTypeSwitcher from '../OddTypeSwitcher';

import { IEvent } from '@/types/newTypes/event';

import {
  invalidateHeadToHeadOdds,
  invalidateHeatWinnerOdds,
  invalidatePodiumOdds,
  invalidatePositionalOdds,
  invalidatePropBetOdds,
  invalidateShowOdds,
  invalidateDreamTeamOdds,
  invalidateExactasEventOdds,
  invalidateExactasHeatOdds,
  invalidateCachedSports,
  invalidateCachedEvent,
} from '@/helpers/cachedQueries';
import { useLocation, useNavigate } from 'react-router-dom';
import useSelectWinner from '@/hooks/odds/selectWinner/useSelectWinner';
import SPORT_TYPES from '@/constants/sportTypes';
import RaceExactasTab from '../Tabs/Exactas/RaceExactasTab';

const MuiTabs = styled(TabList)({
  '& .MuiTabs-root': {
    backgroundColor: 'red',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#ffff',
  },
});

const MuiTab = styled(Tab)({
  '&.MuiTab-root': {
    fontWeight: 'bold',
    fontSize: '0.8rem',
    color: '#6C757D',
    backgroundColor: '#F1F2F4',
    height: '3.5rem',
    textTransform: 'none',
  },
  '&.Mui-selected': {
    color: `#0D6EFD`,
    backgroundColor: '#ffffff',
    position: 'relative',
    zIndex: 20,
    border: '5px solid #F1F2F4',
    borderRadius: '4px',
  },
  '&.MuiTab-wrapped': {
    textTransform: 'none',
  },
});

const MuiTabPanel = styled(TabPanel)({
  padding: '1rem 0',
  maxWidth: '100%',
});

const MuiTabLabelText = styled(Typography)({
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  fontWeight: 500,
});
const MuiTabLabelDate = styled(Typography)({
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
});

interface ODDS_PROPS {
  sport: string;
  event?: IEvent;
}

export default function Odds({ sport, event }: ODDS_PROPS) {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const odd = queryParams.get(EVENT_TAB_QUERY_PARAMS.odd);

  const [value, setValue] = useState('1');
  const queryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { mutate: publishOdds } = usePublishOdds();
  const { mutate: selectWinner } = useSelectWinner();
  const eventId = event?.id;

  const tabs = React.useMemo(() => oddTabs[sport as keyof typeof oddTabs], [sport]);

  React.useEffect(() => {
    if (!odd && tabs) {
      const redirectParams = new URLSearchParams(location.search);
      redirectParams.set(EVENT_TAB_QUERY_PARAMS.odd, tabs?.[0]?.queryStr || '');
      navigate(
        {
          pathname: location.pathname,
          search: redirectParams.toString(),
        },
        { replace: true },
      );
    }
  }, [odd, tabs]);

  React.useEffect(() => {
    const selectedTab = tabs?.find((tab) => tab.queryStr === odd);
    setValue(selectedTab?.value || tabs?.[0]?.value || '1');
  }, [eventId, odd, tabs]);

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    const redirectParams = new URLSearchParams(location.search);
    redirectParams.set(
      EVENT_TAB_QUERY_PARAMS.odd,
      tabs?.find((tabCtx) => tabCtx.value === newValue)?.queryStr || '',
    );
    navigate(
      {
        pathname: location.pathname,
        search: redirectParams.toString(),
      },
      { replace: true },
    );
  };

  const handlePublishOdds = (
    projectionType: string,
    roundHeatId?: string | null | undefined,
    roundId?: string | null | undefined,
    additionalParams?: { [key: string]: string | number },
  ) => {
    if (!event || !eventId) return;
    publishOdds(
      {
        sport,
        eventId,
        payload: {
          projectionType,
          ...Object.fromEntries(
            Object.entries({
              roundHeatId,
              roundId,
              position: additionalParams?.position,
            }).filter(([, value]: any) => ![undefined, null].includes(value)),
          ),
        },
      },
      {
        onSettled: (_, error) => {
          if (!error) {
            enqueueSnackbar('Published odds');
            const currentPosition = Number(
              additionalParams?.position
                ? additionalParams?.position
                : PROJECTION_POSITION_MAPPING[projectionType],
            );
            switch (projectionType) {
              case ODDS_PROJECTION_TYPE.fastestLapProjections:
                invalidatePositionalOdds(queryClient, sport, event?.id, FASTEST_LAP_POSITION);
                break;
              case ODDS_PROJECTION_TYPE.eventWinnerProjections:
                invalidatePositionalOdds(
                  queryClient,
                  sport,
                  event?.id,
                  sport === SPORT_TYPES.F1 ? currentPosition : EVENT_WINNERS_POSITION,
                );
                break;
              case ODDS_PROJECTION_TYPE.eventSecondPlaceProjections:
                invalidatePositionalOdds(queryClient, sport, event?.id, EVENT_SECOND_POSITION);
                break;
              case ODDS_PROJECTION_TYPE.heatProjections:
                invalidateHeatWinnerOdds(queryClient, sport, event?.id);
                break;
              case ODDS_PROJECTION_TYPE.headToHeadProjections:
                invalidateHeadToHeadOdds(
                  queryClient,
                  sport,
                  event?.id,
                  HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)
                    ? { ...additionalParams }
                    : undefined,
                );
                break;
              case ODDS_PROJECTION_TYPE.dreamTeamProjections:
                invalidateDreamTeamOdds(
                  queryClient,
                  sport,
                  event?.id,
                  HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)
                    ? { ...additionalParams }
                    : undefined,
                );
                break;
              case ODDS_PROJECTION_TYPE.propBetProjections:
                invalidatePropBetOdds(queryClient, sport, event?.id);
                break;
              case ODDS_PROJECTION_TYPE.showsProjections:
                invalidateShowOdds(queryClient, sport, event?.id);
                break;
              case ODDS_PROJECTION_TYPE.podiumProjections:
                invalidatePodiumOdds(queryClient, sport, event?.id);
                break;
              case ODDS_PROJECTION_TYPE.exactasEventProjections: {
                setTimeout(() => {
                  invalidateExactasEventOdds(queryClient, sport, event?.id, EXACTAS_TYPE[2]);
                  invalidateExactasEventOdds(queryClient, sport, event?.id, EXACTAS_TYPE[3]);
                }, 1000);
                break;
              }
              case ODDS_PROJECTION_TYPE.exactasHeatProjections: {
                setTimeout(() => {
                  invalidateExactasHeatOdds(queryClient, sport, event?.id, EXACTAS_TYPE[2]);
                  invalidateExactasHeatOdds(queryClient, sport, event?.id, EXACTAS_TYPE[3]);
                }, 5000);

                break;
              }
            }
          }
        },
      },
    );
  };

  const handleSelectWinner = ({
    roundHeatId,
    roundId,
    athleteId,
    position,
    exactasType,
  }: {
    roundHeatId?: string | null | undefined;
    roundId?: string | null | undefined;
    athleteId?: string;
    position?: number | string | null;
    exactasType?: number;
  }) => {
    if (!event || !eventId) return;
    selectWinner(
      { sport, eventId, payload: { roundHeatId, eventRoundId: roundId, athleteId, position } },
      {
        onSettled: (_, error) => {
          if (!error) {
            enqueueSnackbar('Selected Winner');

            if (roundHeatId || sport === SPORT_TYPES.POWERSLAP) {
              invalidateHeatWinnerOdds(queryClient, sport, event?.id);
            } else {
              if (position) invalidatePositionalOdds(queryClient, sport, event?.id, +position);

              if (exactasType) invalidateExactasEventOdds(queryClient, sport, eventId, exactasType);
            }

            if (position && (+position as number) === EVENT_WINNERS_POSITION) {
              invalidateCachedSports(queryClient);
              invalidateCachedEvent(queryClient, sport, event?.id, 'Event');
            }
          }
        },
      },
    );
  };

  const refreshAllOdds = () => {
    if (!event || !eventId) return;
    tabs.forEach((tab) => {
      switch (tab.label) {
        case ODD_TAB_LABELS.FASTEST_LAP:
          invalidatePositionalOdds(queryClient, sport, event?.id, FASTEST_LAP_POSITION);
          break;
        case ODD_TAB_LABELS.EVENT_WINNER:
          invalidatePositionalOdds(queryClient, sport, event?.id, EVENT_WINNERS_POSITION);
          break;
        case ODD_TAB_LABELS.SECOND_PLACE:
          invalidatePositionalOdds(queryClient, sport, event?.id, EVENT_SECOND_POSITION);
          break;
        case ODD_TAB_LABELS.HEAT_WINNER:
        case ODD_TAB_LABELS.FIGHT_WINNER:
          invalidateHeatWinnerOdds(queryClient, sport, event?.id);
          break;
        case ODD_TAB_LABELS.HEAD_TO_HEAD:
          invalidateHeadToHeadOdds(queryClient, sport, event?.id, undefined);
          break;
        case ODD_TAB_LABELS.DREAM_TEAM:
          invalidateDreamTeamOdds(queryClient, sport, event?.id, undefined);
          break;
        case ODD_TAB_LABELS.PROP_BETS:
          invalidatePropBetOdds(queryClient, sport, event?.id);
          break;
        case ODD_TAB_LABELS.SHOW_ODDS:
          invalidateShowOdds(queryClient, sport, event?.id);
          break;
        case ODD_TAB_LABELS.PODIUM_ODDS:
          invalidatePodiumOdds(queryClient, sport, event?.id);
          break;
        case ODD_TAB_LABELS.EXACTAS_EVENT:
          invalidateExactasEventOdds(queryClient, sport, event?.id, EXACTAS_TYPE[2]);
          break;
        case ODD_TAB_LABELS.EXACTAS_HEAT:
          invalidateExactasHeatOdds(queryClient, sport, event?.id, EXACTAS_TYPE[2]);
          break;
        case ODD_TAB_LABELS.RACE_EXACTA:
          invalidateExactasEventOdds(queryClient, sport, eventId, EXACTAS_TYPE[2]);
          break;
        case ODD_TAB_LABELS.RACE_TRIFECTA:
          invalidateExactasEventOdds(queryClient, sport, eventId, EXACTAS_TYPE[3]);
          break;
      }
    });
  };

  const closeSimulationInProgressSnackbar = (snackbarId: SnackbarKey) => (
    <Button
      variant="text"
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
      sx={{ color: '#ffffff' }}
    >
      Close
    </Button>
  );

  const fetchUpdatedOdds = () => (
    <>
      <Button
        variant="text"
        onClick={() => {
          refreshAllOdds();
          closeSnackbar();
        }}
        sx={{ color: '#ffffff' }}
      >
        Refresh Odds
      </Button>
    </>
  );

  useEffect(() => {
    // Trader realtime sim notifications
    const controller = new AbortController();
    const fetchSimNotification = async () => {
      const serverBaseURL = `${config.apiBaseUrl}/api/v1/admin/${sport}/events/odds`;
      let token = getAuthToken();
      await fetchEventSource(`${serverBaseURL}/sse`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
        signal: controller.signal,
        onopen(res) {
          if (res.ok && res.status === 200) {
            // console.log('Connection made ', res);
          }
          if (res.status === 403) {
            refreshAuthToken();
            token = getAuthToken();
          }
          return new Promise<void>((resolve) => {
            resolve();
          });
        },
        onmessage(event) {
          const data = JSON.parse(event?.data || '{}');
          if (data.status === SSE.START) {
            enqueueSnackbar('Simulation in progress', {
              content: (snackbarId: SnackbarKey, message: SnackbarMessage) => (
                <CustomSnackbar
                  snackbarId={snackbarId}
                  message={message}
                  action={closeSimulationInProgressSnackbar}
                  sx={{ backgroundColor: '#ff0000' }}
                />
              ),
              preventDuplicate: true,
              anchorOrigin: {
                horizontal: 'left',
                vertical: 'bottom',
              },
              autoHideDuration: 100000,
            });
          }

          if (data.status === SSE.END) {
            enqueueSnackbar('Updated odds will be fetched in 10 seconds.', {
              content: (snackbarId: SnackbarKey, message: SnackbarMessage) => (
                <CustomSnackbar
                  snackbarId={snackbarId}
                  message={message}
                  action={fetchUpdatedOdds}
                  sx={{ backgroundColor: '#43A047' }}
                />
              ),
              preventDuplicate: true,
              anchorOrigin: {
                horizontal: 'left',
                vertical: 'bottom',
              },
              autoHideDuration: 10000,
              onClose: (_, reason) => {
                if (reason === CLOSE_REASON.timeout) {
                  refreshAllOdds();
                }
              },
            });
          }
        },
        onerror(err) {
          // console.log('There was an error from server', err);
          if (err.statusCode === 403) {
            refreshAuthToken();
            token = getAuthToken();
          }
        },
      });
    };
    fetchSimNotification();
    return () => controller.abort();
  }, []);

  const tabDateFormatter = (dateStr?: string) => {
    if (!dateStr) return 'TBD';
    return `${format(new Date(dateStr), 'MM-dd-yyyy hh:mm:ss a')} PST`;
  };

  const formattedEventStartDate = useMemo(
    () => tabDateFormatter(event?.startDate),
    [event?.startDate],
  );

  const formattedHeatStartTime = useMemo(
    () => tabDateFormatter(event?.heatStartTime),
    [event?.heatStartTime],
  );

  const OddsTabs = React.useMemo(
    () =>
      tabs?.map((tab) => {
        const label = (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              gap: 0.2,
            }}
          >
            <MuiTabLabelText>{tab.label}</MuiTabLabelText>
            <MuiTabLabelDate>
              {[ODD_TAB_LABELS.HEAT_WINNER, ODD_TAB_LABELS.FIGHT_WINNER].includes(tab.label)
                ? formattedHeatStartTime
                : formattedEventStartDate}
            </MuiTabLabelDate>
          </Box>
        );
        return <MuiTab key={`${sport}_${tab.label}`} value={tab.value} label={label} />;
      }),
    [tabs, sport, formattedEventStartDate, formattedHeatStartTime],
  );

  const OddsTabPanels = React.useMemo(() => {
    if (!event || !eventId) return;
    return tabs?.map((tab) => (
      <MuiTabPanel key={`${sport}_${tab.value}`} value={tab.value}>
        <Box>
          {(tab.label === ODD_TAB_LABELS.FASTEST_LAP && (
            <OddsWinnerTab
              sport={sport}
              eventId={eventId}
              position={FASTEST_LAP_POSITION}
              handlePublishOdds={handlePublishOdds}
              handleSelectWinner={handleSelectWinner}
            />
          )) ||
            (tab.label === ODD_TAB_LABELS.EVENT_WINNER && (
              <OddsWinnerTab
                sport={sport}
                eventId={eventId}
                position={EVENT_WINNERS_POSITION}
                handlePublishOdds={handlePublishOdds}
                handleSelectWinner={handleSelectWinner}
              />
            )) ||
            (tab.label === ODD_TAB_LABELS.RACE_TOP_3 && (
              <OddsWinnerTab
                sport={sport}
                eventId={eventId}
                position={RACE_TOP_3_POSITION}
                handlePublishOdds={handlePublishOdds}
                handleSelectWinner={handleSelectWinner}
              />
            )) ||
            (tab.label === ODD_TAB_LABELS.RACE_TOP_5 && (
              <OddsWinnerTab
                sport={sport}
                eventId={eventId}
                position={RACE_TOP_5_POSITION}
                handlePublishOdds={handlePublishOdds}
                handleSelectWinner={handleSelectWinner}
              />
            )) ||
            (tab.label === ODD_TAB_LABELS.RACE_TOP_10 && (
              <OddsWinnerTab
                sport={sport}
                eventId={eventId}
                position={RACE_TOP_10_POSITION}
                handlePublishOdds={handlePublishOdds}
                handleSelectWinner={handleSelectWinner}
              />
            )) ||
            // (tab.label === ODD_TAB_LABELS.SECOND_PLACE && (
            //   <OddsWinnerTab
            //     sport={sport}
            //     eventId={eventId}
            //     position={EVENT_SECOND_POSITION}
            //     handlePublishOdds={handlePublishOdds}
            //   />
            // )) ||
            (tab.label === ODD_TAB_LABELS.HEAT_WINNER && (
              <HeatWinnerTab
                sport={sport}
                eventId={eventId}
                handlePublishOdds={handlePublishOdds}
                handleSelectWinner={handleSelectWinner}
              />
            )) ||
            (tab.label === ODD_TAB_LABELS.FIGHT_WINNER && (
              <FightWinnerTab
                sport={sport}
                eventId={eventId}
                handlePublishOdds={handlePublishOdds}
                handleSelectWinner={handleSelectWinner}
              />
            )) ||
            (tab.label === ODD_TAB_LABELS.HEAD_TO_HEAD && (
              <PlayerHeadToHead
                sport={sport}
                eventId={eventId}
                handlePublishOdds={handlePublishOdds}
              />
            )) ||
            (tab.label === ODD_TAB_LABELS.DREAM_TEAM && (
              <DreamTeamTab sport={sport} eventId={eventId} handlePublishOdds={handlePublishOdds} />
            )) ||
            (tab.label === ODD_TAB_LABELS.PROP_BETS && (
              <PropBetsOddsTab
                sport={sport}
                eventId={eventId}
                handlePublishOdds={handlePublishOdds}
              />
            )) ||
            (tab.label === ODD_TAB_LABELS.SHOW_ODDS && (
              <ShowsAndPodiumsOddsTab
                sport={sport}
                eventId={eventId}
                showOrPodium={SHOW_OR_PODIUM.SHOW}
                handlePublishOdds={handlePublishOdds}
              />
            )) ||
            (tab.label === ODD_TAB_LABELS.PODIUM_ODDS && (
              <ShowsAndPodiumsOddsTab
                sport={sport}
                eventId={eventId}
                showOrPodium={SHOW_OR_PODIUM.PODIUM}
                handlePublishOdds={handlePublishOdds}
              />
            )) ||
            (tab.label === ODD_TAB_LABELS.EXACTAS_EVENT && (
              <ExactasEventTab
                sport={sport}
                eventId={eventId}
                handlePublishOdds={handlePublishOdds}
              />
            )) ||
            (tab.label === ODD_TAB_LABELS.EXACTAS_HEAT && (
              <ExactasHeatTab
                sport={sport}
                eventId={eventId}
                handlePublishOdds={handlePublishOdds}
              />
            )) ||
            (tab.label === ODD_TAB_LABELS.RACE_EXACTA && (
              <RaceExactasTab
                sport={sport}
                eventId={eventId}
                exactasType={EXACTAS_TYPE[2]}
                handlePublishOdds={handlePublishOdds}
                handleSelectWinner={handleSelectWinner}
              />
            )) ||
            (tab.label === ODD_TAB_LABELS.RACE_TRIFECTA && (
              <RaceExactasTab
                sport={sport}
                eventId={eventId}
                exactasType={EXACTAS_TYPE[3]}
                handlePublishOdds={handlePublishOdds}
                handleSelectWinner={handleSelectWinner}
              />
            ))}
        </Box>
      </MuiTabPanel>
    ));
  }, [sport, event, tabs]);

  return (
    <TabContext value={value}>
      <Box
        sx={{
          display: { xs: 'flex', sm: 'none' },
          flexDirection: { xs: 'column' },
          gap: { xs: '1rem', sm: 0 },
        }}
      >
        <MuiTabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="Odd Tabs"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {OddsTabs}
        </MuiTabs>
        <OddTypeSwitcher />
      </Box>
      <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'space-between' }}>
        <MuiTabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="Odd Tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          {OddsTabs}
        </MuiTabs>

        <OddTypeSwitcher />
      </Box>
      <ThemeProvider theme={oddsTablesTheme}>{OddsTabPanels}</ThemeProvider>
    </TabContext>
  );
}
