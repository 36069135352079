import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '@/hooks/index';

import { PublishOddsPayloadDTO } from '@/types/odds/publishOdds';
import { ODDS_PROJECTION_TYPE } from '@/constants/oddsProjectionType';

interface PUBLISH_ODDS {
  sport: string;
  eventId: string;
  payload: PublishOddsPayloadDTO;
}

const NEW_PUBLISH_PATH_URL = {
  [ODDS_PROJECTION_TYPE.dreamTeamProjections]: 'dreamTeam',
  [ODDS_PROJECTION_TYPE.exactasEventProjections]: 'exactas/odds',
  [ODDS_PROJECTION_TYPE.exactasHeatProjections]: 'exactas/odds',
  [ODDS_PROJECTION_TYPE.trifectasEventProjections]: 'exactas/odds',
};

const publishOddsUrlGenerator = (sport: string, eventId: string, projectionType: string) => {
  // const url = [
  //   ODDS_PROJECTION_TYPE.dreamTeamProjections,
  //   ODDS_PROJECTION_TYPE.exactasEventProjections,
  //   ODDS_PROJECTION_TYPE.exactasHeatProjections,
  // ].includes(projectionType)
  //   ? `admin/${sport}/events/${eventId}/${NEW_PUBLISH_PATH_URL[projectionType]}/public`
  //   : `admin/${sport}/traders/events/${eventId}/odds/public`;
  // return url;

  switch (projectionType) {
    case ODDS_PROJECTION_TYPE.dreamTeamProjections:
      return `admin/${sport}/events/${eventId}/${NEW_PUBLISH_PATH_URL[projectionType]}/odds/public`;

    case ODDS_PROJECTION_TYPE.exactasEventProjections:
    case ODDS_PROJECTION_TYPE.exactasHeatProjections:
    case ODDS_PROJECTION_TYPE.trifectasEventProjections:
      return `admin/${sport}/events/${eventId}/${NEW_PUBLISH_PATH_URL[projectionType]}/public`;

    default:
      return `admin/${sport}/traders/events/${eventId}/odds/public`;
  }
};

const publishOdds = async ({ sport, eventId, payload }: PUBLISH_ODDS) => {
  const { data } = await authenticatedAxios({
    method: 'PUT',
    url: publishOddsUrlGenerator(sport, eventId, payload?.projectionType),
    data: payload,
  });

  return data;
};

export default function usePublishOdds() {
  return useMutation(({ sport, eventId, payload }: PUBLISH_ODDS) =>
    publishOdds({ sport, eventId, payload }),
  );
}
