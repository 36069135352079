import React, { useState, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import MaterialTable from '@material-table/core';
import { parseISO } from 'date-fns';
import { Column } from '@material-table/core';
import { Typography, Box, Grid, Button } from '@mui/material';
// , Tooltip, IconButton

// import FirstPageIcon from '@mui/icons-material/FirstPage';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import LastPageIcon from '@mui/icons-material/LastPage';

import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import { grey } from '@mui/material/colors';

import { oddsType } from '@/atoms/oddsType';

import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import ResetOddsBtn from '@/components/ResetOddsBtn';
import SaveOddsBtn from '@/components/SaveOddsBtn';
import ExportBtn from '@/components/ExportBtn';
import PublishOdds from '@/components/PublishOdds';

import useFetchParticipants from '@/hooks/athletes/useFetchParticipants';
import useFetchExactasEventOdds from '@/hooks/odds/exactas/exactasEventOdds/useFetchExactasEventOdds';
import useAddExactasEventOdds from '@/hooks/odds/exactas/exactasEventOdds/useAddExactasEventOdds';
import useUpdateExactasEventOdds from '@/hooks/odds/exactas/exactasEventOdds/useUpdateExactasEventOdds';
import useUpdateExactasEventOddsPayout from '@/hooks/odds/exactas/exactasEventOdds/useUpdateExactasEventOddsPayout';

import {
  initialExactasEventOddsDataLoader,
  exactasEventOddsFreshWinnerDrawCount,
  exactaEventOddsPayloadFormatter,
  compareExactasTypePublishTime,
} from '@/helpers/exactas';
import { excelDownloader } from '@/helpers/fileDownloader';
import { oddsToCSVFormatModifier } from '@/helpers/oddsToCSVFormatModifier';
import { invalidateExactasEventOdds } from '@/helpers/cachedQueries';
import { displayInPT as format } from '@/helpers/timeConverters';

import { ODDS_PROJECTION_TYPE } from '@/constants/oddsProjectionType';
import { ODD_DATA_TYPES } from '@/constants/oddDataTypes';
import * as httpStatusCodes from '@/constants/httpStatus';
import { SPORT_NAMES } from '@/constants/sportTypes';
import keys from '@/constants/queryKeys';

import { UpdateExactasOddsPayloadDTO } from '@/types/odds/updateOdds';
import { UpdateOddsPayoutPayloadDTO } from '@/types/odds/updateOddsPayout';
import { AddExactasOddsDTO } from '@/types/odds/addOdds';
import { ExactaOddRow } from '@/types/odds/getOdds';
import { EXACTAS_TYPE } from '@/constants/oddTabs';

import {
  tableIcons,
  defaultTableOptions,
  createTablePageOptions,
  // headToHeadTableStyles,
} from '@/utils/TableMisc';

import { generateExactasEventOddsColumns } from './columns';
import AddEventExactasEventDialog from '../components/AddExactasEventOddDialog';
// import { HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS } from '@/constants/misc';

const ConfirmSettleButton = styled(Button)({
  fontSize: '0.875rem',
  padding: '0.485rem 0.768rem',
  fontWeight: '400',
});

export type TOGGLE_OPTIONS_KEYS = 'WINNER' | 'VOIDED' | 'DRAW';
export type TOGGLE_OPTIONS_VALUES = 'winner' | 'voided' | 'draw';
export const TOGGLE_OPTIONS: Record<TOGGLE_OPTIONS_KEYS, TOGGLE_OPTIONS_VALUES> = {
  WINNER: 'winner',
  VOIDED: 'voided',
  DRAW: 'draw',
};
// const DEFAULT_SORT_VALUE = { sortColumn: '', sortOrder: '' };

const MIN_HOLD_PERCENTAGE = 100;
const MAX_HOLD_PERCENTAGE = 200;

const ExactasEventTab = (props: any) => {
  // const [page, setPage] = useState(1);
  // const [query, setQuery] = useState('');
  // const [sort, setSort] = useState(DEFAULT_SORT_VALUE);
  const { sport, eventId, handlePublishOdds } = props;
  const { data: athletes } = useFetchParticipants(sport, eventId);
  const exactasEventOddsKey: {
    sport: string;
    eventId: string;
    exactasType: number;
    params?: { query: string; page: number; sortColumn: string; sortOrder: string };
  } = { sport, eventId, exactasType: EXACTAS_TYPE[2] };
  // if (HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)) {
  //   exactasEventOddsKey.params = { page, query, ...sort };
  // }
  const { data: oddsData } = useFetchExactasEventOdds({ ...exactasEventOddsKey });
  const exactasEventTrifectaOddsKey: {
    sport: string;
    eventId: string;
    exactasType: number;
    params?: { query: string; page: number; sortColumn: string; sortOrder: string };
  } = { sport, eventId, exactasType: EXACTAS_TYPE[3] };
  const { data: oddsTrifectaData } = useFetchExactasEventOdds({ ...exactasEventTrifectaOddsKey });
  const [tableData, setTableData] = useState<any>([]);
  const [listOfToggles, setListOfToggles] = useState<any>([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [hasOddsBeenEdited, setHasOddsBeenEdited] = useState(false);
  const [showOddsUpdateDialog, setShowOddsUpdateDialog] = useState(false);
  const [showResetOddsDialog, setShowResetOddsDialog] = useState(false);
  const [isConfirmVoidDialogOpen, setIsConfirmVoidDialogOpen] = useState(false);
  const [tempVoidedRowId, setTempVoidedRowId] = useState('');
  const [oddType] = useRecoilState(oddsType);
  const { mutateAsync: addExactasEventOdds } = useAddExactasEventOdds();
  const { mutateAsync: updateExactasEventOdds } = useUpdateExactasEventOdds();
  const { mutateAsync: updateExactasEventOddsPayout } = useUpdateExactasEventOddsPayout();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const event: any = queryClient.getQueryData([keys.events.fetchEvent, sport, eventId, 'Event']);

  const playerDropdownOptions = useMemo(() => {
    const modifiedAthletes = athletes
      ? athletes?.map((player: any) => {
          return {
            label: [
              player?.athlete?.firstName,
              player?.athlete?.middleName,
              player?.athlete?.lastName,
            ]
              .filter(Boolean)
              .join(' '),
            eventParticipantId: player?.id || '',
            athleteId: player?.athlete?.id || '',
            ...player.athlete,
          };
        })
      : [];
    return modifiedAthletes;
  }, [JSON.stringify(athletes)]);

  const handleToggleSaveDialog = () => setShowOddsUpdateDialog(!showOddsUpdateDialog);
  const handleToggleResetDialog = () => setShowResetOddsDialog(!showResetOddsDialog);
  const handleToggleHasOddsBeenEdited = () => setHasOddsBeenEdited(!hasOddsBeenEdited);
  const handleToggleConfirmVoidDialog = () => setIsConfirmVoidDialogOpen(!isConfirmVoidDialogOpen);
  const handleResetTempVoidRowId = () => setTempVoidedRowId('');

  const setInitialOddsData = (reset = false) => {
    const allExactas = [
      ...(oddsData?.odds && oddsData?.odds.length > 0 ? oddsData?.odds || [] : []),
      ...(oddsTrifectaData?.odds && oddsTrifectaData?.odds.length > 0
        ? oddsTrifectaData?.odds || []
        : []),
    ];

    const tableDataCopy = JSON.parse(JSON.stringify(tableData));
    let resetWinnerVoidValues = reset ? true : false;
    if (!reset) {
      // Initial data loading
      if (tableData.length === 0) resetWinnerVoidValues = true;
    }

    const newExactasEventOdds = initialExactasEventOddsDataLoader({
      newExactasEventOdds: allExactas,
      currentExactasEventOdds: tableDataCopy,
      resetToggleValues: resetWinnerVoidValues,
      maxHoldPercentage: MAX_HOLD_PERCENTAGE,
    });

    setTableData(newExactasEventOdds);
  };

  // const updateTableData = (rowData: ExactaOddRow) => {
  //   const modExactasEventOdds = JSON.parse(JSON.stringify(tableData)).map(
  //     (tableRow: ExactaOddRow) => (tableRow.id === rowData.id ? rowData : tableRow),
  //   );
  //   setTableData(modExactasEventOdds);

  //   if (!hasOddsBeenEdited) handleToggleHasOddsBeenEdited();
  // };

  const refreshExactasEventOdds = () => {
    invalidateExactasEventOdds(
      queryClient,
      sport,
      eventId,
      EXACTAS_TYPE[2],
      undefined,
      // HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)
      //   ? filterObj({ page, query, ...sort })
      //   : undefined,
    );
    invalidateExactasEventOdds(
      queryClient,
      sport,
      eventId,
      EXACTAS_TYPE[3],
      undefined,
      // HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)
      //   ? filterObj({ page, query, ...sort })
      //   : undefined,
    );
  };

  // const removeExactasEventOdds = () => {
  //   queryClient.removeQueries({
  //     queryKey: [
  //       keys.odds.exactas.fetchExactasEventOdds,
  //       sport,
  //       eventId,
  //       HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)
  //         ? filterObj({ page, query, ...sort })
  //         : undefined,
  //     ],
  //     exact: true,
  //   });
  // };

  React.useEffect(() => {
    refreshExactasEventOdds();
  }, [eventId]);

  React.useEffect(() => {
    setInitialOddsData();
  }, [JSON.stringify(oddsData), JSON.stringify(oddsTrifectaData)]);

  const [showAddMatchUpPopup, setAddMatchUpPopUp] = useState(false); // Handle winner toggle.

  const handleToggleChange = ({
    checked,
    type,
    rowMatchUpId,
    winnerId,
  }: {
    checked: boolean;
    type: TOGGLE_OPTIONS_VALUES;
    rowMatchUpId: string;
    winnerId?: string;
  }) => {
    findAndUpdateTableEntry({ type, rowMatchUpId, checked, winnerId });

    if (type !== TOGGLE_OPTIONS.VOIDED) {
      const updatedListOfToggles = [...listOfToggles];
      const index = updatedListOfToggles.findIndex((row) => row.id === rowMatchUpId);
      if (checked) {
        const key = type === TOGGLE_OPTIONS.WINNER ? 'eventParticipantWinnerId' : type;
        const value = type === TOGGLE_OPTIONS.WINNER ? winnerId : checked;
        if (index !== -1) {
          updatedListOfToggles[index][key] = value;
        } else {
          updatedListOfToggles.push({
            id: rowMatchUpId,
            [key]: value,
          });
        }
      } else {
        if (index !== -1) updatedListOfToggles.splice(index, 1);
      }
      setListOfToggles(updatedListOfToggles);
    }
  };

  const findAndUpdateTableEntry = ({
    type,
    rowMatchUpId = '',
    checked = false,
  }: // winnerId = null,
  {
    type: TOGGLE_OPTIONS_VALUES;
    rowMatchUpId: string;
    checked: boolean;
    winnerId?: string | null;
  }) => {
    const updatedTableData = [...tableData]; // Find index of table entry.
    const tableEntryIndex = updatedTableData.findIndex(
      (tableEntry: any) => tableEntry.id === rowMatchUpId,
    );
    // Update table entry to toggle value
    // if (tableEntryIndex !== -1 && type === TOGGLE_OPTIONS.WINNER) {
    //   updatedTableData[tableEntryIndex].newWinnerId = checked ? winnerId : '';
    // } else

    if (tableEntryIndex !== -1 && type === TOGGLE_OPTIONS.VOIDED) {
      updatedTableData[tableEntryIndex].editedVoid = checked;
    } else if (tableEntryIndex !== -1 && type === TOGGLE_OPTIONS.DRAW) {
      updatedTableData[tableEntryIndex].editedDraw = checked;
    }
    setTableData(updatedTableData);
  };

  const addExactasEventOddsData = async (
    payload: AddExactasOddsDTO,
    message = 'Added Event Matchup Successfully',
  ) => {
    const response = await addExactasEventOdds(
      {
        sport,
        eventId,
        exactasType: payload.participants.length === 2 ? EXACTAS_TYPE[2] : EXACTAS_TYPE[3],
        payload,
      },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          refreshExactasEventOdds();
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  const updateExactasEventOddsData = async ({
    payload,
    message = 'Updated Event Matchup Successfully',
  }: {
    payload: UpdateExactasOddsPayloadDTO;
    message?: string;
  }) => {
    const response = await updateExactasEventOdds(
      { sport, eventId, payload },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          refreshExactasEventOdds();
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  const handleExactasEventConfirmVoid = async (
    payload: UpdateOddsPayoutPayloadDTO,
    message = 'Voided Successfully!',
  ) => {
    const response = await updateExactasEventOddsPayout(
      { sport, eventId, payload },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          refreshExactasEventOdds();
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  const handleExactasEventConfirmAndSettle = async (
    payload: UpdateOddsPayoutPayloadDTO,
    message = 'Payouts updated Successfully!',
  ) => {
    const response = await updateExactasEventOddsPayout(
      { sport, eventId, payload },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          refreshExactasEventOdds();
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  const handleConfirmVoid = async () => {
    handleToggleConfirmVoidDialog();
    const payload = {
      items: [{ id: tempVoidedRowId, voided: true }],
    };
    const responseStatus = await handleExactasEventConfirmVoid(payload);

    if (responseStatus === httpStatusCodes.OK) {
      handleResetTempVoidRowId();
    }
  };

  const handleConfirmAndSettle = async () => {
    handleToggleConfirmDialog(); // Close confirm dialog/modal.
    const responseStatus = await handleExactasEventConfirmAndSettle({ items: listOfToggles });
    if (responseStatus === httpStatusCodes.OK) {
      setListOfToggles([]);
      if (hasOddsBeenEdited) handleToggleHasOddsBeenEdited();
    }
  };

  const handleToggleConfirmDialog = () => setIsConfirmDialogOpen(!isConfirmDialogOpen);

  const holdPercentagesOptions = Array(MAX_HOLD_PERCENTAGE - MIN_HOLD_PERCENTAGE + 1)
    .fill(0)
    .map((_, idx) => MIN_HOLD_PERCENTAGE + idx);

  const updateExactasEventHandler = async () => {
    const payload = exactaEventOddsPayloadFormatter(tableData);
    await updateExactasEventOddsData({ payload: { items: payload } });
  };

  const exportHandler = () => {
    const modData = JSON.parse(JSON.stringify(tableData || []));
    const { title, headers, csvData } = oddsToCSVFormatModifier({
      data: modData,
      oddDataType: ODD_DATA_TYPES.EXACTAS_EVENT,
      oddType,
    });

    if (csvData.length > 0) {
      const eventInfo = {
        eventName: event?.name,
        year: event?.year,
        sport: SPORT_NAMES[sport],
        ...(event?.tour && { tour: event?.tour.name }),
        ...(event?.league && { league: event?.league.name }),
      };
      const lastItemUpdatedAt = oddsData?.traderUpdatedAtDate;
      const updatedAt = lastItemUpdatedAt
        ? format(parseISO(lastItemUpdatedAt.toString() as string), 'MM_dd_yyyy_HH_mm_aaa')
        : '';
      excelDownloader({ title, headers, csvData, updatedAt, ...eventInfo });
    }

    if (csvData.length === 0) enqueueSnackbar(`No Data Found for ${title}`);
  };

  const rowUpdateHandler = (newData: ExactaOddRow) =>
    new Promise((resolve, reject) => {
      if (!hasOddsBeenEdited) {
        handleToggleHasOddsBeenEdited();
      }

      const modTableData = JSON.parse(JSON.stringify(tableData));
      modTableData.some((exactasEventOdd: ExactaOddRow, index: number) => {
        if (exactasEventOdd.id === newData.id) {
          modTableData[index] = newData;
          setTableData(modTableData);
          resolve('success');
        } else {
          reject('failed');
        }
      });
    });

  const rowUpdateHandlerQs = (newData: ExactaOddRow) => {
    if (!hasOddsBeenEdited) {
      handleToggleHasOddsBeenEdited();
    }

    const modTableData = JSON.parse(JSON.stringify(tableData));
    modTableData.some((exactasEventOdd: ExactaOddRow, index: number) => {
      if (exactasEventOdd.id === newData.id) {
        modTableData[index] = newData;
        setTableData(modTableData);
      }
    });
  };

  const columns: Column<ExactaOddRow>[] = generateExactasEventOddsColumns({
    rowUpdateHandler,
    rowUpdateHandlerQs,
    holdPercentagesOptions,
    handleToggleChange,
    oddType,
    setTempVoidedRowId,
    handleToggleConfirmVoidDialog,
    sport,
  });

  // const handlePageChange = (val: number) => {
  //   removeExactasEventOdds();
  //   if (val === Number.NEGATIVE_INFINITY) {
  //     setPage(1);
  //   }
  //   if (val === Number.POSITIVE_INFINITY) {
  //     setPage(oddsData?.total || 1);
  //   }
  //   if (val === -1 || val === 1) {
  //     setPage((currentPage) => currentPage + val);
  //   }
  // };

  return (
    <Box>
      <Box sx={{ marginBottom: '0.875rem' }} display="flex" justifyContent="flex-end">
        <ResetOddsBtn disabled={!hasOddsBeenEdited} resetOddsHandler={handleToggleResetDialog} />
        <SaveOddsBtn disabled={!hasOddsBeenEdited} saveOddsHandler={handleToggleSaveDialog} />
      </Box>
      <Box sx={{ marginBottom: '1rem', marginTop: '1rem' }}>
        <Typography sx={{ fontSize: '0.875rem', fontWeight: '400', color: 'info.main' }}>
          Odds for two athletes to finish the contest in a better position. For example, if Athlete
          1 finishes the contest in 3rd place and Athlete 2 finishes in 12th place, Athlete 1 wins.
        </Typography>
      </Box>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        item
        xs={12}
        sx={{ marginY: '1rem', gap: { xs: '1rem', sm: 0 } }}
      >
        <Grid container alignItems={'center'} item xs={12} sm={6}>
          <ExportBtn handleOnClick={exportHandler} />
          <PublishOdds
            clientUpdatedAtDate={compareExactasTypePublishTime({
              publishTime1: oddsData?.clientUpdatedAtDate as string,
              publishTime2: oddsTrifectaData?.clientUpdatedAtDate as string,
            })}
            traderUpdatedAtDate={compareExactasTypePublishTime({
              publishTime1: oddsData?.traderUpdatedAtDate as string,
              publishTime2: oddsTrifectaData?.traderUpdatedAtDate as string,
            })}
            handlePublishOdds={() => {
              handlePublishOdds(
                ODDS_PROJECTION_TYPE.exactasEventProjections,
                undefined,
                undefined,
                undefined,
                // HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)
                //   ? filterObj({ page, query, ...sort })
                //   : undefined,
              );
            }}
          />
        </Grid>
        <ConfirmSettleButton
          id="confirmAndSettleBtn"
          disableElevation
          variant="contained"
          onClick={handleToggleConfirmDialog}
          disabled={exactasEventOddsFreshWinnerDrawCount(tableData) === 0 ? true : false}
        >
          Confirm and settle
        </ConfirmSettleButton>
      </Grid>
      <Box>
        <MaterialTable
          icons={tableIcons}
          columns={columns}
          data={tableData}
          options={{
            rowStyle: (data) => {
              return { backgroundColor: data.voided ? grey[300] : '' };
            },
            actionsColumnIndex: -1,
            ...defaultTableOptions,
            pageSize: createTablePageOptions(tableData?.length || 0).pageSize,
            pageSizeOptions: createTablePageOptions(tableData?.length || 0).pageSizeOptions,
            thirdSortClick: false,
            toolbar: false,
            // ...(HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)
            //   ? { showTitle: false, searchDebounceDelay: 10 }
            //   : { toolbar: false }),
          }}
          editable={{
            isDeleteHidden: () => true,
            isEditable: (rowData: any) => !rowData.voided && !rowData.winnerParticipantId,
            onRowUpdate: (newData: any) => rowUpdateHandler(newData),
            onRowDelete: () =>
              new Promise(() => {
                //
              }),
          }}
          localization={{
            header: {
              actions: '',
            },
            // toolbar: { searchPlaceholder: 'Search By Athlete Name' },
          }}
          components={{
            Pagination: () => (
              <td>
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: '0.5rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box>
                    <Button
                      id="addNewExactaBtn"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setAddMatchUpPopUp(true);
                      }}
                      color="primary"
                    >
                      Add a new exacta
                    </Button>
                  </Box>
                  {/* {HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport) && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                      <Typography sx={{ ...headToHeadTableStyles.athleteNameStyle }}>
                        {`${oddsData?.page || '-'}-${oddsData?.total || '-'} of ${
                          oddsData?.total || '-'
                        }`}
                      </Typography>
                      <Box sx={{ display: 'flex', gap: '0.7rem', alignItems: 'center' }}>
                        <Tooltip title="First Page" placement="bottom">
                          <span>
                            <IconButton
                              disabled={
                                oddsData?.page === 1 || oddsData?.total === 0 ? true : false
                              }
                              onClick={() => handlePageChange(Number.NEGATIVE_INFINITY)}
                            >
                              <FirstPageIcon sx={{ fontSize: '1.5rem', color: '#BDBDBD' }} />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Previous Page" placement="bottom">
                          <span>
                            <IconButton
                              disabled={
                                oddsData?.page === 1 || oddsData?.total === 0 ? true : false
                              }
                              onClick={() => handlePageChange(-1)}
                            >
                              <ArrowBackIosIcon sx={{ fontSize: '1rem', color: '#BDBDBD' }} />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Next Page" placement="bottom">
                          <span>
                            <IconButton
                              disabled={
                                oddsData?.page === oddsData?.total || oddsData?.total === 0
                                  ? true
                                  : false
                              }
                              onClick={() => handlePageChange(1)}
                            >
                              <ArrowForwardIosIcon sx={{ fontSize: '1rem', color: '#BDBDBD' }} />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Last Page" placement="bottom">
                          <span>
                            <IconButton
                              disabled={
                                oddsData?.page === oddsData?.total || oddsData?.total === 0
                                  ? true
                                  : false
                              }
                              onClick={() => handlePageChange(Number.POSITIVE_INFINITY)}
                            >
                              <LastPageIcon sx={{ fontSize: '1.5rem', color: '#BDBDBD' }} />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>
                    </Box>
                  )} */}
                </Grid>
              </td>
            ),
          }}
          // onOrderCollectionChange={
          //   HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)
          //     ? (orderByCollection) => {
          //         const [firstColumn] = orderByCollection;
          //         if (columns && firstColumn) {
          //           const column = columns[firstColumn.orderBy];

          //           const sortFieldName = column?.field
          //             ? `player${column?.field.includes('eventParticipant1.') ? 1 : 2}${
          //                 column?.field.includes('Odds') ? 'Odds' : 'Probability'
          //               }`
          //             : '';
          //           removeExactasEventOdds();
          //           setSort({
          //             sortColumn: sortFieldName,
          //             sortOrder: firstColumn?.orderDirection?.toUpperCase() || 'ASC',
          //           });
          //           setPage(1);
          //         }
          //       }
          //     : undefined
          // }
          // onSearchChange={
          //   HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)
          //     ? (searchText: string) => {
          //         removeExactasEventOdds();
          //         setQuery(searchText);
          //         setPage(1);
          //       }
          //     : undefined
          // }
        />
      </Box>

      {showAddMatchUpPopup && (
        <AddEventExactasEventDialog
          open={showAddMatchUpPopup}
          handleClose={() => setAddMatchUpPopUp(false)}
          playerDropdownOptions={playerDropdownOptions}
          addExactasEventOddsData={addExactasEventOddsData}
        />
      )}
      {isConfirmDialogOpen && (
        <ConfirmDialog
          open={isConfirmDialogOpen}
          handleClose={handleToggleConfirmDialog}
          handleConfirm={handleConfirmAndSettle}
          title={'ARE YOU SURE YOU WANT TO SETTLE ALL WINNERS/ DRAWS EXACTAS EVENT ODDS?'}
          body={
            'This will establish the winners/ draws and will require voiding if there are any errors. Make sure these are all correct.'
          }
        />
      )}
      {isConfirmVoidDialogOpen && (
        <ConfirmDialog
          open={isConfirmVoidDialogOpen}
          handleClose={() => {
            handleToggleConfirmVoidDialog();
            handleToggleChange({
              checked: false,
              type: TOGGLE_OPTIONS.VOIDED,
              rowMatchUpId: tempVoidedRowId,
            });
            handleResetTempVoidRowId();
          }}
          handleConfirm={handleConfirmVoid}
          title={'ARE YOU SURE YOU WANT TO VOID EXACTAS EVENT ODDS?'}
          body={'This will void the exactas event odds.'}
        />
      )}
      {showOddsUpdateDialog && (
        <ConfirmDialog
          open={showOddsUpdateDialog}
          handleClose={handleToggleSaveDialog}
          handleConfirm={() => {
            handleToggleSaveDialog();
            handleToggleHasOddsBeenEdited();
            updateExactasEventHandler();
          }}
          title={`ARE YOU SURE YOU WANT TO UPDATE THE EXACTAS EVENT ODDS?`}
          body={'This will update the odds.'}
        />
      )}
      {showResetOddsDialog && (
        <ConfirmDialog
          open={showResetOddsDialog}
          handleClose={handleToggleResetDialog}
          handleConfirm={() => {
            handleToggleResetDialog();
            handleToggleHasOddsBeenEdited();
            setInitialOddsData(true);
          }}
          title={`ARE YOU SURE YOU WANT TO RESET THE EXACTAS EVENT ODDS?`}
          body={'This will reset the odds.'}
        />
      )}
    </Box>
  );
};

export default ExactasEventTab;
