import parseISO from 'date-fns/parseISO';

import round from 'lodash.round';

import {
  exportToExcel,
  customFileNameFormatter,
  FORMAT_TYPE,
  excelOddsConverter,
  //   getSortedPublishTimesArr,
  DEFAULT_PROBABILITY,
} from './excelOddHelpers';

import { SPORT_NAMES } from '@/constants/sportTypes';

import { EventStatusKeys, EventStatusNames } from '@/constants/events';

import ODDS_TYPES from '@/constants/oddsTypes';

import { teamTypeToKey } from '@/features/scores/JAIALAI/components/Markets/constants';

import {
  JABetTypes,
  JABetTypesValues,
  JAMarketTypes,
  JASubMarketTypes,
  LegacyJABetTypes,
  LegacyJAMarketTypes,
} from '@/constants/jaialai';

import { displayInPT as format } from './timeConverters';

const getExcelExportFileNameGenerator = ({ oddsExportData, eventInfo, title }: any) => {
  const eventYear = eventInfo?.year || '';
  const sportName = SPORT_NAMES[eventInfo?.sportType]
    ? customFileNameFormatter(SPORT_NAMES[eventInfo.sportType], FORMAT_TYPE._START)
    : '';
  const tourOrLeagueName = customFileNameFormatter(
    eventInfo?.tour?.name || eventInfo?.league?.name || '',
    FORMAT_TYPE._START,
  );

  const eventName = customFileNameFormatter(oddsExportData?.name, FORMAT_TYPE._START);
  const oddTabName = title ? customFileNameFormatter(title, FORMAT_TYPE._START) : '';

  const publishTime = customFileNameFormatter(eventInfo.traderUpdatedAtDate, FORMAT_TYPE._START);
  // Concatenate non-empty values
  return `${[eventYear, sportName, tourOrLeagueName, eventName, oddTabName, publishTime]
    .filter(Boolean)
    .join('')}.xlsx`;
};

/* Returns the event info table section of an excel export as an array of arrays */
const getExcelEventInfoTable = ({ eventInfo }: any) => {
  const {
    id: eventId,
    startDate,
    endDate,
    sportType,
    tour,
    year,
    eventNumber,
    eventStatus,
  } = eventInfo;

  const formattedStartDate = startDate ? format(parseISO(startDate), 'MM-dd-yyyy hh:mm a') : '';
  const formattedEndDate = endDate ? format(parseISO(endDate), 'MM-dd-yyyy hh:mm a') : '';

  const eventInfoTable = [
    ['Event ID:', eventId],
    ['Sport:', SPORT_NAMES[sportType]],
    ['Tour/League:', tour?.name || ''],
    ['Season:', year?.toString()],
    ['Stop Number:', eventNumber?.toString() || ''],
    ['Start Date:', formattedStartDate],
    ['End Date:', formattedEndDate],
    ['Event Status:', EventStatusKeys[eventStatus]?.replaceAll('_', ' ') || ''],
    [''],
  ];

  return eventInfoTable;
};

const getBetTypeHeaders = ({ betType, oddsLabel }: { betType: number; oddsLabel: string }) => {
  switch (betType) {
    case JABetTypes.EVENT_WINNER:
      return [`Moneyline ${oddsLabel}`];
    case JABetTypes.MATCH_WINNER:
      return [
        `Moneyline ${oddsLabel}`,
        'Spread',
        `Spread ${oddsLabel}`,
        'Total',
        `Total ${oddsLabel}`,
      ];
    /* case JABetTypes.WINS_NEXT_SERVE:
      return [`Moneyline ${oddsLabel}`];*/
    // DEPRECATED: Market, Bet and Sub Market code
    /* case JABetTypes.CORRECT_SETS:
      return ['Match Sets Won', oddsLabel];
    case JABetTypes.TOTAL_SETS:
      return ['OverUnder', oddsLabel]; */
    default:
      return [
        `Moneyline ${oddsLabel}`,
        'Spread',
        `Spread ${oddsLabel}`,
        'Total',
        `Total ${oddsLabel}`,
      ];
  }
};

const getSubMarketOdds = ({ betType, subMarkets, teamType, oddTypeSelected }: any) => {
  switch (betType) {
    case JABetTypes.EVENT_WINNER:
      return [
        excelOddsConverter({
          oddTypeSelected,
          odds: subMarkets?.[JASubMarketTypes.MONEY_LINE]?.[teamType]?.odds,
          probability: subMarkets?.[JASubMarketTypes.MONEY_LINE]?.[teamType]?.probability,
        }) || '0',
      ];
    case JABetTypes.MATCH_WINNER:
      return [
        excelOddsConverter({
          oddTypeSelected,
          odds: subMarkets?.[JASubMarketTypes.OUTCOME_1]?.[teamType]?.odds,
          probability: subMarkets?.[JASubMarketTypes.OUTCOME_1]?.[teamType]?.probability,
        }) || '0',
        `${subMarkets?.[JASubMarketTypes.OUTCOME_2]?.[teamType]?.calculatedValue || '0'}`,
        excelOddsConverter({
          oddTypeSelected,
          odds: subMarkets?.[JASubMarketTypes.OUTCOME_2]?.[teamType]?.odds,
          probability: subMarkets?.[JASubMarketTypes.OUTCOME_2]?.[teamType]?.probability,
        }) || '',
        teamType === teamTypeToKey.homeTeam
          ? `${subMarkets?.[JASubMarketTypes.OUTCOME_3]?.[teamType]?.calculatedValue || '0'}`
          : '',
        excelOddsConverter({
          oddTypeSelected,
          odds: subMarkets?.[JASubMarketTypes.OUTCOME_3]?.[teamType]?.odds,
          probability: subMarkets?.[JASubMarketTypes.OUTCOME_3]?.[teamType]?.probability,
        }) || '',
      ];
    /*case JABetTypes.WINS_NEXT_SERVE:
      return [
        excelOddsConverter({
          oddTypeSelected,
          odds: subMarkets?.[JASubMarketTypes.MONEY_LINE]?.[teamType]?.odds,
          probability: subMarkets?.[JASubMarketTypes.MONEY_LINE]?.[teamType]?.probability,
        }) || '0',
      ];*/
    // DEPRECATED: Market, Bet and Sub Market code
    /* case JABetTypes.CORRECT_SETS:
      return [
        `${subMarkets?.[currentSubMarket]?.[teamType]?.calculatedValue || '0'}`,
        teamType === teamTypeToKey.homeTeam
          ? excelOddsConverter({
              oddTypeSelected,
              odds: subMarkets?.[currentSubMarket]?.[teamType]?.odds,
              probability: subMarkets?.[currentSubMarket]?.[teamType]?.probability,
            }) || ''
          : '',
      ];
    case JABetTypes.TOTAL_SETS:
      return [
        teamType === teamTypeToKey.homeTeam
          ? `${subMarkets?.[JASubMarketTypes.OUTCOME_3]?.[teamType]?.calculatedValue || '0'}`
          : '',
        excelOddsConverter({
          oddTypeSelected,
          odds: subMarkets?.[JASubMarketTypes.OUTCOME_3]?.[teamType]?.odds,
          probability: subMarkets?.[JASubMarketTypes.OUTCOME_3]?.[teamType]?.probability,
        }) || '',
      ]; */
    default:
      return [
        excelOddsConverter({
          oddTypeSelected,
          odds: subMarkets?.[JASubMarketTypes.MONEY_LINE]?.[teamType]?.odds,
          probability: subMarkets?.[JASubMarketTypes.MONEY_LINE]?.[teamType]?.probability,
        }) || '0',
        `${subMarkets?.[JASubMarketTypes.SPREAD]?.[teamType]?.calculatedValue || '0'}`,
        excelOddsConverter({
          oddTypeSelected,
          odds: subMarkets?.[JASubMarketTypes.SPREAD]?.[teamType]?.odds,
          probability: subMarkets?.[JASubMarketTypes.SPREAD]?.[teamType]?.probability,
        }) || '',
        teamType === teamTypeToKey.homeTeam
          ? `${subMarkets?.[JASubMarketTypes.TOTAL]?.[teamType]?.calculatedValue || '0'}`
          : '',
        excelOddsConverter({
          oddTypeSelected,
          odds: subMarkets?.[JASubMarketTypes.TOTAL]?.[teamType]?.odds,
          probability: subMarkets?.[JASubMarketTypes.TOTAL]?.[teamType]?.probability,
        }) || '',
      ];
  }
};

const getOddData = ({
  oddsLabel,
  oddTypeSelected,
  subMarkets,
  currentSubMarket,
  // betTypeKey,
  homeTeam,
  awayTeam,
}: any) => {
  const headers: any = [];
  const homeTeamOdds: any = [];
  const awayTeamOdds: any = [];
  const marketType = subMarkets[currentSubMarket]?.homeTeam?.marketType;
  const currentBetType = subMarkets[currentSubMarket]?.homeTeam?.betType;

  headers.push(
    ...[
      [JAMarketTypes.MATCH, JAMarketTypes.SETS].includes(marketType) ? 'Player/s' : 'Team',
      ...getBetTypeHeaders({ betType: currentBetType, oddsLabel }),
    ],
  );
  homeTeamOdds.push(
    ...[
      [JAMarketTypes.MATCH, JAMarketTypes.SETS].includes(marketType)
        ? [homeTeam?.team?.athlete1?.firstName, homeTeam?.team?.athlete2?.firstName]
            .filter(Boolean)
            .join(' & ')
        : homeTeam?.team?.name || '',
      ...getSubMarketOdds({
        betType: currentBetType,
        subMarkets,
        teamType: teamTypeToKey.homeTeam,
        oddTypeSelected,
        currentSubMarket,
      }),
    ],
  );
  awayTeamOdds.push(
    ...[
      [JAMarketTypes.MATCH, JAMarketTypes.SETS].includes(marketType)
        ? [awayTeam?.team?.athlete1?.firstName, awayTeam?.team?.athlete2?.firstName]
            .filter(Boolean)
            .join(' & ')
        : awayTeam?.team?.name || '',
      ...getSubMarketOdds({
        betType: currentBetType,
        subMarkets,
        teamType: teamTypeToKey.awayTeam,
        oddTypeSelected,
        currentSubMarket,
      }),
    ],
  );

  return { headers, homeTeamOdds, awayTeamOdds };
};

export const allMarketOdds = ({
  oddTypeSelected = ODDS_TYPES.AMERICAN,
  oddsExportData,
  matchData,
}: any) => {
  const MARKETS_ARRAY: any = [];
  const MARKET_DATA: any = {};

  const oddsLabel =
    oddTypeSelected === ODDS_TYPES.DECIMAL
      ? 'Decimal Odds'
      : oddTypeSelected === ODDS_TYPES.FRACTIONAL
      ? 'Fractional Odds'
      : 'American Odds';

  const gradeStatus =
    EventStatusKeys[matchData.eventStatus] === EventStatusNames.COMPLETED ? 'Settled' : 'Ungraded';

  MARKETS_ARRAY.push(['']);
  MARKETS_ARRAY.push(['Match Number:', `${matchData.eventNumber || 0}`]);
  MARKETS_ARRAY.push(['Grading Status:', gradeStatus]);
  MARKETS_ARRAY.push(['']);

  oddsExportData
    .filter(
      (odd: any) =>
        +odd?.marketType !== LegacyJAMarketTypes.SERVE &&
        (+odd?.marketType !== JAMarketTypes.MATCH ||
          ![LegacyJABetTypes.CORRECT_SETS, LegacyJABetTypes.TOTAL_SETS].includes(+odd?.betType)),
    )
    ?.sort((a: any, b: any) => {
      const roundComparison = +a?.round?.name - +b?.round?.name;
      if (roundComparison !== 0) {
        return roundComparison;
      }

      return a?.points - b?.points;
    })
    .forEach((row: any) => {
      // DEPRECATED: Market, Bet and Sub Market code
      /* 
      const { marketType, betType: customBetType, subMarketType, team, round: rnd, points } = row;
      const betType =
        +marketType === JAMarketTypes.SERVE
          ? `MATCH ${oddsExportData?.eventNumber || ''}, SET ${rnd?.name || ''}, POINT ${points}`
          : +marketType === JAMarketTypes.SETS
          ? `MATCH ${matchData?.eventNumber || ''}, SET ${rnd?.name || ''}`
          : customBetType; */
      const { marketType, betType: customBetType, subMarketType, team, round: rnd } = row;
      const betType =
        +marketType === JAMarketTypes.SETS
          ? `MATCH ${matchData?.eventNumber || ''}, SET ${rnd?.name || ''}`
          : customBetType;
      const teamKey = team?.isHomeTeam ? teamTypeToKey.homeTeam : teamTypeToKey.awayTeam;
      const otherTeamKey =
        teamKey === teamTypeToKey.homeTeam ? teamTypeToKey.awayTeam : teamTypeToKey.homeTeam;

      MARKET_DATA[marketType] ??= {};

      MARKET_DATA[marketType][betType] ??= {};

      MARKET_DATA[marketType][betType][subMarketType] ??= {};
      if (
        !MARKET_DATA?.[marketType]?.[betType]?.[subMarketType]?.[otherTeamKey] &&
        !MARKET_DATA?.[marketType]?.[betType]?.[subMarketType]?.[teamKey]
      ) {
        MARKET_DATA[marketType][betType][subMarketType][teamKey] = row;
      }
      if (
        MARKET_DATA?.[marketType]?.[betType]?.[subMarketType]?.[otherTeamKey] &&
        !MARKET_DATA?.[marketType]?.[betType]?.[subMarketType]?.[teamKey]
      ) {
        const otherTeamSubMarketType =
          MARKET_DATA?.[marketType]?.[betType]?.[subMarketType]?.[otherTeamKey]?.subMarketType;
        if (otherTeamSubMarketType === subMarketType) {
          MARKET_DATA[marketType][betType][subMarketType][teamKey] = row;
        }
      }

      if (
        MARKET_DATA?.[marketType]?.[betType]?.[subMarketType]?.[otherTeamKey] &&
        MARKET_DATA?.[marketType]?.[betType]?.[subMarketType]?.[teamKey]
      ) {
        if (!MARKET_DATA[marketType][betType].margin) {
          const currentTeamProbability = round(
            MARKET_DATA?.[marketType]?.[betType]?.[subMarketType]?.[teamKey]?.probability || 0,
            2,
          );
          const otherTeamProbability = round(
            MARKET_DATA?.[marketType]?.[betType]?.[subMarketType]?.[otherTeamKey]?.probability || 0,
            2,
          );
          MARKET_DATA[marketType][betType].margin = currentTeamProbability + otherTeamProbability;
        }
      }
    });

  Object.values(MARKET_DATA).forEach((betTypeVal: any) => {
    Object.keys(betTypeVal).forEach((betTypeKey) => {
      const { margin, ...subMarkets } = betTypeVal[betTypeKey];
      MARKETS_ARRAY.push([
        'Match Market:',
        betTypeKey.includes('MATCH') ? betTypeKey : JABetTypesValues[betTypeKey as keyof {}] || '',
      ]);
      MARKETS_ARRAY.push(['Grading Status:', 'Ungraded']);

      // DEPRECATED: Market, Bet and Sub Market code
      /* if (+betTypeKey !== JABetTypes.CORRECT_SETS) {
        MARKETS_ARRAY.push(['Margin:', `${round(margin - DEFAULT_PROBABILITY || 0, 2)}%`]);
      } */

      MARKETS_ARRAY.push(['Margin:', `${round(margin - DEFAULT_PROBABILITY || 0, 2)}%`]);

      // DEPRECATED: Market, Bet and Sub Market code
      /*  if (+betTypeKey === JABetTypes.CORRECT_SETS) {
        Object.keys(subMarkets).forEach((currentSubMarket: any) => {
          const { headers, homeTeamOdds, awayTeamOdds } = getOddData({
            oddsLabel,
            oddTypeSelected,
            betTypeKey: +betTypeKey,
            homeTeam: subMarkets[currentSubMarket].homeTeam,
            awayTeam: subMarkets[currentSubMarket].awayTeam,
            subMarkets: { [currentSubMarket]: subMarkets[currentSubMarket] },
            currentSubMarket,
          });

          MARKETS_ARRAY.push(headers);
          MARKETS_ARRAY.push(homeTeamOdds);
          MARKETS_ARRAY.push(awayTeamOdds);
        });
      } else {
        const [firstSubMarket]: any = Object.keys(subMarkets);
        const [firstSubMarketVal]: any = Object.values(subMarkets);
        const { headers, homeTeamOdds, awayTeamOdds } = getOddData({
          oddsLabel,
          oddTypeSelected,
          subMarkets,
          betTypeKey: +betTypeKey,
          currentSubMarket: firstSubMarket,
          homeTeam: firstSubMarketVal.homeTeam,
          awayTeam: firstSubMarketVal.awayTeam,
        });

        MARKETS_ARRAY.push(headers);
        MARKETS_ARRAY.push(homeTeamOdds);
        MARKETS_ARRAY.push(awayTeamOdds);
      } */

      const [firstSubMarket]: any = Object.keys(subMarkets);
      const [firstSubMarketVal]: any = Object.values(subMarkets);
      const { headers, homeTeamOdds, awayTeamOdds } = getOddData({
        oddsLabel,
        oddTypeSelected,
        subMarkets,
        betTypeKey: +betTypeKey,
        currentSubMarket: firstSubMarket,
        homeTeam: firstSubMarketVal.homeTeam,
        awayTeam: firstSubMarketVal.awayTeam,
      });

      MARKETS_ARRAY.push(headers);
      MARKETS_ARRAY.push(homeTeamOdds);
      MARKETS_ARRAY.push(awayTeamOdds);

      MARKETS_ARRAY.push(['']);
    });
  });

  return MARKETS_ARRAY;
};

/* Excel downloader for Jai Alai/ MASL */
export const gameExcelDownloader = ({
  matchData,
  oddsExportData,
  oddTypeSelected,
  eventInfo,
  title,
}: any) => {
  const excelFileName = getExcelExportFileNameGenerator({
    oddsExportData: matchData,
    eventInfo,
    title,
  });
  const excelEventTable = getExcelEventInfoTable({ eventInfo });

  const allMarketData = allMarketOdds({ matchData, oddsExportData, oddTypeSelected });

  const excelAoA = [...excelEventTable, ...allMarketData];

  exportToExcel({ excelAoA, excelFileName, title });
};
