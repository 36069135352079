import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import * as httpStatusCodes from '@/constants/httpStatus';
import { AddEventHeadToHeadDialogProps, FormValues } from '@/types/playerHeadToHeadTab';
import { MODEL_GENERATED_TEXT_AND_COLOR, MODEL_GENERATED_TYPES } from './constants';

const initialValues: FormValues = {
  eventParticipant1Id: null,
  eventParticipant2Id: null,
};

const validationSchema = Yup.object({
  eventParticipant1Id: Yup.object({ eventParticipantId: Yup.string().required() }),
  eventParticipant2Id: Yup.object({ eventParticipantId: Yup.string().required() }),
});

const AddEventHeadToHeadDialog: React.FC<AddEventHeadToHeadDialogProps> = ({
  open,
  handleClose,
  playerDropdownOptions,
  addHeadToHeadOddsData,
  possibleMatchups,
  existingMatchups,
}) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const payload = {
        eventParticipant1Id: values.eventParticipant1Id?.eventParticipantId || '',
        eventParticipant2Id: values.eventParticipant2Id?.eventParticipantId || '',
      };

      if ((await addHeadToHeadOddsData(payload)) === httpStatusCodes.OK) {
        resetForm();
      }
    },
  });

  const { values, errors, touched, setFieldValue, handleBlur, handleSubmit } = formik;

  const matchupExist = useMemo(() => {
    return values.eventParticipant1Id && values.eventParticipant2Id
      ? possibleMatchups.has(
          `${values.eventParticipant1Id.athleteId}-${values.eventParticipant2Id.athleteId}`,
        )
      : true;
  }, [values, possibleMatchups]);

  const matchupAlreadyExist = useMemo(() => {
    return values.eventParticipant1Id && values.eventParticipant2Id
      ? existingMatchups.has(
          `${values.eventParticipant1Id.athleteId}-${values.eventParticipant2Id.athleteId}`,
        )
      : false;
  }, [values, existingMatchups]);

  const renderWarning = (condition: boolean, color: string, text: string) =>
    condition && (
      <Grid
        item
        xs={12}
        sx={{
          marginBottom: '1rem',
          display: 'flex',
          gap: '0.2rem',
          alignItems: 'center',
          backgroundColor: '#FFF4D3',
          p: 1.5,
        }}
      >
        <WarningIcon sx={{ color, fontSize: 30 }} />
        <Typography sx={{ fontSize: '0.875rem', lineHeight: '1.3125rem' }}>{text}</Typography>
      </Grid>
    );

  const renderAutocomplete = (name: keyof FormValues, label: string, excludeId?: string) => (
    <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
      <Typography>{label}</Typography>
      <Autocomplete
        disableClearable
        value={values[name]}
        options={
          excludeId
            ? playerDropdownOptions.filter((data) => data.eventParticipantId !== excludeId)
            : playerDropdownOptions
        }
        onChange={(_, newValue) => setFieldValue(name, newValue)}
        renderOption={(props, option) => (
          <Box
            sx={{ borderRadius: '8px', margin: '5px', padding: '8px' }}
            component="li"
            {...props}
            key={option.eventParticipantId}
          >
            {option.label}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            onBlur={handleBlur}
            helperText={touched[name] && errors[name] ? `${label} is Required` : ''}
            error={Boolean(errors[name])}
            fullWidth
          />
        )}
      />
    </Grid>
  );

  const { color, tooltipText } = MODEL_GENERATED_TEXT_AND_COLOR[MODEL_GENERATED_TYPES.CUSTOM];
  const { color: colorExistingMatchup, tooltipText: tooltipTextExistingMatchup } =
    MODEL_GENERATED_TEXT_AND_COLOR[MODEL_GENERATED_TYPES.EXISTING_MATCHUP];

  const warningMessage = matchupAlreadyExist
    ? { color: colorExistingMatchup, text: tooltipTextExistingMatchup }
    : !matchupExist
    ? { color, text: tooltipText }
    : null;

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => reason !== 'backdropClick' && handleClose()}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Typography sx={{ fontSize: '1.5rem' }}>
          <strong>Add Event Head to Head</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form>
          <Grid container direction="column">
            {renderWarning(
              Boolean(warningMessage),
              (warningMessage && warningMessage?.color) || '',
              (warningMessage && warningMessage?.text) || '',
            )}
            {renderAutocomplete(
              'eventParticipant1Id',
              'Event Participant 1',
              values.eventParticipant2Id?.eventParticipantId,
            )}
            {renderAutocomplete(
              'eventParticipant2Id',
              'Event Participant 2',
              values.eventParticipant1Id?.eventParticipantId,
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end" spacing={1} sx={{ marginBottom: '1rem' }}>
          <Grid item sm={4}>
            <Button fullWidth variant="outlined" onClick={handleClose} disableElevation>
              Close
            </Button>
          </Grid>
          <Grid item sm={4}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              onClick={handleSubmit}
              disableElevation
              disabled={matchupAlreadyExist}
            >
              Add Head to Head
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AddEventHeadToHeadDialog;
