import * as React from 'react';

const FeatureEnabled = ({
  isFeatureEnabled,
  children,
}: {
  isFeatureEnabled: boolean;
  children: React.ReactNode;
}) => {
  return isFeatureEnabled ? children : null;
};

export default FeatureEnabled;
