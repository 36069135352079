export const LegacyJAMarketTypes = {
  SERVE: 1, // DEPRECATED: Market, Bet and Sub Market code
};

export const JAMarketTypes = {
  EVENT: 2,
  MATCH: 3,
  SETS: 4,
  PROPS: 5,
};

export const LegacyJAMarketTypesValue = {
  1: 'SERVE', // DEPRECATED: Market, Bet and Sub Market code
};

export const JAMarketTypesValue = {
  2: 'EVENT',
  3: 'MATCH',
  4: 'SETS',
  5: 'PROPS',
};

export const LegacyMarketTypes = [
  LegacyJAMarketTypes.SERVE, // DEPRECATED: Market, Bet and Sub Market code
];

export const MarketTypes = [
  JAMarketTypes.EVENT,
  JAMarketTypes.MATCH,
  JAMarketTypes.SETS,
  JAMarketTypes.PROPS,
];
export const LegacyJABetTypes = {
  CORRECT_SETS: 1, // DEPRECATED: Market, Bet and Sub Market code
  TOTAL_SETS: 2, // DEPRECATED: Market, Bet and Sub Market code
  WINS_NEXT_SERVE: 5, // DEPRECATED: Market, Bet and Sub Market code
};

export const JABetTypes = {
  MATCH_WINNER: 3,
  EVENT_WINNER: 4,
};

export const LegacyJABetTypesValues = {
  1: 'CORRECT SETS', // DEPRECATED: Market, Bet and Sub Market code
  2: 'TOTAL SETS', // DEPRECATED: Market, Bet and Sub Market code
};
export const JABetTypesValues = {
  3: 'MATCH WINNER',
  4: 'EVENT WINNER',
  5: 'WINS NEXT SERVE',
};

export const JASubMarketTypes = {
  MONEY_LINE: 1,
  SPREAD: 2,
  TOTAL: 3,
  OUTCOME_1: 4,
  OUTCOME_2: 5,
  OUTCOME_3: 6,
  OUTCOME_4: 7,
};

export const SubMarkets = [
  JASubMarketTypes.MONEY_LINE,
  JASubMarketTypes.SPREAD,
  JASubMarketTypes.TOTAL,
  JASubMarketTypes.OUTCOME_1,
  JASubMarketTypes.OUTCOME_2,
  JASubMarketTypes.OUTCOME_3,
  JASubMarketTypes.OUTCOME_4,
];

// Modify JASubMarketTypesValuesMod as well
export const JASubMarketTypesValues = {
  1: 'MONEY LINE',
  2: 'SPREAD',
  3: 'TOTAL',
  4: 'OUTCOME 1',
  5: 'OUTCOME 2',
  6: 'OUTCOME 3',
  7: 'OUTCOME 4',
};

export const JASubMarketTypesValuesMod = {
  1: 'MONEY LINE',
  2: 'SPREAD',
  3: 'TOTAL',
  4: 'MONEY LINE',
  5: 'SPREAD',
  6: 'TOTAL',
  7: 'OUTCOME 4',
};

export const JATypes = {
  POSITIVE: 1,
  NEGATIVE: 2,
  OVER: 3,
  UNDER: 4,
};

export const JaiAlaiStatusNames: { [key: string]: string } = {
  LIVE: 'LIVE',
  UPCOMING: 'UPCOMING',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
  POSTPONED: 'POSTPONED',
  NEXT: 'NEXT',
  IN_WINDOW: 'IN_WINDOW',
  FORFEIT: 'FORFEIT',
};
// Refer constants/events.ts for status number mappings
export const JaiAlaiStatusValues = {
  [JaiAlaiStatusNames.LIVE]: 1,
  [JaiAlaiStatusNames.UPCOMING]: 2,
  [JaiAlaiStatusNames.COMPLETED]: 3,
  [JaiAlaiStatusNames.CANCELED]: 4,
  [JaiAlaiStatusNames.FORFEIT]: 8,
};

export const JaiAlaiMatchType = {
  S: 'SINGLE SEED',
  D: 'DOUBLE SEED',
};

export const LEGACY_JAI_ALAI_MARKET_LABELS = {
  SERVE: 'Serve', // DEPRECATED: Market, Bet and Sub Market code
};

export const JAI_ALAI_MARKET_LABELS = {
  EVENT: 'Event',
  MATCH: 'Match',
  SETS: 'Sets',
  PROPS: 'Props',
};
