import * as React from 'react';
import { Button, Autocomplete, TextField, Chip, Box } from '@mui/material';

import config from '@/config/vars';
import { FIELD_TO_DEBUG } from './constants';
import { JAIALAIGameContext } from '@/contexts/jaialaiGame.context';
import { updateSocketAuthToken } from '@/utils/socketService';

const options = [
  FIELD_TO_DEBUG.probability,
  FIELD_TO_DEBUG.id,
  FIELD_TO_DEBUG.isSubMarketLocked,
  FIELD_TO_DEBUG.playerLean,
];

const DebugBtn = ({ showDebugBtn = false }) => {
  const { isInTestMode, setIsInTestMode, setCurrentFieldsToDebug } =
    React.useContext(JAIALAIGameContext);

  const [selectedOptions, setSelectedOptions] = React.useState([FIELD_TO_DEBUG.probability]);

  const handleToggleDebug = () => {
    if (config.isDevelop) {
      setIsInTestMode((prev: boolean) => !prev);
      setCurrentFieldsToDebug(selectedOptions);
    }
  };

  const handleSelectionChange = (_: any, newValue: string[]) => {
    if (newValue.length === 0) {
      setSelectedOptions([FIELD_TO_DEBUG.probability]);
    } else {
      setSelectedOptions(newValue);
      setCurrentFieldsToDebug(newValue);
    }
  };

  if (!config.isDevelop) return null;

  return (
    showDebugBtn && (
      <Box display="flex" gap={1}>
        {isInTestMode && (
          <>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                updateSocketAuthToken(
                  `eyJhbGciOiJSUzI1NiIsImtpZCI6ImE0MzRmMzFkN2Y3NWRiN2QyZjQ0YjgxZDg1MjMwZWQxN2ZlNTk3MzciLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiVHJhZGVyIFJleW5vbGRzIiwidXNlclR5cGUiOiJhZG1pbiIsInVzZXJJZCI6IjYzODE5NTI2LTVlZWMtNGRhYi04YWQ0LTA5ODdmMzAxYzM5YyIsInJlc3RyaWN0ZWRTcG9ydHMiOltdLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vYWx0c3BvcnRzLWRldmVsb3BtZW50IiwiYXVkIjoiYWx0c3BvcnRzLWRldmVsb3BtZW50IiwiYXV0aF90aW1lIjoxNzM4MzIzNDY2LCJ1c2VyX2lkIjoiQ1U2UHRyU0RFY1FKRllhTlZxS0dHNVhDU2ltMiIsInN1YiI6IkNVNlB0clNERWNRSkZZYU5WcUtHRzVYQ1NpbTIiLCJpYXQiOjE3Mzg4Mjg4NjgsImV4cCI6MTczODgzMjQ2OCwiZW1haWwiOiJ0cmFkZXJAYWx0c3BvcnRzZGF0YS5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicGhvbmVfbnVtYmVyIjoiKzkxOTg3NjU0MzIxMyIsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsidHJhZGVyQGFsdHNwb3J0c2RhdGEuY29tIl0sInBob25lIjpbIis5MTk4NzY1NDMyMTMiXX0sInNpZ25faW5fcHJvdmlkZXIiOiJjdXN0b20ifX0.nRE_zko_KLAlTmJCHdkR5rUA23J_Skt88d368TKwa3AGJ3_IS_rPcBgywLClLsCSLLWiewXlsCwwNyMW2ahn2ylm5dw7B8HP9OEvtg_okdYvhQNJJyq6acmxOY2pcU1DekwXxWfQVNUYSv8x_oS1srO1Aw0bxiVQGP20vgSQk--Z3g4Ag1FVzlu02WIeQuUWwPWeUVHXrKG5328isaeUnO-K4wKAmwn1Zg17o7eDa6Iz7RXiOTmM73B8yU7PYEYz1cU5_IWs46eGr0VDhymhis9PEOfaRvxnbpeBtD9tuw-SIMNvk4sSuo3gZfwe3Xuw1K3lm1Qou-CQlqXUwLuwow`,
                );
              }}
              disableElevation
              disableFocusRipple
            >
              Use expired token
            </Button>
            <Autocomplete
              multiple
              options={options}
              value={selectedOptions}
              onChange={handleSelectionChange}
              renderTags={(value: string[], getTagProps) =>
                React.Children.toArray(
                  value.map((option, index) => (
                    <Chip label={option} {...getTagProps({ index })} sx={{ padding: '0.1rem' }} />
                  )),
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Debug Fields"
                  sx={{
                    '& .MuiInputBase-root': {
                      padding: '4px',
                    },
                    '& .MuiChip-root': {
                      margin: '2px',
                    },
                  }}
                />
              )}
            />
          </>
        )}
        <Button onClick={handleToggleDebug} variant="outlined" disableElevation>
          Debug
        </Button>
      </Box>
    )
  );
};

export default DebugBtn;
