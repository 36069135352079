export const MIN_DECIMAL_ODDS = 1.01;
export const MAX_DECIMAL_ODDS = 200;

export const MIN_FRACTION_ODDS = 0.01;
export const MAX_FRACTION_ODDS = 200;

export const MIN_NEGATIVE_AMERICAN_ODDS = -10000;
export const MAX_NEGATIVE_AMERICAN_ODDS = -101;

export const MIN_AMERICAN_ODDS = 101;
export const MAX_AMERICAN_ODDS = 100000;

// export const MIN_PROBABILITY = 0.01;
export const MIN_PROBABILITY = 0;
export const MAX_PROBABILITY = 99.5;
